import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import copyIcon from '../assets/css/images/Copy.png'
import viewIcon from '../assets/css/images/view-doc.png'
import emailIcon from '../assets/css/images/email.png'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { Button, CircularProgress, Grid } from "@mui/material";
import data from '../json/employees.json'
import axios from 'axios';
import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReviewSetup from '../components/ReviewSetup';
import { Modal } from 'react-bootstrap';
import delete_btn from '../assets/css/images/delete_btn.png'
import {API} from '../Controllers/API'
import { Document } from '../components/Document';
import { Form } from "react-bootstrap";
import ToolTipButton from '../components/TooltipButton';
import Loader from '../components/Loader';

function createData(name,type, reviewCreated, progress, status, id, history) {
  return {
    name,
    type,
    reviewCreated,
    progress,
    status,
    id,
    history,
  };
}


function Row(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const { row } = props;
  const [showDocument, setShowDocument] = useState(false);
  const handleCloseDocument = () => setShowDocument(false);
  const handleShowDocument = (a,b,c,d ) => {
    console.log(a + " " + b + " " + c)
    setShowDocument(true);
    setDocument(
    <Document
    UserID={data.id}
    EmployeeID={a}
    ReviewID={b}
    ReviewerID={c}
    ReviewerName={d}
    />)
  }
  const copyLink = async (a,b,c)=>{
    console.log(a + " " + b + " " + c)
    let link = 'http://app.performanceevaluationcenter.com/#/review?a='+data.id+'&b=' + a+'&c='+b+'&d='+c
    await navigator.clipboard.writeText(link)
    window.alert('Copied Link to clipboard. Now you can use this link to personally email the reviewer their link to complete the review.')
    }

  const resendEmail = async (a,b,c)=>{
    let link = 'http://app.performanceevaluationcenter.com/#/review?a='+data.id+'&b=' + a+'&c='+b+'&d='+c
    let resp = await axios.post(API+"resend-email",{userId: data.id, employeeId:a, reviewId:b, reviewerId:c, link:link})
    
    
    console.log(resp)
    if(resp.data == 'email sent'){
      window.alert('The email has been resent to the reviewer.')
    } else{
      window.alert('Something went wrong. Please try again')
    }
    }
  
 
  const [document, setDocument] = useState(<Paper sx={{height:'1000px', padding:'15px'}}></Paper>)
  
  
  const [open, setOpen] = React.useState(false);

  
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name.name}
        </TableCell>
        
           <TableCell align="center">{row.reviewCreated}</TableCell>
           <TableCell align="center">{row.progress}</TableCell>
           <TableCell align="Left">{row.type}</TableCell>
           <TableCell style={{color: (row.status === 'Completed')? '#199A71': 'orange', fontWeight:'bold'}} align="center">{(row.status === 'Completed')? 'Completed √': 'In Progress'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <Typography variant="button" gutterBottom component="div">
              <b>Review Type:</b> <span>{row.type}</span> 
              <p><b>Created By:</b> <span>{row.reviewFrom.name}</span> </p>
              <div style={{display:'inline', float:'right'}}>
              
              <div style={{display: props.showArchivedTrainings ? 'none':'inline' }}>
              <ToolTipButton 
              onClick={props.ClickArchive} 
              Title="Archive Evaluation"
              Icon={<ArchiveIcon htmlColor='dodgerBlue'/>}
              />
              </div>
              <ToolTipButton 
              onClick={props.ClickDelete} 
              Title="Delete Evaluation"
              Icon={<CancelIcon htmlColor='tomato'/>}
              />
              </div>
             
              </Typography> 
              <Typography variant="button" gutterBottom component="div">
               Reviewer Details
              </Typography>
              
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.name}>
                      <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>{historyRow.email}</TableCell>
                      <TableCell style={{color: (historyRow.status === 'completed')? '#199A71': 'orange', fontWeight:'bold'}} align="center">{(historyRow.status === 'completed')? 'Completed √': 'Pending'}</TableCell>
                      <TableCell align="center">
                       
                       {historyRow.status === 'pending' && ( <ToolTipButton
                        Title={`Resend Email To ${historyRow.name}`}
                        Icon={<ForwardToInboxIcon htmlColor='#199A71'/>}
                        onClick={()=>resendEmail(row.name.id, row.id, historyRow.id)}
                        />)}
                       {historyRow.status === 'pending' && ( <ToolTipButton
                        Title={`Copy Link To Review`}
                        Icon={<ContentCopyIcon htmlColor='#199A71'/>}
                        onClick={()=>copyLink(row.name.id, row.id, historyRow.id)}
                        />)}
                        {historyRow.status === 'completed' && (<ToolTipButton
                        Title={`View Document`}
                        Icon={<PlagiarismIcon htmlColor='#199A71'/>}
                        onClick={()=>handleShowDocument(row.name.id, row.id, historyRow.id, historyRow.name)}
                        />)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal style={{zIndex:'10000'}} size="lg" show={showDocument} onHide={handleCloseDocument}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          {document}
        </Modal.Body>
       
      </Modal>
    </React.Fragment>
  );
}


Row.propTypes = {
  row: PropTypes.shape({
    reviewCreated: PropTypes.string.isRequired,
    progress: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default function RecentReviews(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
const [loading, setLoading] = useState(true)
const [rowed, setRowed]= useState([])
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [filter, setFilter] = useState('')
const [roleFilter, setRoleFilter] = useState('currentYear')
const [showDocument, setShowDocument] = useState(false);
const [showArchivedTrainings, setShowArchivedTrainings] = useState(false)


const handleCloseDocument = () => setShowDocument(false);
const handleShowDocument = (a,b,c,d) => {
  console.log(a + " " + b + " " + c)
  setShowDocument(true);
  setDocument(
  <Document
  UserID={data.id}
  EmployeeID={a}
  ReviewID={b}
  ReviewerID={c}
  />)
}
const [update,setUpdate] = useState(false)
const [document, setDocument] = useState(<Paper sx={{height:'1000px', padding:'15px'}}></Paper>)


const setupData = (a)=>{

let arr = []

axios.post(API,{email:data.email}).then((response)=>{
  return response
    }).then((response)=>{
      console.log(response)
      if(response.data[0].employees.length > 0){
        
        response.data[0].employees.forEach(i=>{
          if(i.reviews.length > 0){

       
         console.log(i.reviews)

         i.reviews.map((a)=>{

          let progressFilter = a.reviewers.filter(i=> {return (i.status === "completed")})
          let statusUpdate;
  
          if(progressFilter.length === a.reviewers.length){
            statusUpdate = "Completed"
          } else{
            statusUpdate = 'In Progress'
          }
          console.log(a)
          let date = a.dateCreated
          if(a.hasOwnProperty('archivedTraining') === showArchivedTrainings){
            
          arr.push({
            name:a.reviewee,
            type: a.reviewType,
            reviewFrom:a.reviewFrom,
            reviewCreated: date,
            progress:`${progressFilter.length}/${a.reviewers.length}`,
            status:statusUpdate,
            id:a.reviewId,
            history:a.reviewers
          })

        }
          
         })
        }
              
            })
            
          }
          // }
          console.log(arr)
          setRowed(arr)
      //   })
      
      
      // }
    })
   
}

  useEffect(()=>{

   setupData()
    setLoading(false)
   },[update])

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

    const RemoveReview = (a,b)=>{
    console.log(a)
    let message = window.confirm("This will delete this review and any progress. Are you sure you would like to do this?")
  
  
    if(message){

      axios.post(API+'remove-review', {email:data.email,employeeId:a, reviewId:b}).then((response)=>{
  return response
    }).then((response)=>{
     console.log(response)
     setupData()
     setUpdate(!update)
    })
  }
}

const ArchiveReview = (a,b)=>{
  console.log(a)
  let message = window.confirm("This will move the this evaluation to the archived section. This action cannot be undone. Are you sure you would like to do this?")


  if(message){

    axios.post(API+'archive-review', {email:data.email,employeeId:a, reviewId:b}).then((response)=>{
return response
  }).then((response)=>{
   console.log(response)
   setupData()
   setUpdate(!update)
  })
}
}

  if(loading){
   return(<Loader open={true} title={'Loading'}/>)
  } else{
    

  return (<div>
    
    <Typography><b>EVALUATION MANAGER</b></Typography>
    <div style={{textAlign:'end', paddingBottom:'20px'}}>
    <Grid container justifyContent="space-between">
      <Grid item>
      <Button style={{backgroundColor:'#52C47B', fontWeight:'bold', minWidth:'100%'}} onClick={()=>{setShowArchivedTrainings(!showArchivedTrainings); setUpdate(!update)}} variant="contained">{showArchivedTrainings ? '' : <ArchiveIcon htmlColor='white'/>} {'\u00A0'}Show {showArchivedTrainings ? 'Ongoing': 'Archived'} Evaluations</Button>

      </Grid>
      <Grid item>
      <Button style={{backgroundColor:'#52C47B', fontWeight:'bold', minWidth:'100%'}} onClick={()=>{handleShow()}} variant="contained">Setup New Evaluation +</Button>

      </Grid>
    </Grid>
</div>

<div style={{textAlign:'start', paddingBottom:'20px'}}>

<Box sx={{marginBottom:10, display:'inline'}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="&#128269; Search by First or Last Name..." style={{width:'33%', display:'inline'}}/>
  <Form.Select onChange={(e)=>setRoleFilter(e.target.value)} style={{width:'35%', float:'right', display:'inline'}}>
    <option value="currentYear">Current Year</option>
    <option value="">All</option>
    <option value="2023">2023</option>
    <option value="Employee Performance Evaluation">Employee Performance Evaluation</option>
    <option value="Employee Self Evaluation">Employee Self Evaluation</option>
    <option value="360 Evaluation">360 Evaluation</option>
    <option value="Upward Evaluation">Upward Evaluation</option>
  </Form.Select>
</Box>
</div>
<Typography variant='button'><b>{showArchivedTrainings ? 'Archived': 'Ongoing'} Evaluations</b></Typography>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left"><b>Employee Name</b></TableCell>
            <TableCell align="center"><b>Evaluation Created On</b></TableCell>
            <TableCell align="center"><b>Progress</b></TableCell>
            <TableCell align="center"><b>Evaluation Type</b></TableCell>
            <TableCell align="center"><b>Overall Status</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowed.filter(roleFilter === 'currentYear' ? f=>f.reviewCreated.toLowerCase().includes(new Date().getFullYear())  : roleFilter === '2023' ? f=>f.reviewCreated.toLowerCase().includes(roleFilter) : f=>f.type.toLowerCase().includes(roleFilter.toLowerCase())).filter(o=> o.name.name.toLowerCase().includes(filter.toLowerCase())).map((row) => {
            console.log(row)
              return (  <Row 
            
                showArchivedTrainings={showArchivedTrainings}
                ClickDelete={()=>RemoveReview(row.name.id, row.id)} 
                ClickArchive={()=>ArchiveReview(row.name.id, row.id)}
              key={row.id} row={row} />
              )
          }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <React.Fragment>
    <Modal style={{zIndex:'2400'}} size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>EVALUATION SETUP</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <ReviewSetup OnClickSend={()=>{
          handleClose();
          setLoading(true)

          setupData()
          setUpdate(!update)
          setLoading(false)
        }}/>
        </Modal.Body>
       
      </Modal>


      
      {/* <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: '80%' }}>
         
          <div style={{textAlign:'right'}}>
          <Button style={{fontSize:'20px'}} onClick={handleClose}>X</Button>
          </div>

         <ReviewSetup/>
        </Box>
      </Modal> */}
    </React.Fragment>
    </div>
  );

}
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow:'scroll',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};