import { Box } from "@mui/system";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import uuid from "react-uuid";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { API } from "../Controllers/API";

export const AddMultipleEmployees = (props)=>{
    const[ result, setResult] = useState([])
    const[ displayAddBtn, setDisplayAddBtn] = useState(false)
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    const addEmployees = async ()=>{
      let resp = await  axios.post(API+'add-multiple-employees',{id:data.id, array:result})
      console.log(resp.data)
    }
   



    return (
    <Box>
        <Form.Label>Upload a CSV file using <a download={true} name="Employees-sample.csv" href={require("../documents/Employees-sample.csv")}><b>this</b></a> template.</Form.Label>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Default file input example</Form.Label>
        <Form.Control
         accept=".csv" 
         type="file"
         onChange={props.OnFileChange}
         ref={props.Id}
         />
      </Form.Group>

      <Table striped bordered hover>
      <thead>
        <tr>
        
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        {props.TableBody.map(i=>{
      return (
          <tr key={i.id}>
      
          <td>{i.firstName}</td>
          <td>{i.lastName}</td>
  
          <td>{i.email}</td>
          <td>{i.role}</td>
          </tr>
      )
          })}
    

       
     
      </tbody>
    </Table>
    <Typography sx={{display:props.AddDisplayBtn}} variant="button"><b>REVIEW THE TABLE ABOVE TO MAKE SURE EVERYTHING LOOKS Like it should.</b></Typography>
    <a><Button variant="contained" onClick={props.AddEmployees} sx={{display:props.AddDisplayBtn, float:'right' ,padding:'10px', margin:'10px'}}>Add Employees</Button></a>
   <Button variant="contained" onClick={props.ClearEmployees} sx={{display:props.AddDisplayBtn, float:'right' ,padding:'10px', margin:'10px', backgroundColor:'tomato'}}>Clear</Button>
    </Box>
    )

}