import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { API } from "../Controllers/API";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; // Import the styles
import { Row, Col } from 'react-bootstrap';
import EvaluationReport from "../components/EvaluationReport";
import doc from '../documents/report-template.docx'
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import {saveAs} from "file-saver";
import { formatDate } from "../classes/global-functions";



export default function Reports() {
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const[mainData, setMainData] = useState()
    const [loading, setLoading] = React.useState(true);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    //Data Variables
    const [responses,setResponses] = useState([])
    const [name,setName] = useState('---')
    const [evaluationType,setEvaluationType] = useState('---')
    const[reportContent, setReportContent] = useState( {employeeName: name,
      reviewPeriod: `${selectedStartDate} - ${selectedEndDate}`,
      criteria: [],
      reviewsAveraged: 0,
      partTwo:[]
    });

    function loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback);
  }

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
      console.log(date)
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
        console.log(date)
      };
    useEffect(()=>{
        axios.post(API,{email:data.email}).then((response)=>{
         return response
         
      
            }).then((response)=>{
              setMainData(response.data[0])
              setLoading(false)
              console.log(response.data[0])
            })
      },[])

      const generateDocument = (object) => {
        loadFile(
            doc,
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
    
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render(object);
                const blob = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                saveAs(blob, `${object.employeeName} Report.docx`);
            }
        );
    };

      const generateReport = ()=>{
        if(name === '---' || evaluationType === '---'|| selectedEndDate === '' || selectedStartDate === ''){
          
          window.alert('Make sure you have selected the employee, evaluation type, and the review periods.')
        } else {
          console.log('log')
          if(selectedStartDate > selectedEndDate){ 
            window.alert('Start date cannot be greater than the end date.')

          } else {
            
        console.log(name)
        console.log(evaluationType)
        let filter = mainData.employees.filter(i=> {return i.name === name})
        console.log(filter)
        let revArr = [];
       
        let filterTwo = filter[0].reviews.filter(i=>{return i.reviewType === evaluationType}).filter(item => {
          const itemDate = new Date(item.dateCreated);
          return itemDate >= selectedStartDate && itemDate <= selectedEndDate;
        })
        console.log(filterTwo)
        
        let ptTwoRespArr = [];

        filterTwo.map(i=>{
          i.reviewers.forEach(i=>{
            if(i.status === 'completed'){
            i.completedReview.forEach(i=>{
              ptTwoRespArr.push({comment:i.partTwo})
            })
          }
          })
        })
    

        filterTwo.map(i=>{
          i.reviewers.forEach(i=>{
            if(i.status === 'completed'){
            revArr.push(i.completedReview[0].worksheetData)
            }
          })
        })
        if(revArr.length > 0){
        console.log(ptTwoRespArr)
        console.log(revArr)

        // Create a mapping of category index to its corresponding object
        const categoryMap = {};
        
        // Loop through the array of arrays
        revArr.forEach(array => {
          array.forEach(item => {
            const { catIndex, cat, questions } = item;
        
            // If the category index doesn't exist in the categoryMap, create it
            if (!categoryMap[catIndex]) {
              categoryMap[catIndex] = {
                catIndex,
                cat,
                questions: [],
              };
            }
        
            // Add unique questions from the current item to the corresponding category
            questions.forEach(question => {
              // Check if the question already exists in the category
              const existingQuestion = categoryMap[catIndex].questions.find(
                q => q.question === question.question
              );
        
              // If the question doesn't exist, add it
              if (!existingQuestion) {
                categoryMap[catIndex].questions.push(question);
              }
            });
          });
        });
        
        // Calculate the average response for each question
        for (const catIndex in categoryMap) {
          if (categoryMap.hasOwnProperty(catIndex)) {
            const category = categoryMap[catIndex];
            const questions = category.questions;
        
            questions.forEach(question => {
              // Calculate the average response for each question
              const totalResponses = questions.length;
              const totalScores = questions.reduce(
                (sum, q) => sum + scoreToNumber(q.answer),
                0
              );
              const averageResponse = totalScores / totalResponses;
        
              question.averageResponse = numberToScore(averageResponse);
            });
          }
        }
        
        // Convert the categoryMap object to an array with the desired format
        const result = Object.values(categoryMap).map(category => {
          return {
            catIndex: category.catIndex,
            category: category.cat,
            questions: category.questions.map(question => ({
              question: question.question,
              averageResponse: question.averageResponse,
            })),
          };
        });
        
        console.log(result);
        let date = new Date;

        let resultObject = {
          employeeName: name,
          evaluationType: evaluationType,
          todaysDate: formatDate(date),
          reviewPeriod: `${formatDate(selectedStartDate)} - ${formatDate(selectedEndDate)}`,
          reviewsAveraged: revArr.length,
          criteria: result,
          partTwo:ptTwoRespArr

        }

        console.log(resultObject)
       
        
        // Helper function to convert score numbers to text
        function numberToScore(number) {
          if (number === 0) {
            return "NA";
          } else if (number >= 4.5) {
            return "Exceptional Performance";
          } else if (number >= 3.5) {
            return "Exceeds Expectations";
          } else if (number >= 2.5) {
            return "Fully Meets Expectations";
          } else if (number >= 1.5) {
            return "Partially Meets Expectations";
          } else {
            return "Does Not Meet Requirements";
          }
        }
        
        // Helper function to convert score text to numbers
        function scoreToNumber(score) {
          switch (score) {
            case "NA":
              return 0;
            case "Does Not Meet Requirements":
              return 1;
            case "Partially Meets Expectations":
              return 2;
            case "Fully Meets Expectations":
              return 3;
            case "Exceeds Expectations":
              return 4;
            case "Exceptional Performance":
              return 5;
            default:
              return 0; // Default to 0 for unrecognized scores
          }
        }
        
// Helper function to create an HTML table for each category



        setReportContent(resultObject)
      } else{
        window.alert(`${name} has no completed reviews during this time period.`)
      }
      }

    }
        
      }



      if(loading){
       return <Box sx={{textAlign:'center', marginTop:20}}>
       <CircularProgress/>
       <Typography><b>Loading</b></Typography>
     </Box>
      } else{

     
    return (
    <Box >
                <Typography sx={{fontSize:16}} variant="button"><b>Average Review Scores</b></Typography>

    <Paper sx={{display:reportContent.reviewsAveraged === 0 ? 'block': 'none'}}>
        <Container className="p-2">
            <Box className="p-1 mb-3">
            <Typography variant="button"><b>Employee Name:</b></Typography>
            <Form.Select onChange={(e)=>{setName(e.target.value)}}>
            <option value="---">---</option>
                {mainData.employees.map((i)=>{
                    return <option value={i.name}>{i.name}</option>

                })}
            </Form.Select>
            </Box>

            <Box className="p-1 mb-3">
            <Typography variant="button"><b>Evaluation Type:</b></Typography>
            <Form.Select onChange={(e)=>{setEvaluationType(e.target.value)}}>
                <option value="---">---</option>
                <option value="Employee Performance Evaluation">Employee Performance Evaluation</option>
                <option value="Employee Self Evaluation">Employee Self Evaluation</option>
                <option value="360 Evaluation">360 Evaluation</option>
                <option value="Upward Evaluation">Upward Evaluation</option>
            </Form.Select>
            </Box>


            <Box className="p-1 mb-3">
            <Typography variant="button"><b>Review Period:</b></Typography>

            <Row>
        <Col md={6}>
        <Typography variant="button"><b>Start:</b></Typography><br></br>

          <ReactDatePicker
              selected={selectedStartDate}
              onChange={handleStartDateChange}
              dateFormat="MM/dd/yyyy" // You can customize the date format
            />
        </Col>
        
        <Col md={6}>
        <Typography variant="button"><b>End:</b></Typography><br></br>

        <ReactDatePicker
              selected={selectedEndDate}
              onChange={handleEndDateChange}
              dateFormat="MM/dd/yyyy" // You can customize the date format
            />  
        </Col>
      </Row>
           
    
            </Box>

            <Box sx={{textAlign:'end'}} className="p-1 mb-3">
                <Button variant="contained" onClick={()=>generateReport()}><b>Generate Report</b></Button>
            </Box>

           
        </Container>
    </Paper>
    <Box sx={{display:reportContent.reviewsAveraged > 0 ? 'block': 'none'}}>
   
   <Box sx={{textAlign:'end'}}>
   <Button sx={{marginRight:1}} onClick={()=>setReportContent({employeeName: name,
      reviewPeriod: `${selectedStartDate} - ${selectedEndDate}`,
      criteria: [],
      reviewsAveraged: 0,
      partTwo:[]
    })} variant="contained"><b>Back</b></Button>

    <Button onClick={()=>generateDocument(reportContent)} variant="contained"><b>Download Report</b></Button>
    </Box>
    
    <Paper sx={{display:reportContent.reviewsAveraged > 0 ? 'block': 'none'}} className="mt-3 p-5">
    <EvaluationReport resultObject={reportContent}/>
              </Paper>
     </Box>
    </Box>
    )
}
}