import React, {useRef} from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, CircularProgress, Toolbar, Grid} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/pec-badge.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../classes/global-functions";
import pipInstructions from '../../json/pip-instructions.json'
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Controllers/API";
import { useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { coachingPlan } from "../../documents/cp-document";
import ToolTipButton from "../../components/TooltipButton";
import DownloadIcon from '@mui/icons-material/Download';

function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const CpView = (props)=>{
  const paperRef = useRef(null);
const data = JSON.parse(sessionStorage.getItem('profile-data'))
const [spinner,setSpinner] = useState(false)
const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
const [loading,setLoading] = useState(true)
const [completed, setCompleted] = useState(false)
const handleSignature = (e)=>{reviewObj.signature = e}
const [managerEmployee, setManagerEmployee] = useState(null)
const handleSignatureDate = (e)=>{reviewObj.signatureDate = e}
  const[reviewObj, setReviewObj] = useState(
    {
        reviewId:'',
        name: '',
        position: '',
        date: '',
        shortTermGoals: [],
        longTermGoals: [],
        skillsTraining: '',
        additionalBudget: '',
        peerSupport: '',
        other: '',
        specificActionSteps: [],
        initialCheckDate: '',
        midwayCheckDate: '',
        finalCheckDate: '',
        employeeSignature: '',
        managerSignature: '',
        ccs:[],
        status:'',
        sendToManager: false,
        manager:{name:'', email:''},
        reviewFrom:{id:'', email:''},

    }
)
  const location = useLocation();
  function getQueryVariable(variable) {
      var query = location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
  }

  const downloadDocx = () => {
    const htmlContent = paperRef.current.innerHTML;

    // Convert HTML to DOCX
    const docx = htmlDocx.asBlob(
      coachingPlan(
        reviewObj.name.name, 
        reviewObj.position,
       formatDate(reviewObj.date),
       reviewObj.longTermGoals.map((i,index)=>{
        return(`
        <p><b>Goal ${index + 1}: ${i.title}</b>

        <div style="padding-bottom:15px;border-bottom:1px solid gray">${i.details}</div>
        `)
       }).join(''),
       reviewObj.shortTermGoals.map((i,index)=>{
        return(`
        <p><b>Goal ${index + 1}: ${i.title}</b>

        <div style="padding-bottom:15px;border-bottom:1px solid gray">${i.details}</div>
        `)
       }).join(''),
       reviewObj.specificActionSteps,
      reviewObj.skillsTraining,
      reviewObj.additionalBudget,
      reviewObj.peerSupport,
      reviewObj.other,
      reviewObj.checkpoints.map(i=>{
        return(`
        <tr>
        <td>
        <h3 style="font-family:Calibri,sans-serif">${formatDate(i.dateOfNextReview)}</h3>
        </td>
        <td>
        <h3 style="font-family:Calibri,sans-serif">${i.reviewType}</h3>
        </td>
        <td style="font-family:Calibri,sans-serif">
        <h3><a href="app.performanceevaluationcenter.com">View Document</a></h3>
        </td>
    
    </tr>
        `)
    }).join(''),
    reviewObj.employeeSignature.signature,
    formatDate(reviewObj.employeeSignature.date),
    reviewObj.managerSignature.signature,
    formatDate(reviewObj.managerSignature.date),
        ), { margins: {top: 720, left:720, right:720, bottom:720}});

    // Save the DOCX file
    saveAs(docx, `${reviewObj.name.name} Coaching Plan.docx`);
  };
 
  const a  = getQueryVariable('a')
  const b  = getQueryVariable('b')


  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUrl = window.location.href;
        const response = await axios.post(API + 'get-cp', { id: a, cpId: b });
        console.log('Full Response:', response);
        console.log('Data from Response:', response.data[0]);
        setReviewObj(response.data[0]);
      if(currentUrl.toLowerCase().includes('manager')){
        setManagerEmployee('manager')
      } else {
        setManagerEmployee('employee')
      }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [a, b]); // Trigger the effect when 'a' or 'b' changes

  useEffect(() => {
    console.log('Updated reviewObj:', reviewObj);
  }, [reviewObj]);

 

     if(loading){
      return(
      <Box sx={{textAlign:'center', marginTop:20}}>
      <CircularProgress/>
      <Typography><b>Loading</b></Typography>
    </Box>
      )
     } else {

    
     
        
    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{window.close()}}>Close</Button>

        <ToolTipButton
        onClick={downloadDocx}
        Title="DOWNLOAD"
        iconBtnSx={{color:'white',position:'absolute', top:15, right:80}}
        Icon={<DownloadIcon/>}
        />
          </Toolbar>



       </AppBar>

       <br></br>
      <Box >
       
      </Box>

      <Paper ref={paperRef} className="demo-paper" sx={{ textAlign: 'center', padding: '3%', maxWidth: '95%', marginTop: '70px', justifyContent: 'center' }}>
           <Typography variant="h5" sx={{marginBottom:'25px'}}><b>EMPLOYEE COACHING PLAN</b></Typography>
     
               <hr></hr>
<Box sx={{textAlign:'start'}}>
               </Box>

<Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>EMPLOYEE:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{reviewObj.name.name}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>DATE CREATED:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{formatDate(reviewObj.date)}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>POSITION:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{reviewObj.position}</Form.Label>
        </Form.Group>
      </Grid>
    </Grid>
            <hr></hr>
            <Box style={{ textAlign: 'start' }}>
      <Typography variant="button" sx={{ textAlign: 'right', fontSize: 16 }}>
        <b>GOALS FOR THIS PERIOD:</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid  item xs={12} sm={6} md={6}>
        <hr/>
          <Form.Group>
            <Form.Label>
              <Typography variant="button" sx={{ textAlign: 'right' }}>
                <b>Short-Term Goals:</b>
              </Typography>
            </Form.Label>
          </Form.Group>
          <hr/>
          <Box sx={{ padding:1, maxHeight: 500, overflowY: 'scroll' }}>
            {reviewObj.shortTermGoals.map((i, index) => (
              <Box key={index}>
                <Typography>
                  <b>{`GOAL ${index + 1}:`} {i.title}</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: i.details }} />
                <hr/>
              </Box>
              
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
        <hr/>
          <Form.Group>
            <Form.Label>
              <Typography variant="button" sx={{ textAlign: 'right' }}>
                <b>Long-Term Goals:</b>
              </Typography>
            </Form.Label>
          </Form.Group>
          <hr/>
          <Box sx={{ maxHeight: 500, overflowY: 'scroll' }}>
            {reviewObj.longTermGoals.map((i, index) => (
              <Box key={index}>
                <Typography>
                <b>{`GOAL ${index + 1}:`} {i.title}</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: i.details }} />
                <hr/>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
            <hr></hr>
<Box sx={{textAlign:'start'}}>
<TableContainer>
<Table>
<TableHead  sx={{width:'100%'}}>
               
                  <TableCell sx={{width:125}}><b>What To Do</b></TableCell>
                  <TableCell sx={{width:125}}><b>By When</b></TableCell>
                  <TableCell><b>Resources Needed</b></TableCell>
                  <TableCell><b>Potential Obstacles</b></TableCell>
                 
         
              </TableHead>
              <TableBody>
                {reviewObj.specificActionSteps.map((step, index) => (
                  <TableRow key={index}>
                    <TableCell>{step.whatToDo}</TableCell>
                    <TableCell>{step.byWhen}</TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.resourcesNeeded}}/></TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.potentialObstacles}}/></TableCell>
                  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
</Box>

<Box sx={{textAlign:'start'}}>
   <hr/>
<Typography variant="button" sx={{ fontSize: 16 }}>
        <b>SUPPORT REQUIRED:</b>
      </Typography>   
      <hr/>   

      <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Skills Training:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.skillsTraining}}/></Box>
            <br></br>
            {/* <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Additional Budget:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.additionalBudget}}/></Box>
            <br></br> */}
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Peer Support:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.peerSupport}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Other:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.other}}/></Box>
           
    </Box>  
       
    <Box sx={{textAlign:'start'}}> 
  
    <hr/>    
           <Typography variant="button"><b>Checkpoint Schedule</b></Typography>
           <hr/>
           <TableContainer>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
        
          <TableCell><b>Checkpoint Date</b></TableCell>
          <TableCell><b>Follow-up Type</b></TableCell>
          <TableCell><b>Session Details</b></TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
        {
          reviewObj.checkpoints.map(i=>{
          let date = new Date(i.dateOfNextReview)
          let link = `https://app.performanceevaluationcenter.com/#/cp-session-view?a=${a}&b=${b}&c=${i.id}`

            return(
              <TableRow>
              <TableCell>{formatDate(date)}</TableCell>
              <TableCell>{i.reviewType}</TableCell>
              <TableCell>{i.status ? <div dangerouslySetInnerHTML={{__html: `<a href='${link}' target='_blank'>View Document</a>`}}/> : 'Not Available' }</TableCell>
            </TableRow>
            )
          })
        }
            </TableBody>
      </Table>
    </TableContainer> 
    
<hr/>
    <Box>
              
    <Typography variant="button"><b>Signatures</b></Typography>
    <hr></hr>  
            <Typography variant="subtitle2">I have read, understand, and agree to abide by the plan set forth above.</Typography>
           
            <Row className="mb-3">
       
            
        <Form.Group  as={Col} >
        <Typography variant="button"><b>Employee Signature:</b></Typography>
          <br></br>
          <Form.Control 
          value={reviewObj.employeeSignature.signature}
          disabled
          style={{fontFamily:'Signature', fontSize:'16px', height:38, paddingLeft:15}} type="text" />
          <Typography variant="button"><b>{reviewObj.name.name}</b></Typography>
        </Form.Group>

        <Form.Group style={{marginLeft:5}} as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Control 
          value={formatDate(reviewObj.employeeSignature.date)}
          disabled
        //   onChange={(e)=>{reviewObj.signatureDate =e.target.value; console.log(e.target.value)}} 
         />
        </Form.Group>

      <div style={{display: reviewObj.sendToManager ? 'flex':'none'}}>
      <hr/>
       
        <Form.Group as={Col} >
          <Typography variant="button"><b>Manager Signature:</b></Typography>
          <br></br>
          <Form.Control 
          value={reviewObj.managerSignature.signature}
          disabled
          style={{fontFamily:'Signature', fontSize:'16px', height:38}} type="text" />
          <Typography variant="button"><b>{reviewObj.manager.name}</b></Typography>
        </Form.Group>
       
        <Form.Group style={{marginLeft:30}} as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Control 
          value={formatDate(reviewObj.managerSignature.date)}
          disabled
          />
        </Form.Group>

        </div>
      </Row>
      <hr/>
     
    </Box>
      </Box>  
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
 
     }
  
}