import { Box } from "@mui/system";
import React, {useState} from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Grid, Button, ButtonGroup, Paper } from '@mui/material';
import Table from 'react-bootstrap/Table';
import '../assets/review-setup.css'
import { CustomCriteriaForm } from "./CustomCriteriaForm";
const QuestionSetup = (props)=>{
  const [show, setShow] = useState(false);
  const [wkShow, setWkShow] = useState(false);
  const [modalData, setModalData] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleWkClose = () => setWkShow(false);
  const handleWKShow = () => setWkShow(true);

    return(
    <Box>
    <Form.Label><b>Criteria Selection:</b></Form.Label>
    <p>Select the criteria you would like to use for this review. You can create custom criteria or use suggested criteria. You are also able to save worksheets for reuse.</p>
    <InputGroup className="mb-3">
    <Form.Select onChange={props.QuestionSelectOnChange} >
      <option>Select Criterion</option>
    {props.QuestionData}
        </Form.Select>
      <Button
      onClick={props.AddQuestion}
      variant="contained" id="button-addon2">
        Add Criterion
        
      </Button>
    </InputGroup>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <ButtonGroup
          fullWidth
          aria-label="Basic example"
          variant="contained"
        >
          <Button
            onClick={handleShow}
            style={{ backgroundColor: '#48B56F', color: 'white' }}
          >
            Add Custom Criteria
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonGroup fullWidth aria-label="Basic example" variant="contained">
          <Button
            onClick={props.SuggestedButton}
            style={{ backgroundColor: '#48B56F' }}
          >
            Use Suggested Criteria
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonGroup fullWidth aria-label="Basic example" variant="contained">
          <Button
            onClick={props.ClearButton}
            style={{ backgroundColor: '#FC5645',color:'white' }}
            variant="danger"
          >
            Clear
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ButtonGroup
          fullWidth
          aria-label="Basic example"
          variant="contained"
        >
          <Button
            onClick={props.SaveWorksheet}
            style={{ backgroundColor: '#48B56F',color:'white' }}
            variant="success"
          >
            Save Worksheet
          </Button>
          <Button
            onClick={handleWKShow}
            style={{ backgroundColor: '#48B56F',color:'white' }}
            variant="primary"
          >
            Load Worksheet
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
      <Paper style={{padding:'20px', marginTop:'10px'}}>
     {props.WorkSheetData}
      </Paper>
      <div style={{zIndex:'100000'}}>

        {/* Custom Setup Modal */}
      <Modal style={{zIndex:'100000', top:'30%'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
  
        </Modal.Header>
        <Modal.Body> 
          <CustomCriteriaForm 
    CustomCatOnChange={props.CustomCatOnChange} 
    AddCustomCategory={props.AddCustomCategory}
    OnAddCustomQuestion={props.OnAddCustomQuestion}  
    CustomCat={props.CustomCat} 
    CustomCategories={props.CustomCategories}
    CustomDisplay={props.CustomDisplay} 
    SetCustomCategory={props.SetCustomCategory} 
    CategoryData={props.CategoryData}
    TestLabel={props.TestLabel}
    CustomCatValue={props.CustomCatValue}
    CustomCriteriaValue={props.CustomCriteriaValue}
    DisplayCustomInput={props.DisplayCustomInput}
    OnCustomCriteriaChange={props.OnCustomCriteriaChange}
    CloseForm={()=>{setTimeout(handleClose(), 500) }}
    />
    </Modal.Body>
   {/* Worksheet Modal */}
      </Modal>


      <Modal style={{zIndex:'100000', top:'30%'}} show={wkShow} onHide={handleWkClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body> 
        <Table striped bordered hover>
      <thead>
        <tr>
          <th >Worksheet Title</th>
          <th style={{textAlign:'center'}}>Actions</th>
          
        </tr>
      </thead>
      <tbody>
       {props.WorksheetTableItems}
 
      </tbody>
    </Table>
    </Modal.Body>
      
      </Modal>
      </div>
      </Box>
      )
}
export default QuestionSetup;