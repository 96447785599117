
import React, { useEffect, useState } from 'react';
import { Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from '../assets/css/images/PECLogo-clr.png'
import { styled } from '@mui/system';
import axios from 'axios';
import { API } from '../Controllers/API';
import { Link } from 'react-router-dom';

const CenteredContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop:'10%', // Add this to center horizontally as well
    height: '100vh', // Use the full viewport height
  });
  
  const Avatar = styled(LockOutlinedIcon)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: 'green',
  }));
  
  const Form = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  }));
  
  const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#1A9A71'
  }));
export const ResetPassword = () => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [queryParams, setQueryParams] = useState({});

  const [showSuccess, setShowSuccess] = useState(false);

useEffect(() => {
    const parseQueryParams = (queryString) => {
      const params = {};
      queryString
        .split('&')
        .forEach((pair) => {
          const [key, value] = pair.split('=');
          params[key] = decodeURIComponent(value);
        });
      return params;
    };
  
    const hash = window.location.hash;
  
    if (hash.indexOf('?') !== -1) {
      const queryString = hash.split('?')[1]; // Split by hash instead of ?
      const parsedParams = parseQueryParams(queryString);
      setQueryParams(parsedParams);
      console.log(parsedParams)
    }

  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
  
    if(confirmPassword === password){
        
    // Add your password reset logic here, e.g., sending a request to the server
    let resp = await axios.post(API + 'update-password',{userId: queryParams.a, subId:queryParams.b, newPassword: confirmPassword})
console.log(resp)
    if(resp === 'error'){
        window.alert('Something went wrong. Please try again.')
    } else {setShowSuccess(true)}
    // Clear form fields
    setPassword('');
    setConfirmPassword('');

} else {
    window.alert('Passwords do not match. Please try again.')
setPassword('');
    setConfirmPassword('');}
  };
if(showSuccess === false){
    
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <CenteredContainer>
        <img src={logo} width="400px" style={{marginBottom:10}} height="auto"/>
        {/* <Avatar>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography sx={{fontSize:18}} variant="button">
        <b>Reset Your Password</b>
        </Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            type="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <SubmitButton
           onClick={()=>handleSubmit()}
            fullWidth
            variant="contained"
            color="primary"
          >
           <b>Reset Password</b>
          </SubmitButton>
        </Form>
      </CenteredContainer>
    </Container>
  );
} else {
    return(
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    <CenteredContainer>
      <img src={logo}  width="400px" height="auto"/>
      {/* <Avatar>
        <LockOutlinedIcon />
      </Avatar> */}
      <Typography sx={{textAlign:'center',marginBottom:5, marginTop:5}} component="h1" variant="h5">
        Success! You can now login to the Performance Evaluation Center.
      </Typography>
       <Link to='/'><Button sx={{backgroundColor:'#1A9A71'}} variant='contained'><b>Login</b></Button></Link>
    </CenteredContainer>
  </Container>
  )
}
}

export default ResetPassword;
