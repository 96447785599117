import React, { useState } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
export const EditEmployeeForm = (props)=>{
const [showCatInput, setShowCatInput] = useState(false)
    return (
        <div>
            <div><Typography sx={{fontSize:'18px'}} variant='button'>Edit Employee</Typography>
            <IconButton sx={{float:'right'}} onClick={props.CloseEdit}>
              <CloseIcon />
            </IconButton>
            </div>
        <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label><b>First Name</b></Form.Label>
          <Form.Control value={props.PersonsFirstNameValue} onChange={props.ChangePersonsFirstName} type="text" placeholder="Name" />
          
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label><b>Last Name</b></Form.Label>
          <Form.Control value={props.PersonsLastNameValue} onChange={props.ChangePersonsLastName} type="text" placeholder="Name" />
          
        </Form.Group>
       </Row>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><b>Email</b></Form.Label>
          <Form.Control value={props.PersonsEmailValue} onChange={props.ChangePersonsEmail}  type="text" placeholder="Email" />
          <Form.Text className="text-muted">
            This email will be used to send evaluations to.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><b>Select the role of this indiviual?</b></Form.Label>
          <Form.Select onChange={props.ChangeCatSelect} value={props.CatSelectValue}>
          
            <option>Employee</option>
            <option>Manager</option>
            </Form.Select>
    
     
     

        </Form.Group>
        
        <Button onClick={props.OnSubmit} variant="contained" type="submit">
          Save Changes
        </Button>
        </div>
    )
}