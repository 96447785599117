import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import copyIcon from '../assets/css/images/Copy.png'
import viewIcon from '../assets/css/images/view-doc.png'
import emailIcon from '../assets/css/images/email.png'
import { Button, CircularProgress, Grid } from "@mui/material";
import data from '../json/employees.json'
import axios from 'axios';
import ReviewSetup from '../components/ReviewSetup';
import { Modal } from 'react-bootstrap';
import delete_btn from '../assets/css/images/delete_btn.png'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CancelIcon from '@mui/icons-material/Cancel';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {API} from '../Controllers/API'
import { Document } from '../components/Document';
import { Form } from "react-bootstrap";
import PIP from '../subcomponents/review-screens/pip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { render } from '@testing-library/react';
import { CheckpointForm } from '../components/CheckpointForm';
import { formatDate } from '../classes/global-functions';
import ToolTipButton from '../components/TooltipButton';
import { PipDrafts } from '../components/PipDrafts';
import PIPEdit from '../subcomponents/review-screens/pipEdit';


const popover = (props)=>{
   return (
    <Popover id="popover-basic">
    <Popover.Header as="h3">Send Plan To Be Signed</Popover.Header>
    <Popover.Body>
     {props.Message}
    </Popover.Body>
  </Popover>
   )
}

;

export default function PeformanceImprovementPlan(props) {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
const [loading, setLoading] = useState(true)
const [rowed, setRowed]= useState([])
const [renderData, setRenderData] = useState({pips:[]})
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

//PIPedit
const [pipEditShow, setPipEditShow] = useState(false);
const handlePipEditClose = () => setPipEditShow(false);
const handlePipEditShow = () => setPipEditShow(true);

//Checkpoint Modal
const [checkpointFormData, setCheckpointFormData] = useState(null);
const [chckShow, setChckShow] = useState(false);
const handleChckClose = () => setChckShow(false);
const handleChckShow = () => setChckShow(true);
const [followUpTypeChck, setFollowUpTypeChck] = useState('')
const [notesChck, setNotesChck] = useState('')
const [checkpoints,setCheckpoints] = useState([])
const [checkpointIndex , setCheckpointIndex] = useState()
const [reviewId , setReviewId] = useState('')
const[loadedPipData, setLoadedPipData] = useState([])


const [filter, setFilter] = useState('')
const [roleFilter, setRoleFilter] = useState('')
const [showDocument, setShowDocument] = useState(false);
const [showDrafts, setShowDrafts] = useState(false)
const handleNotes = (e)=>setNotesChck(e)
const ResendPip =  (a,b)=>{
  axios.post(API+'resend-pip', {id:a, pipId:b}, (err, response)=>{
   
   return response 

   
  }).then((response)=>{
    if(response.data == 'sent!'){
      window.alert('The plan has been resent to the person to sign.')
    } else if (response.data == '!sent'){
      window.alert('Something went wrong. Please try again.')
    } else{
      window.alert('sent')
      setUpdate(!update)
    }
  })
  }
  let checkpointUpdate = {
    reviewId: reviewId,
    index:checkpointIndex,
    notes:notesChck
  }
  const OpenCheckpoint = (checkpoints, reviewId)=>{
    setCheckpoints(checkpoints)
    setReviewId(reviewId)
     handleChckShow()
     setNotesChck('')
     setFollowUpTypeChck('')
  }

  const updateCheckpoint = async (a)=>{
    console.log(checkpointUpdate)
    const response = await axios.post(API+'update-checkpoint',{id:data.id,checkpointUpdate:checkpointUpdate})

    if(response.data === 'updated!'){
      window.alert('Updated! You can see the changes by viewing the document in the checkpoins section.')
      handleChckClose()
      setUpdate(!update)
    }
  }

const copyLink = async (a)=>{
  
  let localLink =`http://localhost:3000/#/pip-sign?a=${data.id}&b=${a}`
  let link = `http://app.performanceevaluationcenter.com/#/pip-sign?a=${data.id}&b=${a}`
  await navigator.clipboard.writeText(link)
  window.alert('Copied Link to clipboard. Now you can use this link to personally email the reviewer their link to complete the review.')
  }


const [update,setUpdate] = useState(false)


const setupData = async(a)=>{

// let response = await axios.post(API, {email:data.email},i=>{
//    return response.data
// })
// console.log(response)
// let arr = response.data
// setRenderData(arr)
// console.log(renderData)
}

useEffect(()=>{
  axios.post(API,{email:data.email}).then((response)=>{
    return response
    
 
       }).then((response)=>{
         setRenderData(response.data[0])
         setLoading(false)
       })
},[])

  useEffect(()=>{
    axios.post(API,{email:data.email}).then((response)=>{
      return response
      
   
         }).then((response)=>{
           setRenderData(response.data[0])
           setLoading(false)
         })
   },[update])

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const HandleClose = () => {
    // Your logic for handling the close event
    setUpdate(!update);
    setPipEditShow(false);
  };

  const HandleClosePip = () => {
    // Your logic for handling the close event
    setUpdate(!update);
    handleClose();
  };



    const DeleteBtn = (a,b)=>{
    console.log(a)
    console.log(b)
    let message = window.confirm("This will delete this review and any progress. Are you sure you would like to do this?")
  
  
    if(message){

      axios.post(API+'delete-pip', {id:a, reviewId:b}).then((response)=>{
  return response
    }).then((response)=>{
     console.log(response)
     setupData()
     setUpdate(!update)
    })
  }
}

const DeletePipDraft = async (a)=>{

  let message = window.confirm("This action will delete this draft. Are you sure you would like to do this?")


  if(message){

  let resp = await axios.post(API+'delete-pip-draft', {email:data.email, reviewId:a})
 console.log(resp.data)
  if(resp.data === 'Deleted!'){
    console.log('this')

   setUpdate(!update)
   setShowDrafts(false)
  } else {
    window.alert('Something went wrong. Please try again.')
  }

}
}

  if(loading){
   return(<Box sx={{textAlign:'center', marginTop:20}}>
   <CircularProgress/>
   <Typography><b>Loading</b></Typography>
 </Box>)
  } else{
   if(showDrafts) {
    return(
      <Box>
    <PipDrafts
    DraftData={renderData?.pipDrafts}
    ShowDrafts={()=>setShowDrafts(!showDrafts)}
    ClickEditPIP={(a) => {
      console.log(a);
      handlePipEditShow();
      setLoadedPipData(a);
  }}
  ClickDeletePip={(a)=>DeletePipDraft(a)}

    />

    <Modal style={{zIndex:'10000'}} size="lg" show={pipEditShow} onHide={handlePipEditClose}>
    <Modal.Header closeButton>
      <Modal.Title>PERFORMANCE IMPROVEMENT PLAN SETUP</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <PIPEdit
        LoadedData={loadedPipData}
        OnClickSend={()=>{setLoading(true); handlePipEditClose(); setUpdate(!update)}}
        HandleClose={HandleClose}
        onFinish={()=>{
          setUpdate(!update)
          setShowDrafts(false)
        }}
        />
    </Modal.Body>
   
  </Modal>
  </Box>
    )
   } else {
    

  return (<div>
    
    <Typography><b>PERFORMANCE IMPROVEMENT PLANS</b></Typography>
    <Grid sx={{marginTop:1, marginBottom:2}} container spacing={2} justifyContent="flex-end" alignItems="center">
      <Grid item xs={6}>
        <Button
          style={{ backgroundColor: '#52C47B', fontWeight: 'bold' }}
          onClick={() => {
            setShowDrafts(!showDrafts);
          }}
          variant="contained"
        >
          Show Saved Drafts
        </Button>
      </Grid>
      <Grid sx={{textAlign:'end'}} item xs={6}>
        <Button
          style={{ backgroundColor: '#52C47B', fontWeight: 'bold' }}
          onClick={() => {
            handleShow();
          }}
          variant="contained"
        >
          Add New PIP +
        </Button>
      </Grid>
    </Grid>

<div style={{textAlign:'start', paddingBottom:'20px'}}>

<Box sx={{marginBottom:10, display:'inline'}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="Search by First or Last Name..." style={{width:'33%', display:'inline'}}/>

</Box>
</div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
         
            <TableCell><b>Employee Name</b></TableCell>
            <TableCell><b>Plan Created On</b></TableCell>
            <TableCell align='left'><b>Signature Status</b></TableCell>
            <TableCell align='center'><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {renderData.pips.filter(o=> o.reviewee.name.toLowerCase().includes(filter.toLowerCase())).map(i=>{
          let link = `https://app.performanceevaluationcenter.com/#/pip-view?a=${data.id}&b=${i.reviewId}`
          
          return(<TableRow>
            <TableCell>{i.reviewee.name}</TableCell>
            <TableCell>{i.dateCreated}</TableCell>
            <TableCell  align='left' style={{color:(i.status === 'Signed') ? '#199A71':'orange', cursor:(i.status === 'Not Yet Sent To Be Signed') ? 'cursor':''}}>
              <OverlayTrigger
            
              
                placement="bottom"
                overlay={<Popover  style={{display:(i.status === 'Not Yet Sent To Be Signed') ? '':'none'}}>
                <Popover.Header as="h3">Send Plan To Be Signed</Popover.Header>
                <Popover.Body>
                 <Typography>Click the <b>Mail Icon</b> to the right to send the plan to be signed. The email address the plan will be sent to:<p> <b>{i.reviewee.email}</b></p></Typography>
                </Popover.Body>
              </Popover>}
                ><b>{i.status}</b>
            </OverlayTrigger></TableCell>
            <TableCell align='center'>
              
            <ToolTipButton
             Title={`Resend PIP to ${i.reviewee.name}`}
            style={{display:(i.status ==='Signed')?'none':'inline'}}
            Icon={<ForwardToInboxIcon htmlColor='#199A71'/>}
            onClick={()=>ResendPip(data.id, i.reviewId)}
            />
            <ToolTipButton
            style={{display:(i.status ==='Signed')?'none':'inline'}}           
            Title={`Copy Link To PIP`}
            Icon={<ContentCopyIcon htmlColor='#199A71'/>}
            onClick={()=>copyLink(i.reviewId)} 
            />
         
            <ToolTipButton
            style={{display:(i.status ==='Signed')?'inline':'none'}}           
            Title={`Update Checkpoint`}
            Icon={<UpgradeIcon htmlColor='#199A71'/>}
            onClick={()=>OpenCheckpoint(i.checkpoints, i.reviewId)}
            />
            <a target="_blank" href={link}>
            <ToolTipButton
            Title={`View Document`}
            Icon={<PlagiarismIcon htmlColor='#199A71'/>}
            />
            </a>
            <ToolTipButton
            Title={`Delete`}
            Icon={<CancelIcon htmlColor='tomato'/>}
            onClick={()=>DeleteBtn(data.id,i.reviewId)}
            />
              {/* <a onClick={()=>ResendPip(data.id, i.reviewId)} style={{marginRight:'10px', display:(i.status ==='Signed')?'none':'inline'}} target="_blank"><img width="25px" height='auto' style={{cursor:'pointer'}} title='Resend Email to Reviewer' src={emailIcon}/> </a>
              <a onClick={()=>copyLink(i.reviewId)} style={{display:(i.status ==='Signed')?'none':'inline', marginRight:'10px'}} target="_blank"><img style={{cursor:'pointer'}} title='Copy link to review' width="15px" height='auto' src={copyIcon}/></a> 
              <a target="_blank" href={link} style={{display: 'inline', marginRight:'5px'}} > <img style={{cursor:'pointer'}} title='View Completed Review' width="20px" height='auto' src={viewIcon}/></a>
              <Button sx={{display:(i.status ==='Signed')?'inline':'none'}} onClick={()=>OpenCheckpoint(i.checkpoints, i.reviewId)}>Update</Button>
              <a onClick={()=>DeleteBtn(data.id,i.reviewId)}><img style={{padding:'2px'}}  width="20px" height='auto' src={delete_btn}/></a> */}
              </TableCell>
          </TableRow>)
         })}
        </TableBody>
      </Table>
    </TableContainer>
    <React.Fragment>
    <Modal style={{zIndex:'10000'}} size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PERFORMANCE IMPROVEMENT PLAN SETUP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <PIP 
            OnClickSend={()=>{setLoading(true); handleClose(); setUpdate(!update)}}
            HandleClose={HandleClosePip}
            />
        </Modal.Body>
       
      </Modal>


     


      <Modal style={{zIndex:'10000'}} size="lg" show={chckShow} onHide={handleChckClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Checkpoints</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <CheckpointForm 
    OnSelectCheckPoint={(e)=>{
      let index = e.target.value
      console.log(index)
      setCheckpointIndex(Number(index))
      setFollowUpTypeChck(checkpoints[Number(index)].checkpointFollowUpType)
      setNotesChck(checkpoints[Number(index)].notes)
    }} 
    FollowUpType={followUpTypeChck}
    Checkpoints={checkpoints.map((i,index)=>{
       return(
        <option value={index}>{i.checkpointGoal} (Follow-up Date: {formatDate(i.dateOfCheck)})</option>
       )
    })}
    NotesValue={notesChck}
    NotesOnChange={(e)=>{handleNotes(e.target.value); console.log(e.target.value)}}
    OnClickUpdate={()=>updateCheckpoint()}
    />
        </Modal.Body>
       
      </Modal>


      
      {/* <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: '80%' }}>
         
          <div style={{textAlign:'right'}}>
          <Button style={{fontSize:'20px'}} onClick={handleClose}>X</Button>
          </div>

         <ReviewSetup/>
        </Box>
      </Modal> */}
    </React.Fragment>
    </div>
  );

}

}
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow:'scroll',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};