import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import stepOneImage from '../assets/getting-started-assets/stepOne.gif'
import stepTwoImage from '../assets/getting-started-assets/step-two.gif'
import stepThreeImage from '../assets/getting-started-assets/step-three.gif'
import stepFourImage from '../assets/getting-started-assets/step-four.gif'
import stepFiveImage from '../assets/getting-started-assets/step-five.gif'
import stepSixImage from '../assets/getting-started-assets/step-six.gif'
import stepSevenImage from '../assets/getting-started-assets/step-seven.gif'

const steps = [
  {
    label: 'STEP ONE: ADD EMPLOYEES',
    image: stepOneImage,
    description: `You can start by adding employees to the employee section of the app. Click the 'EMPLOYEES' tab in the left hand menu as shown. In this section you will want to build out your employee database. When you create reviews you will be able to choose from this list who is being evaluated and who is completing the evaluations.`,
  },
  {
    label: 'STEP 2: ADD EMPLOYEES',
    image: stepTwoImage,
    description:`Click the ADD NEW EMPLOYEES button. Here you will see an option to add a single employee or to add multiple employees. If you select multiple employees you should download and edit the example CSV file. When you manually input employees you will see a simple form you can complete.`,
  },
  {
    label: 'STEP 3: EVALUATION MANAGER',
    image: stepThreeImage,
    description: `After you have added employees. Click the evaluation manager. You are ready to start making your first evaluation. Click ADD NEW EVALUATION.`,
  },
  {
    label: 'STEP 4: SELECT AND SETUP EVALUATION',
    image: stepFourImage,
    description: `Select the type of evaluation you would like to create. Then select the person you would like to evaluate. In the REVIEWERS dropdown, click the name(s) of the people who will performing the review to add them to the REVIEWERS column on the right.`,
  },
  {
    label: 'STEP 5: SETUP CRITERION',
    image: stepFiveImage,
    description: `Setup the criterion for the evaluation by either clicking the USE SUGGESTED CRITERION button or by clicking the dropdown and manually adding criteria. You can also add custom criteria and categories by clicking the ADD CUSTOM CRITERIA button. There you can use the form to add your own criteria. Once you have built out your worksheet, you can also save it for reuse by clicking SAVE WORKSHEET. Once you are finished, go to the FINALIZE section.`,
  },
  {
    label: 'STEP 6: FINALIZE, DEMO, AND SEND EVALUATION',
    image: stepSixImage,
    description: `In the FINALIZE section, add the review period and click the Add Goal Planning Section option if you would like to have the reviewer(s) create goals for the employee being reviewed. Then you will be able to add the rest of the optional information by clicking the checkboxes and inputing the information. After that, you can input names and emails of people you would like to copy on the completed review. (The CCs will receive an email with instructions to view each completed evaluation. They are not reviewers.) You can also demo the evaluation by clicking DEMO THE EVALUATION. This will show you how the evaluation will look to the reviewer. Once you're happy with the evaluation you can click the SEND EVALUATION button and you will see that the evaluation has been added to the EVALUATION MANAGER.`,
  },
  {
    label: 'STEP 7: EVALUATION MANAGER OPTIONS',
    image: stepSevenImage,
    description: `In the evaluation manager you can see the completion status for each reviewer by clicking the drop down on the specific evaluation. In the event you have not received a timely completed evaluation from a specific reviewer, you can use the options in the ACTIONS column to resend the email or copy a direct link to the evaluation and send it to the reviewer personally. If you have made an error and need to re-create the evaluation, you can use the red X button delete the review and start the process again.`,
  }
];

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='button'><b>{steps[activeStep].label}</b></Typography>
      </Paper>
      
      <Box sx={{ height: '100vh', maxWidth: 400, width: '100%', p: 2, overflowY:'auto',paddingBottom:'175px' }}>
      <img src={steps[activeStep].image} width="100%" height="auto"/>
      <div style={{marginTop:'20px'}}>{steps[activeStep].description}</div>
      </Box>
      <MobileStepper
      sx={{position:'sticky', backgroundColor:'#199A71', bottom:0, right:0,left:0, bottom:0, borderTop:'black solid .5px', fontWeight:'bold', color:'white'}}
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{fontWeight:'bold', color:'white'}}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button sx={{fontWeight:'bold', color:'white'}} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}