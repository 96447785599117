import { CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useEffect} from "react";
import { useState } from "react";
import { API } from "../Controllers/API";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { EPEWebDocument } from "../components/web-document-screens/epe-web";
import { SelfWebDocument } from "../components/web-document-screens/self-web";
import { ThreeSixtyWebDocument } from "../components/web-document-screens/360-web";
import { UEDWebDocument } from "../components/web-document-screens/ue-web";
export const DocWebView = (props)=>{
    const location = useLocation();
    function getQueryVariable(variable) {
        var query = location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    function format(inputDate) {
        var date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        }
    }

    const a  = getQueryVariable('a')
    const b  = getQueryVariable('b')
    const c  = getQueryVariable('c')
    const d  = getQueryVariable('d')
    // const e  = props.ReviewerName
    const queryParameters = new URLSearchParams(window.location.search)
    let dataObject = {reviewData:{
        goalDisplay:false
    }}
const[reviewObj, setReviewObj] = useState({reviewee:{
    name:'',
    jobTitle:'',
    employeeId:'',
    department:'',
     timeInPosition:'',
    reviewPeriod:'',


}, worksheetData:[], goalPlanningSection:{status:null, show:'null'}})
const[showEmployeeId, setEmployeeId] = useState('block')
const[goalDisplay, setGoalDisplay] = useState(false)
const [loading,setLoading] = useState(true)
const [completed, setCompleted] = useState(false)
let questions = dataObject.reviewData.worksheetData
let finalReviewData = {
    reviewerId: d,
    jobTitle:dataObject.reviewData.jobTitle,
    employeeId:dataObject.reviewData.employeeId,
    department:dataObject.reviewData.aa,
     timeInPosition:dataObject.reviewData.timeInPosition,
    reviewPeriod:dataObject.reviewData.reviewPeriod,
    worksheetData: questions
}

const EditQuestionArray = (event,a,b)=>{
    let val = event.target.value;

    console.log(val)
console.log(a)
console.log(b)

const index = questions.findIndex(object => {
    return object.catIndex === b;
  }); 

  console.log(index)

  const questionIndex = questions[index].questions.findIndex(object => {
    return object.id === a;
  }); 

  questions[index].questions[questionIndex].answer = val

  console.log(questions)
}
const RetrieveData = async ()=>{
    let resp =  await axios.post(API+'load-document', {userID:a, employeeID:b, reviewID:c, reviewerID:d}, (err, response)=>{return response });
    console.log(resp.data)
    setReviewObj(resp.data)
    if(resp.data.completedReview.hasOwnProperty('goalPlanningSection')){
        setGoalDisplay(resp.data.reviewData.goalPlanningSection)
    }

   
   
   setLoading(false)
}
const[topSection, setTopSection] = useState('')
    useEffect(()=>{
   
      setLoading(true)
console.log(a + " "+b + " "+c+ " " + d)

RetrieveData()


    }, [])
   

    async function downloadDocx(params) {
        // const converted = htmlDocx.asBlob(htmlString);
        // saveAs(converted, 'test.docx';
      }
      if(!loading){
      if(!completed){
        
            if(reviewObj.completedReview[0].reviewType === "Employee Performance Evaluation"){
                
        
    return (
         <EPEWebDocument
         RevieweeName={reviewObj.completedReview[0].reviewee.name}
         Reviewer={reviewObj.name}
         JobTitle={reviewObj.completedReview[0].jobTitle}
         ReviewPeriod={reviewObj.completedReview[0].reviewPeriod}
         EmployeeId={reviewObj.completedReview[0].employeeId}
         Department={reviewObj.completedReview[0].department}
         TimeInPosition={reviewObj.completedReview[0].timeInPosition}
         GoalDisplay={(reviewObj.completedReview[0].partFourA.length > 0 ||reviewObj.completedReview[0].partFourB.length) ? true:false}
         PartTwoTextArea={reviewObj.completedReview[0].partTwo}
         PartFourATextArea={reviewObj.completedReview[0].partFourA}
         PartFourBTextArea={reviewObj.completedReview[0].partFourB}
         OnSubmit={async()  =>{
            let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
            console.log(resp.data)

         }}
         OverallScore={reviewObj.completedReview[0].partThree}
         Signature={reviewObj.completedReview[0].signature}
         SignatureDate={format(reviewObj.completedReview[0].signatureDate)}
         WorkSheetDocData={reviewObj.completedReview[0].worksheetData}
         WorksheetData={reviewObj.completedReview[0].worksheetData.map(i=>{
            const catId = i.catIndex
            return (<div><p><b>{i.cat}</b></p>
            {i.questions.map((i)=>{
                return(
               <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                <div style={{display:'block', flexDirection:'row' }}>
                             
                             <p >{i.question}</p>
                             <div style={{textAlign:'right'}} >
                            <Typography variant="button"><b>{i.answer}</b></Typography>
                            </div>
                            {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                             </div>
                <Divider style={{backgroundColor:"#D0D0D0"}}/>
                </Box>
                )
            })}


            </div>
              
            )
        })}
         />
        )

    }


    if(reviewObj.completedReview[0].reviewType === "Employee Self Evaluation"){
            
        
        return (
            <SelfWebDocument
            RevieweeName={reviewObj.name}
         JobTitle={reviewObj.completedReview[0].jobTitle}
         ReviewPeriod={reviewObj.completedReview[0].reviewPeriod}
         EmployeeId={reviewObj.completedReview[0].employeeId}
         Department={reviewObj.completedReview[0].department}
         TimeInPosition={reviewObj.completedReview[0].timeInPosition}
         WorkSheetDocData={reviewObj.completedReview[0].worksheetData}
         GoalDisplay={(reviewObj.completedReview[0].partFourA.length > 0 ||reviewObj.completedReview[0].partFourB.length) ? true:false}
            PartTwoTextArea={reviewObj.completedReview[0].partTwo}
            PartFourATextArea={reviewObj.completedReview[0].partFourA}
            PartFourBTextArea={reviewObj.completedReview[0].partFourB}
            OnSubmit={async()  =>{
               let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
               console.log(resp.data)
   
            }}
            OverallScore={reviewObj.completedReview[0].partThree}
            Signature={reviewObj.completedReview[0].signature}
            SignatureDate={format(reviewObj.completedReview[0].signatureDate)}
            WorksheetData={reviewObj.completedReview[0].worksheetData.map(i=>{
               const catId = i.catIndex
               return (<div><p><b>{i.cat}</b></p>
               {i.questions.map((i)=>{
                   return(
                  <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                   <div style={{display:'block', flexDirection:'row' }}>
                                
                                <p >{i.question}</p>
                                <div style={{textAlign:'right'}} >
                               <Typography variant="button"><b>{i.answer}</b></Typography>
                               </div>
                               {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                                </div>
                   <Divider style={{backgroundColor:"#D0D0D0"}}/>
                   </Box>
                   )
               })}
   
   
               </div>
                 
               )
           })}
            />
            )
    
        }

    if(reviewObj.completedReview[0].reviewType === "360 Evaluation"){
            
        
        return (
             <ThreeSixtyWebDocument
             RevieweeName={reviewObj.completedReview[0].reviewee.name}
             Reviewer={reviewObj.name}
             ReviewPeriod={reviewObj.completedReview[0].reviewPeriod}
             Department={reviewObj.completedReview[0].department}
             PartTwo={reviewObj.completedReview[0].partTwo}
             WorkSheetDocData={reviewObj.completedReview[0].worksheetData}
             WorksheetData={reviewObj.completedReview[0].worksheetData.map(i=>{
                return (<div><p><b>{i.cat}</b></p>
                {i.questions.map((i)=>{
                   return(
                    <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                     <div style={{display:'block', flexDirection:'row' }}>
                          
                     <p >{i.question}</p>
                     <div style={{textAlign:'right'}} >
                    <Typography variant="button"><b>{i.answer}</b></Typography>
                    </div>
                    {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                     </div>
                     <Divider style={{backgroundColor:"#D0D0D0"}}/>
                     </Box>
                     )
                 })}
    
    
                </div>
                  
                )
            })}
             />
            )
    
        }

        if(reviewObj.completedReview[0].reviewType === "Upward Evaluation"){
            
        
            return (
                
                 <UEDWebDocument
                 RevieweeName={reviewObj.completedReview[0].reviewee.name}
                 ReviewPeriod={reviewObj.completedReview[0].reviewPeriod}
                 Department={reviewObj.completedReview[0].department}
                 WorkSheetDocData={reviewObj.completedReview[0].worksheetData}
                 CompletedScreen={()=>setCompleted(true)}
                 Reviewer={reviewObj.name}
                 PartTwo={reviewObj.completedReview[0].partTwo}
                 OnSubmit={async()  =>{
                    let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
                    console.log(resp.data)
        
                 }}
                 WorksheetData={reviewObj.completedReview[0].worksheetData[0].questions.map((i)=>{
                        return(
                       <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                        <div style={{display:'block', flexDirection:'row' }}>
                             
                        <p >{i.question}</p>
                        <div style={{textAlign:'right'}} >
                       <Typography variant="button"><b>{i.answer}</b></Typography>
                       </div>
                       {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                        </div>
                        <Divider style={{backgroundColor:"#D0D0D0"}}/>
                        </Box>
                        )
                    })}
        
        
       
                      
                    
               
                 />
           
                )
        
            }

      }  else{
        return(
        <Box sx={{backgroundColor:'#D5EDE3', padding:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
      
        <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px',justifyContent:'center'}}>
            <Typography>Thanks for completing the review. Your answers have been sent back to the sender. If you have any questions or would like a copy of the completed review you can reach out to the person who sent them.</Typography>
            </Paper>
            </Box>)
    }
      } else{
        return (<Box sx={{textAlign:'center', marginTop:20}}>
        <CircularProgress/>
        <Typography><b>Loading</b></Typography>
      </Box>)
      }
   
}