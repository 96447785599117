import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactPlayer from 'react-player/vimeo'

export const DemoVideoScreen = ()=>{



    return(
        <Box sx={{padding:'10px'}}>
            <Typography variant="button"><b>DEMO VIDEO</b></Typography>
<iframe src="https://player.vimeo.com/video/792025068?h=fd763e69bf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="400" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Performance Evaluation Center Demo"></iframe>
<div style={{padding:'10px', height:'100vh', overflow:'auto', paddingBottom:'300px'}}>
<Typography variant="button"><b>Video Transcript:</b></Typography>

<Typography  variant="subtitle2">When you access the newly improved Performance Evaluation Center, you will see the navigation menu on the left side of the screen.  You will want to start by making sure the email address on your account is the one you will want to use to be contacted by the person receiving the evaluations. It auto-defaults to your HDHR membership email address. If you would like to change it you can easily do this by clicking the profile icon in the top left corner. </Typography>
<br></br>
<Typography variant="subtitle2"><b>NOTE: This will not change the login or notification email for HelpDeskForHR.com </b></Typography>
<br></br>
<Typography variant="subtitle2">Click EDIT and type the email you would like to use for reply-to if an evaluator receives a review email and needs to ask a question or reply back with other information.</Typography>
<br></br>
<Typography variant="subtitle2">Once you have edited the email address click save. If the email address showing does not need to be edited, you can simply close the profile section. 
</Typography>
<br></br>
<Typography variant="subtitle2">Next I’ll walk you through the individual features of this easy to use application.</Typography>
<br></br>
<Typography variant="subtitle2"><b>DASHBOARD</b></Typography>
<br></br>
<Typography variant="subtitle2">Let’s review the features of the Dashboard. If there is no employee or evaluation information in your database, the charts will be blank. But once you have started adding employees and conducting evaluations this information will populate to show you ratios and types of evaluations you have conducted. </Typography>
<br></br>
<Typography variant="subtitle2">Below, you will see the current ongoing evaluations that are being conducted as well as a section of evaluations that you have scheduled for this month and may need to set up. </Typography>
<br></br>
<Typography variant="subtitle2"><b>EMPLOYEES</b></Typography>
<br></br>
<Typography variant="subtitle2">This is where you will add your database of employees who will perform reviews and/or be reviewed. You can easily do this by adding one at a time OR if you would like to upload a list you can do that by downloading our sample CSV file and adding your information to the proper fields. NOTE: if you use this template all of the headings and layout must stay the same. Simply add the employee’s information and upload. Once the upload is complete, you will see your list of employees has populated the database. You can search for a specific employee by first or last name.</Typography>
<br></br>
<Typography variant="subtitle2"><b>EVALUATION MANAGER</b></Typography>
<br></br>
<Typography variant="subtitle2">After setting up your employees section, you are now able to move on to the evaluation manager. Here you will setup, demo and send evaluations to reviewers. To add an evaluation, click the ADD EVALUATION button.</Typography>
<br></br>
<Typography variant="subtitle2">Select the type of evaluation you would like to conduct. Then select the person to be evaluated.
</Typography>
<br></br>
<Typography variant="subtitle2">After that you can use the drop-downs to customize your evaluation. 
</Typography>
<br></br>
<Typography variant="subtitle2">Start by adding the reviewers. You will see a the list of employees on the right. You can add one or more reviewers by simply clicking their names. If you have a long list of reviewers, you quickly find them by using the Search box.
</Typography>
<br></br>
<Typography variant="subtitle2">If you have added a name on accident and need to remove them, simply click their name in the right column and they will be removed. 
</Typography>
<br></br>
<Typography variant="subtitle2">Next, you can move to the criteria section and setup your worksheet with a few options. You can simply choose questions from “select options” Or use the “Use Suggested Criteria” option which will populate all of the criteria from the built-in worksheet. You can also add custom categories and criterion by using the ADD CUSTOM CRITERIA function.
</Typography>
<br></br>
<Typography variant="subtitle2">Once you are happy with your customized worksheet, you can save it to use again for other evaluations by clicking the save worksheet button. Add a title and it will be saved to your account to be used anytime on any device or browser.
</Typography>
<br></br>
<Typography variant="subtitle2">After you have created the worksheet, you can finalize the evaluation by entering the necessary information. 
</Typography>
<br></br>
<Typography variant="subtitle2">If you want to schedule the persons next evaluation you can set the date for that and it will be added to the evaluation calendar.
</Typography>
<br></br>
<Typography variant="subtitle2">The final steps to take are to add the names and emails of anyone you would like to CC on the completed evaluations. If there is no one you would like to copy simply leave it blank. Then you are able to Demo the evaluation you have just setup. Then if everything looks good. Click Send the evaluations.
</Typography>
<br></br>
<Typography variant="subtitle2">The setup processes for each type of evaluation are very similar. Simply follow the dropdowns and complete the required and optional information fields.
</Typography>
<br></br>
<Typography variant="subtitle2">Once the evaluation is setup you will see it on the evaluation manager list. If you click the dropdown button on the evaluation, you will see the different reviewers you selected.  Here you can resend the email or copy a link to the evaluation to send to the person personally. You can also see the completion status of each evaluation. When the evaluation has been completed, you can view it with the web viewer option and download it as a docx file.
</Typography>
<br></br>
<Typography variant="subtitle2">So, let’s move on and see how easy it is to create a Performance Improvement Plan.
</Typography>
<br></br>
<Typography variant="subtitle2"><b>PERFORMANCE IMPROVEMENT PLAN</b></Typography>
<br></br>
<Typography variant="subtitle2">The process for setting up a Performance Improvement Plan is similar to the Evaluation setup process. When you are ready to make a plan, click Add PIP. 
</Typography>
<br></br>
<Typography variant="subtitle2">Next, select the name of the person you are creating the plan for. 
</Typography>
<br></br>
<Typography variant="subtitle2">You will now see some helpful instruction to keep in mind when creating the plan. Scroll down and you will see the Purpose section where you can list the Areas of Concern and details about OBSERVATIONS, PREVIOUS DISCUSSIONS, OR ADDITIONAL TRAINING you have PROVIDED.
</Typography>
<br></br>
<Typography variant="subtitle2">Next,  move onto the Improvement Goals section. Here you can add and detail each goal you would like the person to reach. </Typography>
<br></br>
<Typography variant="subtitle2">Moving on to RESOURCES and EXPECTATIONS, detail the resources the person can use to help them reach their goals and detail the expectation for the outcome of the plan. 
</Typography>
<br></br>
<Typography variant="subtitle2">Moving on to the progress checkpoints, you can select a goal and add checkpoints for future follow-up. Select the date of checkpoint, enter in the type of follow up and the progress you expect them to make. Feel free to do this for multiple goals. Or, add multiple checkpoints for the same goal. The checkpoint dates will be added to the evaluation calendar as well. 
</Typography>
<br></br>
<Typography variant="subtitle2">Now you can move on to Finalizing the plan. Enter the date and any of the optional information you prefer. 
</Typography>
<br></br>
<Typography variant="subtitle2">If you would like to email the plan to the person immediately, you can select the checkbox. You can also choose to send it at later time by leaving the box unchecked. 
</Typography>
<br></br>
<Typography variant="subtitle2">Finally, add anyone you would like to CC on the signed plan. If you do not want to copy anyone you can leave it blank.
</Typography>
<br></br>
<Typography variant="subtitle2">Once you’re finished, you can choose to preview the completed plan and then click create and send plan.
</Typography>
<br></br>
<Typography variant="subtitle2">After you have created the plan you will see it in the list where you have some action options. If you have not yet sent the plan you can click the email icon to send the plan. You can also view the plan and copy a link to the plan. 
</Typography>
<br></br>
<Typography variant="subtitle2">Once the plan has been signed you can view it and update the checkpoints, when the time comes, by clicking the update button, selecting the checkpoint, and adding notes.
</Typography>
<br></br>
<Typography variant="subtitle2"><b>EVALUATION CALENDAR</b></Typography>
<br></br>
<Typography variant="subtitle2">The evaluation calendar is here to help you remember to setup future evaluations, checkpoint or other necessary followup. You can click on the different months or years to see what you have scheduled. 
</Typography>
<br></br>
<Typography variant="subtitle2">Add a calendar item by simply clicking add calendar item button and filling in the information. 
</Typography>
<br></br>
<Typography variant="subtitle2">I hope you have enjoyed this tutorial of the Performance Evaluation Center. Please do not hesitate to reach out to support if you have any questions or suggestions along the way. We are confident you will love this new tool and will get years of benefit from its use.
</Typography>
<br></br>

</div>
        </Box>
    )
}