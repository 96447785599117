import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography } from '@mui/material';
import { Row, Col } from 'react-bootstrap';



const AddEmployeeForm = (props) =>{

    return (
        <div>
        <Form.Label><Typography sx={{fontSize:'18px'}} variant='button'>Add Person</Typography></Form.Label>
        <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label><b>First Name</b></Form.Label>
          <Form.Control value={props.PersonsFirstNameValue} onChange={props.PersonsFirstName} type="text" placeholder="Name" />
          
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label><b>Last Name</b></Form.Label>
          <Form.Control value={props.PersonsLastNameValue} onChange={props.PersonsLastName} type="text" placeholder="Name" />
          
        </Form.Group>
       </Row>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><b>Email</b></Form.Label>
          <Form.Control value={props.PersonsEmailValue} onChange={props.PersonsEmail}  type="text" placeholder="Email" />
          <Form.Text className="text-muted">
            This email will be used to send evaluations to.
          </Form.Text>
        </Form.Group>
        <Form.Label><b>Role:</b></Form.Label>
        <Form.Group onChange={props.OnChangeRadio} className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="radio" value="Manager" name="group1" label="Manager" />
          <Form.Check  type="radio" value="Employee" name="group1" label="Employee" />
          
        </Form.Group>
        <Button onClick={props.OnSubmit}  style={{backgroundColor:'#52C47B', borderColor:'#52C47B'}} variant="primary" type="submit">
          <b>Add Employee</b>
        </Button>
        </div>
    )
}
export default AddEmployeeForm;