import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Form } from "react-bootstrap";



export const CheckpointForm = (props)=>{
    return (
        <Form.Group>
                        <Form.Label><Typography variant="button"><b>Select the checkpoint you have conducted the follow-up on:</b> {props.FollowUpType}</Typography></Form.Label><br></br>

            <Form.Select onChange={props.OnSelectCheckPoint}>
                <option>---</option>
                {props.Checkpoints}
            </Form.Select>

            <Form.Group>
            <Form.Label><Typography variant="button"><b>Checkpoint Follow-up Type:</b> {props.FollowUpType}</Typography></Form.Label><br></br>


                <Typography variant="button"><b>NOTES:</b></Typography>
                <br></br>
            <Typography  variant="subtitle2">Enter the details that describe how the checkpoint went and the next steps provided to the employee.</Typography>

            <Form.Control value={props.NotesValue} style={{height:"200px"}} onChange={props.NotesOnChange} as='textarea'/>
            <br></br>
            <Box sx={{textAlign:'end'}}>
            <Button onClick={props.OnClickUpdate} variant="contained">Update</Button>
            </Box> 
            </Form.Group>
        </Form.Group>
    )
}