import { CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useEffect} from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {Button} from "@mui/material";
import '../assets/css/demo.css'
import { EPEDemo } from "../components/demo-screens/EPEDemo";
import { ThreeSixtyDemo } from "../components/demo-screens/threesixty-demo";
import { UEDemo } from "../components/demo-screens/ue-demo";
import '../assets/css/review-screen.css'
import { PIPDemo } from "../components/demo-screens/pip-demo";

export const Demo = ()=>{
    const dataObject = JSON.parse(localStorage.getItem('refer'))
const[reviewObj, setReviewObj] = useState({reviewee:{
    name:'',
    jobTitle:'',
    employeeId:'',
    department:'',
     timeInPosition:'',
    reviewPeriod:'',


}, worksheetData:[], goalPlanningSection:{status:null, show:'null'}})
const[showEmployeeId, setEmployeeId] = useState('block')
const[goalDisplay, setGoalDisplay] = useState(null)

const [loading,setLoading] = useState(true)

const[topSection, setTopSection] = useState('')
    useEffect(()=>{
        setReviewObj(JSON.parse(localStorage.getItem('refer')))
        
   
//    console.log(reviewObj.goalPlanningSection)
//    if(reviewObj.goalPlanningSection == true){
//     setGoalDisplay('block')
//     console.log(goalDisplay)
//    } 
   
//    if(reviewObj.goalPlanningSection === false){
//     setGoalDisplay('none')
//     console.log(goalDisplay)
//    }
   
    //   console.log(reviewObj)
      if(reviewObj.hasOwnProperty('goalPlanningSection')){
        setGoalDisplay(reviewObj.goalPlanningSection)
    }

   if(goalDisplay != 'null'){
    setTimeout(()=>setLoading(false),1000)   
   }
    }, [])
   
    async function downloadDocx(params) {
        // const converted = htmlDocx.asBlob(htmlString);
        // saveAs(converted, 'test.docx');
      }
      if(!loading){

        if(reviewObj.type === "Employee Performance Evaluation"){
            
        
    return (
         <EPEDemo
         RevieweeName={reviewObj.reviewee.name}
         JobTitle={reviewObj.jobTitle}
         ReviewPeriod={reviewObj.reviewPeriod}
         EmployeeId={reviewObj.employeeId}
         Department={reviewObj.department}
         TimeInPosition={reviewObj.timeInPosition}
         GoalDisplay={reviewObj.goalPlanningSection}
         WorksheetData={reviewObj.worksheetData.map(i=>{
            return (<div><p><b>{i.cat}</b></p>
            {i.questions.map((i)=>{
                return(
               <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                     
                <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                <select className="answer" style={{flex:'20%', width:'20px', height:'30px', marginRight:'10px'}}>
                <option>---</option>
                <option>Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                <option>Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                <option>Fully Meets Expectations (commendable performance that meets goals)</option>
                <option>Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                <option>Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                <option>NA</option>
                </select>
               {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                </div>
                <Divider style={{backgroundColor:"#D0D0D0"}}/>
                </Box>
                )
            })}


            </div>
              
            )
        })}
         />
        )

    }

    if(reviewObj.type === "Employee Self Evaluation"){
            
        
        return (
             <EPEDemo
             RevieweeName={reviewObj.reviewee.name}
             JobTitle={reviewObj.jobTitle}
             ReviewPeriod={reviewObj.reviewPeriod}
             EmployeeId={reviewObj.employeeId}
             Department={reviewObj.department}
             TimeInPosition={reviewObj.timeInPosition}
             GoalDisplay={reviewObj.goalPlanningSection}
             WorksheetData={reviewObj.worksheetData.map(i=>{
                return (<div><p><b>{i.cat}</b></p>
                {i.questions.map((i)=>{
                    return(
                   <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                    <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                         
                    <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                    <select className="answer" style={{flex:'20%', width:'20px', height:'30px', marginRight:'10px'}}>
                    <option>---</option>
                    <option>Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                    <option>Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                    <option>Fully Meets Expectations (commendable performance that meets goals)</option>
                    <option>Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                    <option>Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                    <option>NA</option>
                    </select>
                   {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                    </div>
                    <Divider style={{backgroundColor:"#D0D0D0"}}/>
                    </Box>
                    )
                })}
    
    
                </div>
                  
                )
            })}
             />
            )
    
        }

    if(reviewObj.type === "360 Evaluation"){
            
        
        return (
             <ThreeSixtyDemo
             RevieweeName={reviewObj.reviewee.name}
             ReviewPeriod={reviewObj.reviewPeriod}
             Department={reviewObj.department}
             GoalDiplay={goalDisplay}
             WorksheetData={reviewObj.worksheetData.map(i=>{
                return (<div><p><b>{i.cat}</b></p>
                {i.questions.map((i)=>{
                    return(
                   <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                    <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                         
                    <p className="question"  style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                    <select className="answer"  style={{flex:'20%', width:'20px', height:'30px', marginRight:'10px'}}>
                    <option>---</option>
                    <option>Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                    <option>Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                    <option>Fully Meets Expectations (commendable performance that meets goals)</option>
                    <option>Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                    <option>Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                    <option>NA</option>
                    </select>
                   {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                    </div>
                    <Divider style={{backgroundColor:"#D0D0D0"}}/>
                    </Box>
                    )
                })}
    
    
                </div>
                  
                )
            })}
             />
            )
    
        }

        if(reviewObj.type === "Upward Evaluation"){
            
        
            return (
                 <UEDemo
                 RevieweeName={reviewObj.reviewee.name}
                 ReviewPeriod={reviewObj.reviewPeriod}
                 Department={reviewObj.department}
                 GoalDiplay={goalDisplay}
                 WorksheetData={reviewObj.worksheetData.map(i=>{
                    return (<div><p><b>{i.cat}</b></p>
                    {i.questions.map((i)=>{
                        return(
                       <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                        <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                             
                        <p className="question"  style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                        <select className="answer"  style={{flex:'20%', width:'20px', height:'30px', marginRight:'10px'}}>
                        <option>---</option>
                        <option>Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                        <option>Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                        <option>Fully Meets Expectations (commendable performance that meets goals)</option>
                        <option>Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                        <option>Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                        <option>NA</option>
                        
                        </select>
                       {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                        </div>
                        <Divider style={{backgroundColor:"#D0D0D0"}}/>
                        </Box>
                        )
                    })}
        
        
                    </div>
                      
                    )
                })}
                 />
                )
        
            }

            if(reviewObj.reviewType === "Performance Improvement Plan"){
            
        
                return (
                     <PIPDemo
                     RevieweeName={reviewObj.reviewee.name}
                     Department={reviewObj.department}
                     Supervisor={reviewObj.supervisor}
                     Date={reviewObj.dateCreated}
                     AreasOfConcern={reviewObj.areasOfConcern}
                     Observations={reviewObj.observations}
                     Resources={reviewObj.resources}
                     Expectations={reviewObj.expectations}
                     GoalList={reviewObj.goalsArray}
                     Checkpoints={reviewObj.checkpoints}
                     />
                    )
            
                }

      } else{
        return(<Box sx={{textAlign:'center', marginTop:20}}>
        <CircularProgress/>
        <Typography><b>Loading</b></Typography>
      </Box>)
      }
}