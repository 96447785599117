import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import EditIcon from '@mui/icons-material/Edit';

import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import logo from '../../assets/css/PECLogo.png'
import {  useNavigate } from "react-router-dom";
// import '../assets/css/table-style.css'
import {useLocation} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Accordion, Container, Modal, OverlayTrigger } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import {Table} from "react-bootstrap";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SendIcon from '@mui/icons-material/Send';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CreateIcon from '@mui/icons-material/Create';
import Info from "@mui/icons-material/Info";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, Tooltip } from "@mui/material";
import { API } from "../../Controllers/API";
import { formatDate } from "../../classes/global-functions";
import ToolTipButton from "../../components/TooltipButton";
import uuid from "react-uuid";
import { CoachingPlan } from "./CoachingPlan";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CopyAll from "@mui/icons-material/CopyAll";
import { BsToolTip } from "../../components/BsToolTip";
import ToolTipButtonMenu from "../../components/ToolTipButtonMenu";
import CP from "../../components/CP";
import CPEdit from "./CPEdit";

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link target='_blank' color="inherit" href="https://employeetrainingtoolkit.com/">
          Employee Training Tool-Kit
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

 

const navItems = ['Back To Coaching Plans'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundImage:'linear-gradient(#1A9A71, #1A9A71) !important',
      color: theme.palette.common.white,
      fontWeight:'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



export const CoaochingPlanDetails = ()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const navigate = useNavigate();
    const [statusArr, setStatusArr] = useState([])
    const location = useLocation()
    // const { height, width } = useWindowSize();
    const [coachingData, setCoachingData] = useState({})
    const [downloadableData, setDownloadableData] = useState()
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(false);
  
        //Checkpoint State
  const [checkpoints, setCheckpoints] = useState([])
  const [checkpointGoal, setCheckpointGoal] = useState('')
  const [checkpointDate, setCheckpointDate] = useState('')
  const [checkpointFollowUpType, setCheckpointFollowUpType] = useState('')
  const [checkpointProgressExpected, setCheckpointProgressExpected] = useState('')
const [formData, setFormData] = useState({
  id:uuid(),
  name:{name: ``},
  dateOfNextReview: checkpointDate,
  reviewType: ``,
  notes:`SessionType: ${checkpointFollowUpType} ${checkpointProgressExpected}`,
  status: false,
  sessionData:{
    sessionDate:'',
    concernsAndGoals:'',
    strengths:'',
    areasImprovement:'',
    keyObjectives:[],
    actionItems:[],
    followUpDate:'',
    managerSign: false,
    managerSignature:'',
    signatureDate:''
    
  }});

    const [completedCheckpoints, setCompletedCheckpoints] = useState([]);
    const [show, setShow] = useState(false)
    const handleShow = ()=>{
      setShow(true)
  }

  const handleClose = ()=>{
      setShow(false)
  }

  const [showActionItemModal, setShowActionItemModal] = useState(false)

  const handleShowActionItem = ()=>{
    setShowActionItemModal(true)
}

const handleCloseActionItem = ()=>{
  setShowActionItemModal(false)
  setActionItem('');
setResponsible('');
setDueDate('');
setNotes('');
}

  const [showCS, setShowCS] = useState(false)
  const handleShowCS = (i)=>{
    setFormData(i)
    setShowCS(true)
    console.log(i)

}

const [cpEditModalShow, setCpEditModalShow] = useState(false);

const handleShowCPEdit = ()=>{
  setCpEditModalShow(true)
}

const handleCloseCPEdit = ()=>{
  setCpEditModalShow(false)
}

const handleUpdate = async (coachingPlan) => {
  try {
    const response = await axios.post(API + 'update-cp', { id: data.id, coachingPlan });
    console.log('Updated session:', response.data);

    // Optionally, update your local state after a successful update
  } catch (error) {
    console.error('Error updating session:', error);
    // Handle error
  }
};

const handleDelete = async (idToDelete) => {
  // Assuming idToDelete is the reviewId you want to delete
  const updatedCoachingData = {
    ...coachingData,
    checkpoints: coachingData.checkpoints.filter((item) => item.id !== idToDelete),
  };


  setCoachingData(updatedCoachingData);

  try {
    const response = await axios.post(API + 'delete-cp-checkpoint', { id: data.id, coachingPlanId: updatedCoachingData.reviewId, checkpointId: idToDelete });
    console.log('Updated session:', response.data);

    // Optionally, update your local state after a successful update
  } catch (error) {
    console.error('Error updating session:', error);
    // Handle error
  }
};

const handleCloseCS = ()=>{
  const conf = window.confirm('Your changes will not be saved if you close this window. If you want to save your changes make sure you click the UPDATE SESSION button. Are you sure you would like to close the window?')
  if(conf){
    
    setShowCS(false)

  }
}
;



const updateCheckpoint = async () => {
  try {
    // Assuming formData contains the id of the checkpoint you want to update
    const checkpointIdToUpdate = formData.id;

    // Assuming there's a function to get the updated checkpoint data from formData
    const updatedCheckpoint = {
      ...formData,
      status: true, // Set the status to true
    };

    // Update the coachingData.checkpoints array
    setCoachingData((prevData) => ({
      ...prevData,
      checkpoints: prevData.checkpoints.map((checkpoint) =>
        checkpoint.id === checkpointIdToUpdate ? updatedCheckpoint : checkpoint
      ),
    }));

    // Update the backend with the updated checkpoint
    await axios.post(API+'update-session', {id: data.id,cpId:location.state.i.reviewId, checkpoint: updatedCheckpoint});
    
    // Optionally, update your local state after a successful update
    

    // Optionally, close the session modal
    setShowCS(false);
  } catch (error) {
    console.error('Error updating checkpoint:', error);
    // Handle error
  }
};

  const AddCheckpoint = async ()=>{
    let checkpoint = {
      id:uuid(),
      name:{name: `${coachingData.name.name} Coaching Session`},
      dateOfNextReview: checkpointDate,
      reviewType: `Coaching Session - ${checkpointFollowUpType}`,
      notes:`SessionType: ${checkpointFollowUpType} ${checkpointProgressExpected}`,
      status: false,
      sessionData:{
        concernsAndGoals:'',
        strengths:'',
        areasImprovement:'',
        keyObjectives:[],
        actionItems:[],
        followUpDate:'',
        managerSign:false,
        managerSignature:'',
        signatureDate:''
        
      },
    
  }
 
  setCoachingData((prevFormData) => ({
    ...prevFormData,
    checkpoints: [...prevFormData.checkpoints, checkpoint],
  }));

  try {
    const response = await axios.post(API + 'add-cp-checkpoint', { id: data.id, coachingPlanId: coachingData.reviewId, checkpoint: checkpoint });
    console.log('Updated session:', response.data);

    // Optionally, update your local state after a successful update
  } catch (error) {
    console.error('Error updating session:', error);
    // Handle error
  }


  setCheckpointDate('')
  setCheckpointFollowUpType('')
  setCheckpointProgressExpected('')

setUpdate(!update)
  console.log(checkpoint)
  setShow(false)
  }

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'managerName') {
      // Update the 'manager.name' property
      setFormData((prevData) => ({
        ...prevData,
        manager: {
          ...prevData.manager,
          name: value,
        },
      }));
    } else if (name === 'managerEmail') {
      // Update the 'manager.email' property
      setFormData((prevData) => ({
        ...prevData,
        manager: {
          ...prevData.manager,
          email: value,
        },
      }));
    } else {
      // Update other fields as usual
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      console.log(formData)
    }
  };


//ActionItems

const [currentStepIndex, setCurrentStepIndex] = useState(null);
const [actionItem, setActionItem] = useState('');
const [responsible, setResponsible] = useState('');
const [dueDate, setDueDate] = useState('');
const [notes, setNotes] = useState('');
const [showSendBtns, setShowSendBtns] = useState(true)
  const handleAddActionItem = () => {
    if (currentStepIndex !== null) {
      // Editing existing step
     setFormData((prevData) => ({
  ...prevData,
  sessionData: {
    ...prevData.sessionData,
    actionItems: prevData.sessionData.actionItems.map((step, i) =>
      i === currentStepIndex
        ? {
          actionItem: actionItem,
          responsible: responsible,
          dueDate: dueDate,
          notes: notes,
        }
        : step
    ),
  },
}));
    } else {
      // Adding new step
      setFormData((prevData) => ({
        ...prevData,
        sessionData: {
          ...prevData.sessionData,
          actionItems: [
            ...prevData.sessionData.actionItems,
            {
              actionItem: actionItem,
              responsible: responsible,
              dueDate: dueDate,
              notes: notes,
            },
          ],
        },
      }));
    }
setUpdate(!update)

setShowActionItemModal(true);
    handleCloseActionItem();
    
  };

  const handleEditActionItem = (index) => {
    const step = formData.sessionData.actionItems[index];
    setCurrentStepIndex(index);
    setActionItem(step.actionItem);
    setResponsible(step.responsible);
    setDueDate(step.dueDate);
    setNotes(step.notes);
    setShowActionItemModal(true);
  };

  const handleDeleteActionItem = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      sessionData: {
        ...prevData.sessionData,
        actionItems: prevData.sessionData.actionItems.filter((_, i) => i !== index),
      },
    }));
  };
 
  

  
    useEffect( ()=>{
      console.log(statusArr.length)
      console.log(data.replyToEmail)
        console.log(location.state)
        const getData = async ()=>{
          let resp = await axios.post(API+ 'get-cp',{id:data.id, cpId: location.state.i.reviewId})

          console.log(resp.data);

          if(resp.data[0].sendToManager === false  && resp.data[0].employeeSignature.signature !== '') {
            setShowSendBtns(false)
          } 

          if(resp.data[0].sendToManager === true  && resp.data[0].employeeSignature.signature !== '' && resp.data[0].managerSignature.signature !== '') {
            setShowSendBtns(false)

          }



          setCoachingData(resp.data[0])
           
            setLoading(false)
        }

        getData()
        
     

        

    },[update])
// RESEND TO EMPLOYEE
    const resendEmailToEmployee = async (userId) => {
      try {
        const response = await axios.post(API + 'resend-cp-to-employee', { userId:data.id, coachingPlan: coachingData, email: data.replyToEmail });
        console.log(response.data); // Log the server response
        return response.data; // You can handle the response as needed
      } catch (error) {
        console.error('Error resending email to employee:', error);
        throw error; // Handle the error or rethrow as needed
      }
    };


// RESEND TO MANAGER
const resendEmailToManager = async (userId) => {
  try {
    const response = await axios.post(API + 'resend-cp-to-manager',{ userId:data.id, coachingPlan: coachingData, email: data.replyToEmail });
    console.log(response.data); // Log the server response
    return response.data; // You can handle the response as needed
  } catch (error) {
    console.error('Error resending email to manager:', error);
    throw error; // Handle the error or rethrow as needed
  }
};

const sendSessionToManager = async (userId, sessionId) => {
  try {
    const response = await axios.post(API + 'send-cp-session-to-manager',{ userId, coachingPlan: coachingData, email: data.replyToEmail, sessionId: sessionId });
    console.log(response.data); // Log the server response
    return response.data; // You can handle the response as needed
  } catch (error) {
    console.error('Error sending email to manager:', error);
    throw error; // Handle the error or rethrow as needed
  }
};


    

      //SEND TRAINING TO LEARNER
//   const SendTraining = async (a,b, email, name, training)=>{

//     console.log(a + " " + b)

//     let resp = await axios.post(API + 'send-initial-training-single',{userId:data.id, trainingId:a, learnerId:b, name: name, email:email, training:training})

//     if(resp.data === 'error'){
//       window.alert('Something went wrong. Please try again.')
//     } else {
//       window.alert('Training has been sent!')
//       console.log(resp.data.data)
//       let newData = resp.data.data.activeTrainings;
//       let trainingIndex = newData.findIndex(i=>{return i.id === a});

//       setTrainingData(resp.data.data.activeTrainings[trainingIndex])
      



//     }
//   }


  //COPY LINK TO TRAINING 
  const CopyLinkToEmployeeSign = async (a,b, c ,d)=>{
    console.log(a + " " + b)
  
      await navigator.clipboard.writeText(`https://app.performanceevaluationcenter.com/#/cp-employee-sign?a=${data.id}&b=${coachingData.reviewId}`)


   
    alert(`Copied the link to the coaching plan for ${coachingData.name.name} to sign.`)
  }

  const CopyLinkToManagerSign = async (a,b, c ,d)=>{
    console.log(a + " " + b)
  
      await navigator.clipboard.writeText(`https://app.performanceevaluationcenter.com/#/cp-manager-sign?a=${data.id}&b=${coachingData.reviewId}`)


   
    alert(`Copied the link to the coaching plan for ${coachingData.name.name} for the manager, ${coachingData.manager.name} to sign.`)
  }

  const copySessionSignLink = async (c)=>{
    await navigator.clipboard.writeText(`https://app.performanceevaluationcenter.com/#/cp-session?a=${data.id}&b=${coachingData.reviewId}&c=${c}`)
    alert(`The link to the coaching session for ${coachingData.name.name} for the manager, ${coachingData.manager.name} to sign has been copied to your clipboard.`)

  }



  if(loading){
    <Box sx={{textAlign:'center', marginTop:20}}>
    <CircularProgress/>
    <Typography><b>Loading</b></Typography>
  </Box>
  } else {
    



    return(
        <Box  sx={{ backgroundColor:'#ECEEF1', height:'100vh', overflow:'auto', paddingBottom:'200px',   }}>
            <AppBar component="nav">
        <Toolbar
        sx={{backgroundImage:'linear-gradient(#1A9A71, #1A9A71) !important'}}
        >
        
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: {  sm: 'block', padding:10 } }}
          >
           <img className="logo" src={logo} width="200px" />
          </Typography>
          <Box sx={{ display: {  sm: 'block' } }}>
            {navItems.map((item) => (
              <Button onClick={()=>navigate('/', { replace: true, state:'CP' })} key={item} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
        <Box className='main-box' sx={{marginTop:'100px !important', maxWidth:'1080px', margin:'auto',padding:{ xs: '0px', sm: '20px' }}}>
        <Box className='main-box' >
        <Container className="main-container" style={{ textAlign:'center'}}>
            <Paper sx={{padding:'10px', border:'2px solid black'}}>
      <Row style={{textAlign:'left', marginLeft:'5%'}}>
        <Col  sm={6}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>Employee Name:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'20px'}}>{coachingData.name.name}</Form.Label>
            </Form.Group>
        </Col>
       
        <Col sm={6}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>Date Created:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{formatDate(coachingData.date)}</Form.Label>
            </Form.Group>
        </Col>
      </Row>

      {/* <Row  style={{textAlign:'left', 
    //   marginTop:width > 570 ? '20px':'0px',
       marginLeft:'5%'}}>
        <Col  sm={6}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>STATUS:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px', color:completedCheckpoints.length === coachingData.checkpoints.length ? 'green': 'orange'}}><b>{completedCheckpoints.length === coachingData.checkpoints.length ? 'COMPLETE': 'IN PROGRESS'}</b></Form.Label>
            </Form.Group>
        </Col>
    

        <Col sm={6}>
        <Form.Group >
                <Form.Label><b><Typography sx={{fontSize:'16px'}} variant="button"><b>PROGRESS:</b></Typography></b></Form.Label>
                <br></br>
                <Form.Label style={{fontSize:'16px'}}>{`${completedCheckpoints.length}/${coachingData.checkpoints.length}`}</Form.Label>
            </Form.Group>
        </Col>
      </Row> */}
      </Paper>
    </Container> 
            </Box>
        
            
            <Box className='main-box-table' sx={{margin:5, padding:{ xs: '0px'}}} >
            <div style={{marginBottom:'10px', display: 'block'}}>
            <ToolTipButton
              iconBtnSx={{backgroundColor:'#25AC71', marginLeft:1, color:'white'}}
              Icon={<EditIcon htmlColor="wihte"/>}
              onClick={handleShowCPEdit}
              Title="Edit Coaching Plan"
              />

              <ToolTipButtonMenu

              Title={'Copy link to signature'}
              Option1Title={'Copy Link for Employee'}
              Option2Title={'Copy Link for Manager'}
              Option2Style={{display:coachingData.sendToManager? 'block':'none'}}
              iconBtnSx={{backgroundColor:'#25AC71', display: showSendBtns ? 'inline-flex' : 'none' }}
              Icon={<ContentCopyIcon htmlColor="white"/>}
              Option1Click={()=>{CopyLinkToEmployeeSign()}}
              Option2Click={()=>{CopyLinkToManagerSign()}}
              />
             
             <ToolTipButtonMenu
             Option1Click={()=>{
              resendEmailToEmployee('userId', { id: data.id})
              .then((response) => {
                // Handle the success response
                console.log(response);
                window.alert('The email for a signature has been resent to the employee.')
              })
              .catch((error) => {
                // Handle the error
                window.alert('Something went wrong. Please try again.')
                console.error(error);
              });
             }}
             Option2Click={()=>{
              resendEmailToManager('userId', { id: data.id})
              .then((response) => {
                // Handle the success response
                console.log(response);
                window.alert('The email for a signature has been resent to the manager.')
              })
              .catch((error) => {
                // Handle the error
                window.alert('Something went wrong. Please try again.')
                console.error(error);
              });
             }}
              Option1Title={'Send to Employee'}
              Option2Title={'Send to Manager'}
              Option2Style={{display:coachingData.sendToManager? 'block':'none'}}
              iconBtnSx={{backgroundColor:'#25AC71', display: showSendBtns ? 'inline-flex' : 'none'}}
              Icon={<ForwardToInboxIcon htmlColor="white"/>}
              
              />
            
           <Tooltip style={{ float:'inline-end',}} title="Add New Session"> <IconButton sx={{
                        float:'inline-end',
                        height:35,
                        width:35, 
                        color:'white',
                        backgroundColor:'#25AC71',
                        marginBottom:2
                        }}  
                        variant="success" 
                      
                        onClick={handleShow}
                        >
                      <AddIcon/>
                    </IconButton>
                    </Tooltip> 
            </div>
         
            <TableContainer  component={Paper}>
      <Table sx={{ minWidth: 700 }} >
        <TableHead>
          <TableRow sx={{height:'60px'}}>
            <StyledTableCell align="center"><Typography variant="button" ><b>Date</b></Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="button" ><b>Session Notes (Internal Use Only)</b></Typography></StyledTableCell>
            <StyledTableCell align="center"><Typography variant="button" ><b>Actions</b></Typography></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {coachingData.checkpoints
  .sort((a, b) => new Date(a.dateOfNextReview) - new Date(b.dateOfNextReview))
  .map((row) => {
    let link = `http://app.performanceevaluationcenter.com/#/cp-session-view?a=${data.id}&b=${coachingData.reviewId}&c=${row.id}`;
    return (
      <StyledTableRow key={row.id}>
        <StyledTableCell align="center">{formatDate(row.dateOfNextReview)}</StyledTableCell>
        <StyledTableCell>
          <div dangerouslySetInnerHTML={{ __html: row.notes }} />
        </StyledTableCell>
        <StyledTableCell align="center">
          <ToolTipButton
            Title="Session Details"
            Icon={<CreateIcon htmlColdsor="#1A9A71" />}
            onClick={() => handleShowCS(row)}
          />
          
          <ToolTipButton
            Title="Delete this session"
            Icon={<DeleteIcon htmlColor="tomato" />}
            onClick={() => handleDelete(row.id)}
          />
          <a target="_blank" href={link}>
            <ToolTipButton
            Title={`View Document`}
            Icon={<PlagiarismIcon htmlColor='#199A71'/>}
            />
            </a>
        </StyledTableCell>
      </StyledTableRow>
    );
  })}

        </TableBody>
      </Table>
    </TableContainer>
         </Box>
         <Modal style={{zIndex:'10000', paddingTop:'10%'}} size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Coaching Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Typography variant="subtitle2">In this section you can add checkpoints for future coaching sessions. You will also be reminded of these checkpoints in the Evaluation Calendar and on the Dashboard under upcoming evaluations.</Typography>
        <hr></hr>

      <Row className="mb-3">
            <Form.Group  as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Session Date:</b></Typography></Form.Label>
        <Form.Control value={checkpointDate} 
        onChange={(e)=>setCheckpointDate(e.target.value)} type="date"  />
      </Form.Group>
      <Form.Group as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Session Type:</b></Typography></Form.Label>
        <Form.Control value={checkpointFollowUpType} 
        onChange={(e)=>setCheckpointFollowUpType(e.target.value)} type="text" placeholder="exp: Call, Meeting..." />
      </Form.Group>
      
      </Row>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label><Typography variant="subtitle2"><b>Additional Notes (Internal Use Only):</b></Typography></Form.Label>
        <Form.Control value={checkpointProgressExpected} onChange={(e)=>setCheckpointProgressExpected(e.target.value)} placeholder="Detail the progress that is expected with this goal..." as="textarea" rows={3} />
      </Form.Group>
      <div style={{textAlign:'end'}}>
      <Button 
      onClick={AddCheckpoint} 
      variant="contained">Add Checkpoint</Button>  
      </div>
       
     
        </Modal.Body>
       
      </Modal>

      <Modal style={{zIndex:'10000', paddingTop:'10%'}} size="lg" show={showCS} onHide={handleCloseCS}>
        <Modal.Header closeButton>
          <Modal.Title>Coaching Session Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>EMPLOYEE INFORMATION</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-4'>
         
          <Col>
            <Form.Group controlId="position">
              <Form.Label><Typography><b>Name:</b></Typography></Form.Label>
              <Form.Control
                type="text"
                name="position"
                disabled
                value={coachingData.name.name}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="date">
              <Form.Label><Typography><b>Actual Session Date:</b></Typography></Form.Label>
              <Form.Control
                name="dateOfNextReview"
                type='date'
                value={formData.dateOfNextReview}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
          <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>Concerns and Goals Addressed</b></Typography>
            </Accordion.Header>
            <Accordion.Body>

         <div style={{padding:10}}>
         <CKEditor
            config={{
                height: 300, // Set the desired height in pixels
            }}
            data={formData.sessionData.concernsAndGoals}
            onChange={(event, editor) => {
              const updatedConcernsAndGoals = editor.getData();
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  sessionData: {
                    ...prevData.sessionData,
                    concernsAndGoals: updatedConcernsAndGoals,
                  },
                };
                console.log(newData); // Log the updated state
                return newData;
              });
            }}
            editor={ClassicEditor}
            />

        </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
          <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>Strengths and Improvments</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
            <Form.Label><Typography variant="subtitle2"><b>Strenghths to Leverage:</b></Typography></Form.Label>


         <div style={{padding:10}}>
         <CKEditor
            config={{
                height: 300, // Set the desired height in pixels
            }}
            data={formData.sessionData.strengths}
            onChange={(event, editor) => {
              const updatedStrengths = editor.getData();
              setFormData((prevData) => {
                const newData = {
                  ...prevData,
                  sessionData: {
                    ...prevData.sessionData,
                    strengths: updatedStrengths,
                  },
                };
                console.log(newData); // Log the updated state
                return newData;
              });
            }}
            editor={ClassicEditor}
            />

        </div>

        <Form.Label><Typography variant="subtitle2"><b>Areas for Improvement:</b></Typography></Form.Label>

 <div style={{padding:10}}>
 <CKEditor
  config={{
        height: 300,
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'link',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      }}
  data={formData.sessionData.areasImprovement}
  onChange={(event, editor) => {
    const updatedAreasImprovement = editor.getData();
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        sessionData: {
          ...prevData.sessionData,
          areasImprovement: updatedAreasImprovement,
        },
      };
      console.log(newData); // Log the updated state
      return newData;
    });
  }}
  editor={ClassicEditor}
/>

</div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
          <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>Key Objectives</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
            <Form.Label><Typography variant="subtitle2"><b>Key Objectives in Today's Session:</b></Typography></Form.Label>

         <div style={{padding:10}}>
         <CKEditor
  config={{
        height: 300,
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'link',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      }}
  data={formData.sessionData.keyObjectives}
  onChange={(event, editor) => {
    const updatedKeyObjectives = editor.getData();
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        sessionData: {
          ...prevData.sessionData,
          keyObjectives: updatedKeyObjectives,
        },
      };
      console.log(newData); // Log the updated state
      return newData;
    });
  }}
  editor={ClassicEditor}
/>


        </div>

       
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
          <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>Action Items and Follow-Up</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
            

            <Row>
              <Typography className='mb-2' variant='button' sx={{fontSize:18}}><IconButton 
                    sx={{
                        float:'inline-end',
                        height:30,
                        width:30, 
                        color:'white',
                        backgroundColor:'#25AC71',
                        paddingLeft:1
                        }} 
                    variant="success" 
                    size="sm" 
                    onClick={handleShowActionItem }
                    ><AddIcon/></IconButton></Typography>
              <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Action Item</th>
                  <th>Who is Responsible</th>
                  <th>Due Date</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {formData.sessionData.actionItems.map((step, index) => (
                  <tr key={index}>
                    <td>{step.actionItem}</td>
                    <td>{step.responsible}</td>
                    <td>{step.responsible}</td>
                    <td><div dangerouslySetInnerHTML={{__html:step?.notes}}/></td>
                    <td>
                      <IconButton color="primary" 
                      onClick={() => handleEditActionItem(index)}
                      >
                       <CreateIcon/>
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteActionItem(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
         
          </Col>
              </Row>
       
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
            <Typography variant="button" sx={{ fontSize: 16 }}><b>Finalize</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group>
                <Form.Label>
                  <b>MANAGER SIGNATURE</b>
                  
                </Form.Label>
               <Form.Check
               label={'Would you like the manager to sign off on this session?'}
               checked={formData.sessionData.managerSign}
               onChange={(e) => {
                const isChecked = e.target.checked;
              console.log(isChecked)
                setFormData((prevData) => ({
                  ...prevData,
                  sessionData: {
                    ...prevData.sessionData,
                    managerSign: isChecked,
                  },
                }))
              console.log(formData)
              }}
               />

                  <Form.Label className="mt-2">
                  <b>FOlLOW-UP DATE:</b>
                  
                </Form.Label>
                <Form.Control
                name="followUpDate"
                type='date'
                value={formData.sessionData.followUpDate}
                onChange={(e)=>{
                  let val = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    sessionData: {
                      ...prevData.sessionData,
                      followUpDate: val,
                    },
                  }))
                }}
              />
               
             <div  style={{display:formData.sessionData.managerSign ? 'flex' : 'none'}}>
              <div style={{display:'flex'}}>
                <Form.Label className="mt-2"><b>Sending Options:</b></Form.Label>
              <BsToolTip
              Icon={<ForwardToInboxIcon/>}
              Title={`Click here to send an email to the manager with a link to sign the session document. Managers Email:${coachingData.manager.email}`}
              onClick={()=>{
                sendSessionToManager(data.id, formData.id)
                .then((response) => {
                  // Handle the success response
                  console.log(response);
                  window.alert('The email for the manager to review and sign the session has been sent.')
                })
                .catch((error) => {
                  // Handle the error
                  window.alert('Something went wrong. Please try again.')
                  console.error(error);
                });
              }}
              />
              </div>

              <div style={{display:'flex'}}>

              <BsToolTip
              Icon={<CopyAll/>}
              Title={`Click here to copy a link to the session document that you can personally send to the manager to sign.`}
              onClick={()=>{copySessionSignLink(formData.id)}}
              />
          
              </div>
              </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
        <Button 
        onClick={updateCheckpoint}
        className="mt-2" sx={{float:'inline-end'}} variant="contained">Update Session</Button>
        </Modal.Body>
       
      </Modal>

      <Modal size='lg' style={{zIndex:'10000', paddingTop:'10%'}} show={showActionItemModal} onHide={handleCloseActionItem}
 
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* {currentStepIndex !== null ? 'Edit' : 'Add'}  */}
            Action Items and Follow-Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="actionItem">
            <Form.Label>Action Item:</Form.Label>
            <Form.Control
              type="text"
              value={actionItem}
              onChange={(e) => setActionItem(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="responsible">
            <Form.Label>Who is Responsible:</Form.Label>
            <Form.Control
              type="text"
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="dueDate">
            <Form.Label>Due Date:</Form.Label>
            <Form.Control
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="notes">
            <Form.Label>Notes:</Form.Label>
            <CKEditor
             config={{
                height: 300, // Set the desired height in pixels
              }}
              editor={ClassicEditor}
              data={notes}
              onChange={(event, editor) => setNotes(editor.getData())}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" sx={{backgroundColor:'gray', marginRight:2}} 
          onClick={handleCloseActionItem}
          >
            Close
          </Button>
          <Button variant="contained" 
          onClick={handleAddActionItem}
          >
            {'Add Action Item'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size='lg' style={{zIndex:'10000', paddingTop:'10%'}} show={cpEditModalShow} onHide={handleCloseCPEdit}>
        <Modal.Header closeButton>
        <Modal.Title>
         Edit Coaching Plan
         </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <CPEdit
         Submit={()=>{
          handleCloseCPEdit();
          setUpdate(!update)
        }}
         Data={coachingData}
         />
        </Modal.Body>
      </Modal>
         </Box>
         <Box sx={{height:'100px'}}>
           <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    
    <Copyright sx={{ pt: 4 }} />
  </Container>
           </Box>
        </Box>
    )
}
}