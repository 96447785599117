import { Button, Autocomplete, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useState, useMemo } from "react";
import { InputGroup, Table } from "react-bootstrap";
import ToolTipButton from "../components/TooltipButton";
import CancelIcon from '@mui/icons-material/Cancel';

export const ReviewerList = ({ employees, setReviewers, reviewers }) => {
  // Local state to keep track of the selected reviewer
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  // Memoized value to compute filtered employees list
  const availableEmployees = useMemo(() => {
    return employees.filter(emp => !reviewers.some(reviewer => reviewer.id === emp.id));
  }, [employees, reviewers]);

  // Group employees by role
  const groupedOptions = [
    { label: 'Managers', options: availableEmployees.filter(emp => emp.role === 'Manager') },
    { label: 'Employees', options: availableEmployees.filter(emp => emp.role === 'Employee') }
  ];

  // Function to handle adding a reviewer to the list
  const handleAddReviewer = () => {
    if(reviewers.length >= 15){
     window.alert('You can only add reviewers 15 per review. If you need to add more you can start a new review and add the rest.')
    } else {
      if (selectedReviewer) {
        // Add selected reviewer to the reviewers list
        setReviewers((prevData) => [...prevData, selectedReviewer]);
  
        // Clear the selected reviewer from the Autocomplete and state
        setSelectedReviewer(null);
      }
    }
   
  };

  // Function to remove a reviewer from the list
  const handleRemoveReviewer = (reviewerId) => {
    // Remove the reviewer from the reviewers list
    setReviewers((prevData) => prevData.filter((r) => r.id !== reviewerId));
  };

  return (
    <>
      <InputGroup>
        <Autocomplete
        disableClearable
          disablePortal // Render within the DOM hierarchy
          sx={{ zIndex: 2100, width: '80%' }} // Set a high zIndex and width
          value={selectedReviewer} // Controlled component value
          onChange={(event, newValue) => {
            setSelectedReviewer(newValue);
          }}
          options={groupedOptions.flatMap(group => group.options)} // Flattened options array for Autocomplete
          groupBy={(option) => option.role} // Group by role (Manager/Employee)
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Reviewers"
              variant="outlined"
              sx={{ zIndex: 2100 }} // Ensure zIndex is high enough for the TextField
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Typography variant="subtitle1">{option.name}</Typography>
              <Typography variant="caption" sx={{ ml: 1 }}>
                ({option.role})
              </Typography>
            </li>
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <Typography variant="overline" sx={{ pl: 2, pt: 1 }}>{params.group}</Typography>
              <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
          )}
        />
        <Button variant="contained" onClick={handleAddReviewer} sx={{ ml: 2 }}>
          Add Reviewer
        </Button>
      </InputGroup>
      <TableContainer sx={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="reviewer table">
          <TableHead>
            <TableRow>
              <TableCell align="left"><b>First Name</b></TableCell>
              <TableCell align="left"><b>Last Name</b></TableCell>
              <TableCell align="left"><b>Role</b></TableCell>
              <TableCell align="left"><b>Email</b></TableCell>
              <TableCell align="center"><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviewers.map((reviewer) => (
              <TableRow key={reviewer.id}>
                <TableCell align="left">{reviewer.firstName}</TableCell>
                <TableCell align="left">{reviewer.lastName}</TableCell>
                <TableCell align="left">{reviewer.role}</TableCell>
                <TableCell align="left">{reviewer.email}</TableCell>
                <TableCell align="center">
                  <ToolTipButton
                    Title="Delete Reviewer"
                    Icon={<CancelIcon htmlColor="tomato" />}
                    onClick={() => handleRemoveReviewer(reviewer.id)} // Remove reviewer
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
