import { AppBar, Divider, Paper, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useEffect} from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {Button} from "@mui/material";
import { EPEReview } from "../components/review-screens/epe";
import { ThreeSixtyDemo } from "../components/demo-screens/threesixty-demo";
import { UEDemo } from "../components/demo-screens/ue-demo";
import { EPEDemo } from "../components/demo-screens/EPEDemo";
import { API } from "../Controllers/API";
import axios from "axios";
import { UpdateInput } from "../classes/review-functions";
import { useLocation } from "react-router-dom";
import '../assets/css/review-screen.css'
import { LockOpen } from "@mui/icons-material";
import { ThreeSixtyReview } from "../components/review-screens/360";
import { UEReview } from "../components/review-screens/ue";
import logo from '../assets/css/images/PECLogo.png'
import hdLogo from '../assets/css/pec-badge.png'
import { Link } from "react-router-dom";
import { PIPReview } from "../components/review-screens/pip";

function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const Review = ()=>{
    const location = useLocation();
    function getQueryVariable(variable) {
        var query = location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }
   
    const a  = getQueryVariable('a')
    const b  = getQueryVariable('b')
    const c  = getQueryVariable('c')
    const d  = getQueryVariable('d')
    const queryParameters = new URLSearchParams(window.location.search)
    let dataObject = {reviewData:{
        goalDisplay:false
    }}
const[reviewObj, setReviewObj] = useState({reviewData:{
    name:'',
    jobTitle:'',
    employeeId:'',
    department:'',
     timeInPosition:'',
    reviewPeriod:'',
   
    


}, worksheetData:[], goalPlanningSection:{status:null, show:'null'}})
const[showEmployeeId, setEmployeeId] = useState('block')
const[goalDisplay, setGoalDisplay] = useState(false)
const [loader,setLoader] = useState(false)
const [loading,setLoading] = useState(true)
const [ questions, setQuestions] = useState([])
const [completed, setCompleted] = useState(false)
const [partTwo, setPartTwo] = useState('')
const [partFourA, setPartFourA] = useState('')
const [partFourB, setPartFourB] = useState('')
const [partThree, setPartThree] = useState('')
const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
let finalReviewData = {
    reviewerId: d,
    reviewType: reviewObj.reviewType,
    jobTitle:reviewObj.reviewData.jobTitle,
    employeeId:reviewObj.reviewData.employeeId,
    department:reviewObj.reviewData.department,
     timeInPosition:reviewObj.reviewData.timeInPosition,
     partTwo: partTwo,
     partThree: partThree,
     partFourA: partFourA,
     partFourB: partFourB,
     signature: signature,
     signatureDate: signatureDate,
    reviewPeriod:reviewObj.reviewData.reviewPeriod,
    reviewee: reviewObj.reviewee,
    worksheetData: questions
}

const EditQuestionArray = (event,a,b)=>{
    let val = event.target.value;

    console.log(val)
console.log(a)
console.log(b)
console.log(questions)
const index = questions.findIndex(object => {
    return object.catIndex === b;
  }); 

  console.log(index)

  const questionIndex = questions[index].questions.findIndex(object => {
    return object.id === a;
  }); 

  questions[index].questions[questionIndex].answer = val

  console.log(questions)
}
const RetrieveData = async ()=>{
    let resp =  await axios.post(API+'get-review', {userID:a, employeeID:b, reviewID:c, reviewerID:d}, (err, response)=>{return response });
    console.log(resp.data)
    setReviewObj(resp.data)
    setQuestions(resp.data.reviewData.worksheetData)
    if(resp.data.reviewData.hasOwnProperty('goalPlanningSection')){
        setGoalDisplay(resp.data.reviewData.goalPlanningSection)
    }

    let reviewer = resp.data.reviewers.filter(i=>{return (i.id === d)})
  console.log(reviewer)
    if(reviewer[0].status === 'completed'){
        setCompleted(true)
    }
    console.log(reviewObj)
   
   setLoading(false)
}
const[topSection, setTopSection] = useState('')
    useEffect(()=>{
   
      setLoading(true)
console.log(a + " "+b + " "+c+ " " + d)

RetrieveData()


    }, [])
   

    async function downloadDocx(params) {
        // const converted = htmlDocx.asBlob(htmlString);
        // saveAs(converted, 'test.docx');
      }
      if(!loading){
      if(!completed){
        
            if(reviewObj.reviewType === "Employee Performance Evaluation"){
                
        
    return (
         <EPEReview
         RevieweeName={reviewObj.reviewee.name}
         JobTitle={reviewObj.reviewData.jobTitle}
         ReviewPeriod={reviewObj.reviewData.reviewPeriod}
         EmployeeId={reviewObj.reviewData.employeeId}
         Department={reviewObj.reviewData.department}
         TimeInPosition={reviewObj.reviewData.timeInPosition}
         GoalDisplay={goalDisplay}
         PartTwoTextArea={(e)=>{setPartTwo(e.target.value)}}
         PartFourATextArea={(e)=>{setPartFourA(e.target.value)}}
         PartFourBTextArea={(e)=>{setPartFourB(e.target.value)}}
         DisplayLoader={loader}
         OnSubmit={async()  =>{
           setLoader(true)
            console.log(finalReviewData)
            let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
            console.log(resp.data)
            setCompleted(true)
         }}
         ChangePartThree={(e)=>{setPartThree(e.target.value)}}
         Signature={(e)=>{setSignature(e.target.value)}}
         SignatureDate={(e)=>{setSignatureDate(e.target.value)}}
         WorksheetData={reviewObj.reviewData.worksheetData.map(i=>{
            const catId = i.catIndex
            return (<div><p><b>{i.cat}</b></p>
            {i.questions.map((i)=>{
                return(
               <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                     
                <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                <select className="answer" onChange={(event)=>EditQuestionArray(event,i.id, catId)} style={{flex:'30%', width:'20px', height:'30px', marginRight:'10px'}}>
                <option>---</option>
                <option value="Exceptional Performance">Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                <option value="Exceeds Expectations">Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                <option value="Fully Meets Expectations">Fully Meets Expectations (commendable performance that meets goals)</option>
                <option value="Partially Meets Expectations">Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                <option value="Does Not Meet Requirements">Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                <option value="NA">NA</option>

                </select>
               {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                </div>
                <Divider style={{backgroundColor:"#D0D0D0"}}/>
                </Box>
                )
            })}


            </div>
              
            )
        })}
         />
        )

    }


    if(reviewObj.reviewType === "Employee Self Evaluation"){
            
        
        return (
             <EPEReview
         RevieweeName={reviewObj.reviewee.name}
         JobTitle={reviewObj.reviewData.jobTitle}
         ReviewPeriod={reviewObj.reviewData.reviewPeriod}
         EmployeeId={reviewObj.reviewData.employeeId}
         Department={reviewObj.reviewData.department}
         TimeInPosition={reviewObj.reviewData.timeInPosition}
         GoalDisplay={goalDisplay}
         PartTwoTextArea={(e)=>{setPartTwo(e.target.value)}}
         PartFourATextArea={(e)=>{setPartFourA(e.target.value)}}
         PartFourBTextArea={(e)=>{setPartFourB(e.target.value)}}
         DisplayLoader={loader}
         OnSubmit={async()  =>{
            setLoader(true)
            console.log(finalReviewData)
            let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
            console.log(resp.data)
            setCompleted(true)
         }}
         ChangePartThree={(e)=>{setPartThree(e.target.value)}}
         Signature={(e)=>{setSignature(e.target.value)}}
         SignatureDate={(e)=>{setSignatureDate(e.target.value)}}
         WorksheetData={reviewObj.reviewData.worksheetData.map(i=>{
            const catId = i.catIndex
            return (<div><p><b>{i.cat}</b></p>
            {i.questions.map((i)=>{
                return(
               <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                     
                <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                <select className="answer" onChange={(event)=>EditQuestionArray(event,i.id, catId)} style={{flex:'30%', width:'20px', height:'30px', marginRight:'10px'}}>
                <option>---</option>
                <option value="Exceptional Performance">Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                <option value="Exceeds Expectations">Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                <option value="Fully Meets Expectations">Fully Meets Expectations (commendable performance that meets goals)</option>
                <option value="Partially Meets Expectations">Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                <option value="Does Not Meet Requirements">Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                <option value="NA">NA</option>
                </select>
               {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                </div>
                <Divider style={{backgroundColor:"#D0D0D0"}}/>
                </Box>
                )
            })}


            </div>
              
            )
        })}
         />
            )
    
        }

    if(reviewObj.reviewType === "360 Evaluation"){
            
        
        return (
             <ThreeSixtyReview
             RevieweeName={reviewObj.reviewee.name}
             ReviewPeriod={reviewObj.reviewData.reviewPeriod}
             Department={reviewObj.reviewData.department}
             PartTwoTextArea={(e)=>{setPartTwo(e.target.value)}}
             DisplayLoader={loader}
             OnSubmit={async()  =>{
                setLoader(true)
                console.log(finalReviewData)
                let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
                console.log(resp.data)
                setCompleted(true)
             }}
             WorksheetData={reviewObj.reviewData.worksheetData.map(i=>{
                const catId = i.catIndex
                return (<div><p><b>{i.cat}</b></p>
                {i.questions.map((i)=>{
                    return(
                   <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                    <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                         
                    <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                    <select className="answer" onChange={(event)=>EditQuestionArray(event,i.id, catId)} style={{flex:'30%', width:'20px', height:'30px', marginRight:'10px'}}>
                    <option>---</option>
                    <option value="Exceptional Performance">Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                    <option value="Exceeds Expectations">Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                    <option value="Fully Meets Expectations">Fully Meets Expectations (commendable performance that meets goals)</option>
                    <option value="Partially Meets Expectations">Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                    <option value="Does Not Meet Requirements">Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                    <option value="NA">NA</option>
                    </select>
                   {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                    </div>
                    <Divider style={{backgroundColor:"#D0D0D0"}}/>
                    </Box>
                    )
                })}
    
    
                </div>
                  
                )
            })}
             />
            )
    
        }

        if(reviewObj.reviewType === "Upward Evaluation"){
            
        
            return (
                 <UEReview
                 RevieweeName={reviewObj.reviewee.name}
                 ReviewPeriod={reviewObj.reviewData.reviewPeriod}
                 Department={reviewObj.reviewData.department}
                 PartTwoTextArea={(e)=>{setPartTwo(e.target.value)}}
                 CompletedScreen={()=>setCompleted(true)}
                 DisplayLoader={loader}
                 OnSubmit={async()  =>{
                    setLoader(true)
                    console.log(finalReviewData)
                    let resp =  await axios.post(API+'add-completed-review', {userID:a, employeeID:b, reviewID:c,reviewerId:d, reviewData: finalReviewData}, (err, response)=>{return response });
                    console.log(resp.data)
                    setCompleted(true)
                 }}
                 WorksheetData={reviewObj.reviewData.worksheetData.map(i=>{
                    const catId = i.catIndex
                    return (<div><p><b>{i.cat}</b></p>
                    {i.questions.map((i)=>{
                        return(
                       <Box sx={{marginTop:'10px',marginBottom:'10px'}}>
                        <div className="question-box" style={{display:'flex', flexDirection:'row' }}>
                             
                        <p className="question" style={{flex:'70%', minWidth:'70%'}}>{i.question}</p>
                        <select className="answer" onChange={(event)=>EditQuestionArray(event,i.id, catId)} style={{flex:'30%', width:'20px', height:'30px', marginRight:'10px'}}>
                        <option>---</option>
                        <option value="Exceptional Performance">Exceptional Performance (outstanding achievements that far exceed goals in all performance elements)</option>
                        <option value="Exceeds Expectations">Exceeds Expectations (performance that consistently goes beyond meeting goals)</option>
                        <option value="Fully Meets Expectations">Fully Meets Expectations (commendable performance that meets goals)</option>
                        <option value="Partially Meets Expectations">Partially Meets Expectations (inconsistent aspects of performance requiring improvement to meet some goals)</option>
                        <option value="Does Not Meet Requirements">Does Not Meet Requirements (goals unmet, skills not demonstrated, improvement needed)</option>
                        <option value="NA">NA</option>
                        </select>
                       {/* <p style={{flex:'20%', width:'20px', height:'30px'}}><b>Exceptional Performance</b></p> */}
                        </div>
                        <Divider style={{backgroundColor:"#D0D0D0"}}/>
                        </Box>
                        )
                    })}
        
        
                    </div>
                      
                    )
                })}
        
        
       
                      
                    
               
                 />
                )
        
            }


            if(reviewObj.reviewType === "Performance Improvement Plan"){
                <PIPReview
                RevieweeName={reviewObj.reviewee.name}
                Department={reviewObj.department}
                Supervisor={reviewObj.supervisor}
                Date={reviewObj.dateCreated}
                AreasOfConcern={reviewObj.areasOfConcern}
                Observations={reviewObj.observations}
                Resources={reviewObj.resources}
                Expectations={reviewObj.expectations}
                GoalList={reviewObj.goalsArray}
                
                />
            }

      }  else{
        return(
            <div style={{backgroundColor:'#D5EDE3', height:'100vh'}}>
            <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center'}}>
           <AppBar sx={{marginBottom:'50px'}}>
           <Toolbar
                sx={{
                  backgroundColor:'#1A9A71',
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
    
    <img height='auto' width={250} src={logo}/>
              </Toolbar>
           </AppBar>
           <div className="above-btn">
           
        <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px', justifyContent:'center'}}>
            <Typography className="complete-heading" sx={{fontSize:'2vw'}} variant="button">Thank you for completing the evalution! </Typography><br></br><p className="complete-p"  style={{fontSize:'20px',paddingRight:'40px',paddingLeft:'40px'}}>The completed evaluation has been sent back to the person that initiated this process. If you have any questions or would like a copy of the completed review you can reach out to them for assistance.</p><p>You may now close this window.</p>
            </Paper>
            </div>
            </Box>
            <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
            </div>)
    }
      }
   
}