import React, {useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box, Button, Typography } from "@mui/material";
import { Calendar } from "react-calendar";
import { useEffect } from "react";
import '../assets/css/calendar.css'
import axios from "axios";
import { API } from "../Controllers/API";
import { formatDate } from "../classes/global-functions";
import { AddToCalendar } from "../components/AddToCalendarBtn";
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import uuid from "react-uuid";
import ToolTipButton from "../components/TooltipButton";
export const EvaluationCalendar = (props)=>{
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const [value, onChange] = useState(new Date());
    const [update, setUpdate] = useState(true)
    const [currentMonth, setCurrentMonth] = useState(value.getMonth());
    const [mainData, setMainData] = useState([])
    const [show, setShow] = useState(false);
    const [reviewee, setReviewee] = useState('')
    const [managers, setManagers] = useState([{
      dateOfLastReview: "NA",
    email: "null",
    id: "null",
    name: "null",
    reviews: [''],
    role: "Manager"
    }])
    const [employees, setEmployees] = useState([{
      dateOfLastReview: "NA",
    email: "null",
    id: "null",
    name: "null",
    reviews: [''],
    role: "Employee"
    }])


    //FORM DATA
    const [dateOfReview, setDateOfReview] = useState('')
    const [notes, setNotes] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   
    const handleRevieweeSelect = (e) =>{
      setReviewee(e)
    
    
      console.log(reviewee)
      
      
    }
    const DeleteCalendarItem = (a)=>{
      
      axios.post(API+'delete-cal-item', {id:data.id, calendarId:a}, (err,response)=>{
        return response
      }).then(()=>{
        setUpdate(!update)
      })
    }

    const AddItemToCalendar = (a)=>{
      let obj = {
        id:uuid(), 
        name: reviewee, 
        dateOfNextReview:dateOfReview, 
        notes:notes
      } 
      if(obj.name === '' || obj.date === ''){
        alert('Please add at least the name of the employee and the date you would like to schedule the review on.')
      } else{
        
      axios.post(API+'add-cal-item', {id:data.id, calendarObj:obj}, (err,response)=>{
        return response
      }).then(()=>{
        handleClose()
        setUpdate(!update)
      })

    }
    }

    useEffect(()=>{
      axios.post(API,{email:data.email}, (err,response)=>{
        return response
      }).then((response)=>{
        setManagers(response.data[0].employees.filter(i=>{return (i.role === "Manager")}))
          setEmployees(response.data[0].employees.filter(i=>{return (i.role === "Employee")}))
          
         setMainData(response.data[0].scheduledEvaluations)
         console.log(mainData)
      })

    },[])


    useEffect(()=>{
      axios.post(API,{email:data.email}, (err,response)=>{
        return response
      }).then((response)=>{
         setMainData(response.data[0].scheduledEvaluations)
         console.log(mainData)
      })

    },[update])
    const changeDate = ()=>{
        // setValue(new Date())
    }

   
    return(
    <Box>
        <Typography><b>EVALUATION CALENDAR</b></Typography>
        <br></br>
        <Box sx={{ marginBottom:'10px', display:'flex', justifyContent:'center'}} >
         
        <Calendar maxDetail="year" onChange={onChange} onClickDay={()=>console.log(value.getMonth())} value={value} />
      
        </Box>
      <Box sx={{textAlign:'end'}}>
         <Button onClick={handleShow} style={{backgroundColor:'#52C47B', fontWeight:'bold', color:'white'}}>Add Calendar Item +</Button>
        
      </Box>
      <br></br>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Employee Name</TableCell>
            <TableCell align="center">Evaluation Scheduled Date</TableCell>
            <TableCell align="center">Notes</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
            mainData.filter((i)=>{
                let dateOfNextRev = new Date(i.dateOfNextReview)

                // console.log(dateOfNextRev.getMonth())
                // console.log(value.getMonth())
                return ( dateOfNextRev.getMonth() === value.getMonth())
            }).filter((i)=>{
              let dateOfNextRev = new Date(i.dateOfNextReview)

              // console.log(dateOfNextRev.getMonth())
              // console.log(value.getMonth())
              return ( dateOfNextRev.getFullYear() === value.getFullYear())
          }).map((i, index)=>{
                 let date = new Date(i.dateOfNextReview)
                 let todaysDate = new Date()
                 console.log(date.getMonth())
                return(
                    <TableRow>
                    <TableCell align="center">{i.name.name}</TableCell>
                    <TableCell align="center">{formatDate(i.dateOfNextReview)}</TableCell>
                    <TableCell align="left">{i.notes}</TableCell>
                    <TableCell align="center"><Button style={{display: (date.getMonth() === todaysDate.getMonth())?'inline':'none'}} onClick={props.SetupReview}>Setup Review</Button><ToolTipButton Title="Delete" onClick={()=>DeleteCalendarItem(i.id)} Icon={<DeleteIcon htmlColor="tomato"/>}/><AddToCalendar Name={i.name.name} Date={i.dateOfNextReview}/></TableCell>
                  </TableRow>
                )
     
            })
        }
        </TableBody>
      </Table>
    </TableContainer>
    <Modal show={show} style={{zIndex:10000}} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Typography sx={{fontSize:'18px'}} variant="button"><b>Add Evaluation to Schedule</b></Typography></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="subtitle2">Here you can add the date to conduct a follow up evaluation for an employee. You can access the Upcoming Evaluations on the Dashboard or here in the Evaluation Calendar. This action WILL NOT create the evaluation only the calendar item.</Typography>
          <hr></hr>
          <Row className="mb-3">
          <Form.Group as={Col} >
          <Typography variant="button">Select the employee you would like to review:</Typography>
          <br></br>
          <Form.Select  onChange={(e)=>{
          let val = JSON.parse(e.target.value)
      
          handleRevieweeSelect(val)
  
        
        }}>
          <option value="null">Select a Person to be reviewed...</option>
          <optgroup label="Managers">
         {managers.map(i=>{
            let val = {
              id:i.id,
              name:i.name, 
              email:i.email,
              status:'pending'
            }
          return <option  value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>


         <optgroup label="Employees">
         {employees.map(i=>{
          let val = {
            id:i.id,
            name:i.name, 
            email:i.email,
            status:'pending'
          }
          return <option value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>
        </Form.Select>
        </Form.Group>


          </Row>

          <Row className="mb-3">
        <Form.Group as={Col} >
          <Typography variant="button">Date you would like to evaluate this person</Typography>
          <br></br>
          <Form.Control onChange={(e)=>setDateOfReview(e.target.value)} type="date"/>
        </Form.Group>

      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} >
          <Typography variant="button">Notes:</Typography>
          <br></br>
          <Form.Control onChange={(e)=>setNotes(e.target.value)}  placeholder="Type of evaluation or any other notes you need to make pertaining to this evaluation..." as="textarea" />
        </Form.Group>

      </Row>
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="contained" onClick={()=>AddItemToCalendar()}>
            Add to Calendar
          </Button>
        </Modal.Footer>
      </Modal>
    
    </Box>)
}