import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import '../assets/css/selectionCard.css'


export default function SelectionCard(props) {
  return (
    <div onClick={props.OnClick}  >
      <Card className='section-card-box' variant="outlined">
      <React.Fragment>
    <Box >
    <CardContent >
      <Box >
        <img src={props.Image} style={{maxWidth:'150px'}} width='70%' height='auto'/>
        <Typography sx={{fontSize:'16px'}} gutterBottom variant="button" component="div">
        {props.HeaderText}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.DetailText}
        </Typography>
      </Box>
    </CardContent>
    <CardActions>
      
    </CardActions>
    </Box>
  </React.Fragment>

      </Card>
    </div>
  );
}