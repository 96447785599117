import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import logo from '../assets/css/images/PECLogo-clr.png';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { API } from '../Controllers/API';
import Cookies from 'js-cookie';
import Dialog from '@mui/material/Dialog';
import { Spinner } from 'react-bootstrap';
import { Grid, Box} from '@mui/material';
async function loginUser(credentials) {
  console.log(credentials);
  return fetch(`${API}login-1?${credentials}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((data) => data.json());
}

function Login({ setToken }) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [username, setUser] = useState('');
  const [password, setPass] = useState('');
  const [activity, setActivity] = useState('none');
  const [open, setOpen] = useState(false);
  

  const wakeUp = async ()=>{
   let resp = await axios.get(API+'wake-up');


   console.log(resp)
  }

  useState(()=>{
   wakeUp() 
  },[])
  // useEffect(() => {
  //   let userCookie = Cookies.get('user');
  //   console.log(userCookie)
  //   if (!userCookie || userCookie === 'null') {
  //     console.log('No user cookie found');
  //   } else {
  //     setOpen(true);
  //     console.log('Start login');
  //     loginUsingCookie(userCookie);
  //   }
  // }, []);


  const loginUsingCookie = async (a) => {
    let email = a.toLowerCase();
    const token = await axios.get(API + `login-with-cookie?email=${email}&subaccount=false`);
    console.log(token.data.token);
    if (token.data.token == 'test123') {
      setToken(token.data);
      setActivity('none');
      setIsLoggingIn(false);
    }
    if (token.token == 'inactive') {
      alert('There is no active membership with this account.');
      setActivity('none');
      setIsLoggingIn(false);
    }
    if (token.token == false) {
      alert('Invalid Email or Password. Please check your credentials and try again.');
      setActivity('none');
      setIsLoggingIn(false);
    }
  };

  const loginAgain = async (a, b) => {
    const token = await loginUser(`user=${a}&pass=${b}`);
    console.log(token);
    if (token.token == 'test123') {
      setToken(token);
      setActivity('none');
      setIsLoggingIn(false);
    }
    if (token.token == 'inactive') {
      alert('There is no active membership with this account.');
      setActivity('none');
      setIsLoggingIn(false);
    }
    if (token.token == false) {
      alert('Invalid Email or Password. Please check your credentials and try again.');
      setActivity('none');
      setIsLoggingIn(false);
    }
  };

  const handleSubmit = async (e) => {
    setActivity('flex');
    setIsLoggingIn(true);
    e.preventDefault();
    const token = await loginUser(`user=${username}&pass=${password}`);
    console.log(token);

    if (token.action === 'registered') {
      loginAgain(username, password);
    } else {
      if (token.token == 'test123') {
        setToken(token);
        setActivity('none');
        setIsLoggingIn(false);
      }
      if (token.token == 'inactive') {
        alert('There is no active membership with this account.');
        setActivity('none');
        setIsLoggingIn(false);
      }
      if (token.token == false) {
        alert('Invalid Email or Password. Please check your credentials and try again.');
        setActivity('none');
        setIsLoggingIn(false);
      }
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#199A71',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
       <Box
        sx={{
          background: 'linear-gradient(#1A9A71, #28B271) !important', // Setting the background color here
          minHeight: '100vh', // Ensuring the box takes the full height of the viewport
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
           
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid #ccc',
              boxShadow: '2px 2px 5px #999',
              padding: '20px',
              backgroundColor: '#f9f9f9',
              borderRadius: '5px',
              marginBottom: '20px',
              margin:10
            }}
          >
            <img style={{ maxWidth: '400px' }} src={logo} alt="logo" />
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => {
                    setUser(e.target.value);
                    console.log(username);
                  }}
                />
                <Typography variant="subtitle2">Use the email address associated with your account.</Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => {
                    setPass(e.target.value);
                    console.log(password);
                  }}
                  autoComplete="current-password"
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                <b>  Log In</b>
                  <span>&nbsp;</span>
                  <Spinner style={{ display: isLoggingIn ? 'block' : 'none' }} size="sm" animation="border" variant="light" />
                </Button>
                <Grid container>
                  <Grid sx={{textAlign:'end'}} item xs>
                    <a style={{textDecoration:'none', fontWeight:'bold', color: '#199A71'}} target="_blank" href="/#/password-reset">
                      Forgot password?
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <Typography sx={{fontWeight:'bold'}} variant="body2" color="white" align="center">
            {'Copyright © '}
            Andere HR Inc. {new Date().getFullYear()}
            {'.'}
          </Typography>
          <Dialog open={open}>
            <Box sx={{ padding: '50px', justifyContent: 'center' }}>
              <Typography sx={{ color: '#199A71', fontSize: '20px' }} variant="button">
                <b>Loading User Data</b>
              </Typography>
              <br></br>
              <Box style={{ display: 'block', textAlign: 'center', marginTop: '20px' }}>
                <p>
                  <Spinner variant="success" sx={{ color: '#199A71' }} size="lg" />
                </p>
              </Box>
            </Box>
          </Dialog>
        </Box>
      </Container>
      </Box>
    </ThemeProvider>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
