import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, CircularProgress, Toolbar, Grid} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/pec-badge.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../classes/global-functions";
import pipInstructions from '../../json/pip-instructions.json'
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Controllers/API";
import { useState } from "react";
function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const SessionSign = (props)=>{
  const [spinner,setSpinner] = useState(false)
  const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
const [loading,setLoading] = useState(true)
const [completed, setCompleted] = useState(false)
const [coachingPlan, setCoachingPlan] = useState();
const handleSignature = (e)=>{reviewObj.signature = e}
const handleSignatureDate = (e)=>{reviewObj.signatureDate = e}
  const[reviewObj, setReviewObj] = useState(
   
)
  const location = useLocation();
  function getQueryVariable(variable) {
      var query = location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
  }
 
  const a  = getQueryVariable('a')
  const b  = getQueryVariable('b')
  const c  = getQueryVariable('c')

  const Data = async()=>{
    console.log(a);
    console.log(b);
    console.log(c);
    let coachingPlanResp = await axios.post(API+'get-cp', {id:a, cpId:b}, (err, response)=>{
        return response.data[0]
     });

    let response = await axios.post(API+'get-cp-session', {id:a, cpId:b, checkpointId: c}, (err, response)=>{
       return response.data[0]
    });
    console.log(response.data)
    console.log(coachingPlanResp.data)
    
    setCoachingPlan(coachingPlanResp.data[0])
   setReviewObj(response.data);
   if(response.data.sessionData.managerSignature !== ''){
    setCompleted(true)

    setLoading(false)
  } else {
    setLoading(false)
  }


   
  }


     useEffect(()=>{
      Data()

     },[])


     const signSession = async () => {
      try {
        const response = await axios.post(API + 'sign-session', {
          id: a,
          cpId: b,
          checkpointId: c,
          signature: signature,
          coachingPlan: coachingPlan
        });
    
        // Handle the response as needed
        console.log('Session signed:', response.data);
        setCompleted(true)
      } catch (error) {
        console.error('Error signing session:', error);
        // Handle the error
        window.alert('Something went wrong. Please try again.')
      }
    };

     if(loading){ 
        return ( <Box sx={{textAlign:'center', marginTop:20}}>
        <CircularProgress/>
        <Typography><b>Loading</b></Typography>
      </Box>)
     } else {
        if(completed){
          return(
            <div style={{backgroundColor:'#D5EDE3', height:'100vh'}}>
            <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center'}}>
           <AppBar sx={{marginBottom:'50px'}}>
           <Toolbar
                sx={{
                  backgroundColor:'#1A9A71',
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
    
    <img height='auto' width={250} src={logo}/>
              </Toolbar>
           </AppBar>
           <div className="above-btn">
           
        <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px', justifyContent:'center'}}>
            <Typography className="complete-heading" sx={{fontSize:'2vw'}} variant="button">Thank you for signing! </Typography><br></br><p className="complete-p"  style={{fontSize:'20px',paddingRight:'40px',paddingLeft:'40px'}}>This has been sent back to the person that initiated this process. You will be emailed the plan. If you have any questions you can reach out to them for assistance.</p><p>You may now close this window.</p>
            </Paper>
            </div>
            </Box>
            <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
            </div>)
        } else {
          

    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{window.close()}}>Close</Button>
          </Toolbar>



       </AppBar>

       <br></br>
       <Paper className="demo-paper" sx={{ textAlign: 'center', padding: '3%', maxWidth: '95%', marginTop: '70px', justifyContent: 'center' }}>
           <Typography variant="h5" sx={{marginBottom:'25px'}}><b>COACHING SESSION</b></Typography>
     
               <hr></hr>
<Box sx={{textAlign:'start'}}>
               </Box>

<Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>EMPLOYEE:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{coachingPlan.name.name}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>POSITION:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{coachingPlan.position}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>DATE CREATED:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{formatDate(reviewObj.dateOfNextReview)}</Form.Label>
        </Form.Group>
      </Grid>
    </Grid>
            <hr></hr>
            <Box style={{ textAlign: 'start' }}>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Concerns or goals to be addressed:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.concernsAndGoals}}/></Box>
            <hr></hr>
          
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Strengths to Leverage:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.strengths}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Areas for Improvement:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.areasImprovement}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Key Objectives for Today’s Session:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.keyObjectives}}/></Box>
           
    </Box>
            <hr></hr>
<Box sx={{textAlign:'start'}}>
<Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Action Items and Follow-Up:</b></Typography>

<TableContainer>
<Table>
<TableHead  sx={{width:'100%'}}>
               
                  <TableCell sx={{width:125}}><b>Action Item</b></TableCell>
                  <TableCell sx={{width:125}}><b>Who is Responsible</b></TableCell>
                  <TableCell><b>Due Date</b></TableCell>
                  <TableCell><b>Notes</b></TableCell>
                 
         
              </TableHead>
              <TableBody>
                {/* {reviewObj.specificActionSteps.map((step, index) => (
                  <TableRow key={index}>
                    <TableCell>{step.whatToDo}</TableCell>
                    <TableCell>{step.byWhen}</TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.resourcesNeeded}}/></TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.potentialObstacles}}/></TableCell>
                  
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
            </TableContainer>
</Box>

<Box sx={{textAlign:'start'}}>
   <hr/>
   <Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
          <Typography variant="button" sx={{ fontSize: 16 }}>
        <b>Next Session Date and Time:</b>
      </Typography>   
          </Form.Label>
          <br />
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
        <Form.Label>
          <Typography variant="button" sx={{ fontSize: 16 }}>
        <b>{formatDate(reviewObj.sessionData.followUpDate)}</b>
      </Typography>   
          </Form.Label>
        </Form.Group>
      </Grid>

    </Grid>
  
      <hr/>   
      <Row style={{display: reviewObj.sessionData.managerSign ? 'flex': 'none'}} className="mb-3">
       
        <Form.Group  as={Col} >
          <Typography variant="button"><b>Manager Signature:</b></Typography>
          <br></br>
          <Form.Control 
          value={signature}
          onChange={(e)=>{setSignature(e.target.value); console.log(e.target.value)}} 
          style={{fontFamily:'Signature', fontSize:'18px'}} type="text" />
          <Typography variant="button"><b>{coachingPlan.manager.name}</b></Typography>
        </Form.Group>
       
        <Form.Group as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Control 
          value={formatDate(new Date())}
          disabled
          />
        </Form.Group>
      </Row>

      <Box sx={{textAlign:'end'}}>
         <Button 
         variant="contained"
         onClick={signSession}
         ><b>Send Back</b></Button>
      </Box>
     
    </Box>  
       
    
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
  } 
}
}