import React, { useState } from 'react';
import { IconButton, Tooltip, Menu, MenuItem, Typography } from '@mui/material';

function ToolTipButtonMenu(props) {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (action) => {
    // You can perform any action based on the selected menu item
   if(action === 'Option 1'){
    
    props.Option1Click();

   } else {
    props.Option2Click();
   }
    handleMenuClose();
  };

  const tooltipStyle = {
    zIndex: 1000, // You can adjust this value as needed
  };

  return (
    <div style={{display:'inline', marginLeft:5}}>
      <Tooltip sx={props.style} title={props.Title} arrow placement="top" style={tooltipStyle}>
        <IconButton sx={props.iconBtnSx} onClick={handleMenuOpen} color="primary">
          {props.Icon}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem sx={props.Option1Style} onClick={() => handleMenuItemClick('Option 1')}><Typography variant='button'>{props.Option1Title}</Typography></MenuItem>
        <MenuItem sx={props.Option2Style} onClick={() => handleMenuItemClick('Option 2')}><Typography variant='button'>{props.Option2Title}</Typography></MenuItem>
        {/* Add more MenuItems as needed */}
      </Menu>
    </div>
  );
}

export default ToolTipButtonMenu;
