import React, { useEffect, useState , forwardRef, useRef } from "react";
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import EmployeeTransferList from "../EmployeeTransferList";
import { Paper, Typography } from "@mui/material";
import QuestionSetup from "../QuestionSetup";
import deleteBtn from '../../assets/css/images/delete_btn.png'
import questionData from '../../json/UE-questions.json';
import data from '../../json/employees.json'
import uuid from 'react-uuid';
import Select from 'react-select';
import {Table} from "react-bootstrap";
import { PropaneSharp } from "@mui/icons-material";
import { ReviewerList } from "../ReviewerList";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import axios from "axios";
import {API} from '../../Controllers/API'
import Spinner from 'react-bootstrap/Spinner';
import RevieweeSelect from "../RevieweeSelect";
import Loader from "../../components/Loader";
import { Box } from "@mui/system";

const BackBtn = forwardRef((props, ref) => {
  return <a {...props}
  onClick={props.OnClickThis}
  ref={ref} ></a>
})


const UE = (props)=>{
  const token = JSON.parse(sessionStorage.getItem('token'))
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const [switchOne, setSwitchOne] = useState(false)
  const itemRef = useRef(null);
  const [savedWorksheets, setSavedWorksheets] = useState([])
  const [loading, setLoading] = React.useState(true);
const [worksheetDataArray, setWorksheetDataArray] = useState([])
const [renderWorkSheetData, SetRenderWorkSheetData] = useState([])
const [currentQuestion,setCurrentQuestion] = useState(null)
const [addCurrentQuestion,setAddCurrentQuestion] = useState(null)
const [update,setUpdate] = useState('')
const [customSelectVal,setCustomSelectVal] = useState()
const [customCriteriaQuery,setCustomCriteriaQuery] = useState('')
const [carbonCopyList, setCarbonCopyList] = useState([])
const [ccName, setCcName] = useState('')
const [ccEmail, setCcEmail] = useState('')
const [value, setValue] = useState([]);
const [reviewers, setReviewers] = useState([])
const [reviewee, setReviewee] = useState('')
const [show, setShow] = useState(false);
const [jTshow, setJtShow] = useState(true);
const [eIDshow, setEiDShow] = useState(true);
const [departmentShow, setDepartmentShow] = useState(true);
const [tipShow, setTipShow] = useState(true);
const [goalPlanningSection, setGoalPlanningSection] = useState(false)
const [reviewObj, setReviewObj] = useState()
const [jobTitle, setJobTitle] = useState('')
const [employeeId, setEmployeeId] = useState('')
const [department, setDepartment] = useState('')
const [timeInPosition, setTimeInPosition] = useState('')
const [reviewPeriod, setReviewPeriod] = useState('')
const [nextEvaluationDateShow, setNextEvaluationDateShow] = useState(true);
const [nextEvaluationDate, setNextEvaluationDate] = useState('');
const handleNextEvaluationDate = (e) => setNextEvaluationDate(e);
const [loadingMessage, setLoadingMessage] = useState('');
const [working, setWorking] = React.useState(false);
const [sendImmediately, setSendImmediately] = useState(false);

const [managers, setManagers] = useState([{
  dateOfLastReview: "NA",
email: "null",
id: "null",
name: "null",
reviews: [''],
role: "Manager"
}])
const [employees, setEmployees] = useState([{
  dateOfLastReview: "NA",
email: "null",
id: "null",
name: "null",
reviews: [''],
role: "Employee"
}])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleJobTitle = (e) => setJobTitle(e);
const handleEmployeeId = (e) => setEmployeeId(e);
  const handleDepartment = (e) => setDepartment(e);

  const handleTimeInPosition = (e) => setTimeInPosition(e);

  const handleReviewPeriod = (e) => setReviewPeriod(e);

  
const ShowDemo = ()=>{
  handleShow()


}

const deleteWorksheet = async (a,b)=>{
  console.log(a+" "+b)
     let resp = await axios.post(API+'delete-worksheet',{id:data.id,worksheetId:a,type:b}, (err,response)=>{
      return response
     })
    console.log(resp.data)
     if(resp.data == 'worksheet deleted'){
      setSavedWorksheets(savedWorksheets.filter(i=>{return (i.id != a)}))
     }
  }

  const SetupReview = async () => {
    try {
      setWorking(true);
      setLoadingMessage(
        <>
          <Typography variant="button" sx={{ color: 'white', fontSize: 20 }}>Sending Reviews</Typography>
          <Typography sx={{ color: 'white', fontSize: 16 }}>
            <b>Note:</b> This may take a while if there are a lot of reviews being sent.
          </Typography>
        </>
      );
  
      // Validate input before proceeding
      if (!reviewee || !reviewee.name || reviewers.length === 0 || !reviewPeriod) {
        window.alert('Please ensure that all necessary fields are filled: Reviewee, Reviewers, and Review Period.');
        setWorking(false);
        return;
      }
  
      // Prepare reviewer array
      const reviewerArr = reviewers.map(i => ({
        id: uuid(),
        name: i.name,
        firstName: i.firstName,
        lastName: i.lastName,
        email: i.email,
        status: 'pending',
      }));
  
      // Create the review object
      const reviewId = uuid();
      const reviewObject = {
        reviewId: reviewId,
        reviewee: reviewee,
        reviewFrom: {
          id: token.subaccount ? token.subdata.id : data.id,
          name: token.subaccount ? `${token.subdata.firstName} ${token.subdata.lastName}` : data.name,
          email: token.subaccount ? token.subdata.email : data.replyToEmail,
        },
        reviewType: 'Upward Evaluation',
        reviewers: reviewerArr,
        dateCreated: new Date().toLocaleDateString(),
        nextReviewDate: nextEvaluationDate,
        ccGroup: carbonCopyList,
        link: `http://localhost:3000/?a=${data.id}&b=${reviewee.id}&c=${reviewId}`,
        reviewData: {
          worksheetData: worksheetDataArray,
          goalPlanningSection: goalPlanningSection,
          jobTitle: jobTitle,
          employeeId: employeeId,
          department: department,
          timeInPosition: timeInPosition,
          reviewPeriod: reviewPeriod,
          type: 'Upward Evaluation',
        },
      };
  
      // Send review data to the server
      setSwitchOne(true);
      const response = await axios.post(`${API}add-review`, {
        id: data.id,
        reviewID: reviewId,
        obj: reviewObject,
        send: sendImmediately
      });
  
      if (response.status !== 200 ) {
        throw new Error('Failed to add review.');
      }
  
      console.log('Review added successfully:', response.data);
  
      // Update state with new data
      setUpdate(prev => !prev);
  
      // Reset the state and close the loader
      setSwitchOne(false);
      setReviewee({ reviewee: '' });
      setWorking(false);
      itemRef.current.click(); // Trigger click event for any necessary UI update
  
    } catch (error) {
      console.error('Error setting up review:', error);
      window.alert('An error occurred while setting up the review. Please try again.');
      setSwitchOne(false);
      setWorking(false);
    }
  };


let employeeArr = {
  type:'Employees',
  people:[]
}

let managerArr = {
  type:'Managers',
  people:[]
}
const [totalReveieweeArr, setTotalRevieweeArr] = useState([managerArr,employeeArr])
let noDupe1;
let noDupe2;
const sendReviews = ()=>{
  let totalReviewData = {
    reviewee: reviewee,
    

  }
  console.log(totalReviewData)

}
useEffect(()=>{

  console.log(reviewee)
  sendReviews()
},[reviewee])

useEffect(()=>{
  axios.post(API,{email:data.email}).then((response)=>{
    
  return response

      }).then((response)=>{
        console.log(response.data[0])
        
        return response

        
       
      }).then(((response)=>{
        setEmployees(response.data[0].employees)
        setSavedWorksheets(response.data[0].savedWorksheets[2].worksheets)

        setUpdate(!update)
        setLoading(false)
      }))
},[])


useEffect(()=>{
  data.employees.forEach(i=>{
    if(i.role === 'Manager'){
      grpOpts[0].data.push({
        label:i.name,
        value:i.id
      })
    }
      if(i.role === 'Employee'){
        grpOpts[1].data.push({
          label:i.name,
          value:i.id
        })
    }

    
  })
  options = [
  
    createGroup(grpOpts[0].name, grpOpts[0].data, setValue),
    createGroup(grpOpts[1].name, grpOpts[1].data, setValue)
  ]
  setValue(options)
  setUpdate(!update)
},[])
const grpOpts = [{
  name:'managers',

  data:[
  
]},{
  name:'employees',
  data: [
 
]}

]
const createGroup = (groupName, options, setValue) => {
  return {
    label: (() => {
      return (
        <div
          onClick={() =>
            setValue(value =>
              value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
            )
          }
        >
          {groupName}
        </div>
      );
    })(),
    options: options
  };
};
let options = [
  
  createGroup(grpOpts[0].name, grpOpts[0].data, setValue),
  createGroup(grpOpts[1].name, grpOpts[1].data, setValue)
];
let initData = questionData.map(i=>{

  return ({
    catIndex:i.index,
    cat:i.name
  })
  
}
  )
const [customCategories,setCustomCategories] = useState(initData)
const [custom, setCustom] = useState('none')

const [customCatQuery, setCustomCatQuery] = useState('')
const handleSetCustomQuery = (e) =>{
  setCustomCatQuery(e)
  console.log(customCatQuery)
}

const handleCcName = (e) =>{
  setCcName(e)
  console.log(ccName)
}


const handleCcEmail = (e) =>{
  setCcEmail(e)
  console.log(ccEmail)
}

const handleRevieweeSelect = (e) =>{
  setReviewee(e)
  console.log(reviewee)
}


const handleTextArea = (e) =>{
  setCustomCriteriaQuery(e)
  console.log(customCriteriaQuery)
}

useEffect(()=>{
  SetRenderWorkSheetData(worksheetDataArray)
  if(currentQuestion){
    
  setUpdate(currentQuestion.catIndex)

}
},[currentQuestion])

useEffect(()=>{
  SetRenderWorkSheetData(worksheetDataArray)
},[update])
const addQuestion = (e)=>{
  if(currentQuestion != null){


    let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == 'questions'));
  
    if(objIndex === -1){
    
      worksheetDataArray.push({
        catIndex: 'questions',
        cat:'',
        questions:[{
          id:uuid(),
          question: currentQuestion.question}]
    })
 
    setUpdate(!true)
    } else{
      worksheetDataArray[objIndex].questions.push({
        id:uuid(),
        question: currentQuestion.question
       
    })
    setUpdate(!update)
    }

  }

}


const removeQuestion = (a,b)=>{
let conf = window.confirm("You are deleting this question. Are you sure you would like to do this?")
if(conf){
  
let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == a));
console.log(objIndex)
   let filter = worksheetDataArray[objIndex].questions.filter(i=> {return( i.id !== b)})
   console.log(worksheetDataArray[objIndex].questions.length)
if(worksheetDataArray[objIndex].questions.length < 2){
  worksheetDataArray.splice(objIndex,1)
} else{
  worksheetDataArray[objIndex].questions = filter;

}

   setUpdate(!update)

}  
}

const removeEntireCategory = (a)=>{
  let conf = window.confirm("This will delete all of the questions and this category. You can always re-add them if you would like. Are you sure you would like to do this?")
if(conf){
  let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == a));
  worksheetDataArray.splice(objIndex,1)
  setUpdate(!update)
}
}

const clear = ()=>{
  let conf = window.confirm("This will clear the entire worksheet. Are you sure you would like to do this?")
  if(conf){
    setWorksheetDataArray([])
    SetRenderWorkSheetData([])
    console.log(worksheetDataArray)

  }
}


const useSuggested = ()=>{
  let conf = window.confirm("This will clear all of the current worksheet criteria and add suggested criteria. Are you sure you would like to do this?")
  if(conf){
let arr =[]
    questionData.forEach(i=>{
      arr.push({
        catIndex:i.index,
        cat: i.name,
        questions:i.questions
      })
    })
    setWorksheetDataArray(arr)
    setUpdate(!update)
  }
}


const addCustomQuestion = (e)=>{

  let objIndex = worksheetDataArray.findIndex((obj => obj.catIndex == e.catIndex));
  console.log(objIndex)
  if(objIndex === -1){
  
    worksheetDataArray.push({
      catIndex: e.catIndex,
      cat:e.cat,
      questions:[{
        id:uuid(),
        question: e.question}]
  })

  setUpdate(!true)

  } else{
    worksheetDataArray[objIndex].questions.push({
      id:uuid(),
      question: e.question
     
  })
  console.log(worksheetDataArray)
  setUpdate(!update)
  setCustomCriteriaQuery('')
  }

}
    return (
      <div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header><b>PERSON BEING REVIEWED: </b><span>{"\u00A0"}</span>{`${reviewee.firstName} ${reviewee.lastName} `}</Accordion.Header>
        <Accordion.Body>
      <Form.Group className="mb-3">
      <Form.Group className="mb-3">
        <RevieweeSelect
        employees={employees}
        handleRevieweeSelect={handleRevieweeSelect}
        />
      </Form.Group>
   </Form.Group>
   </Accordion.Body>
   </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><b>REVIEWERS</b></Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mb-3">
      <Form.Label><b>Select Reviewers:</b></Form.Label>
      <p>Click a person’s name to add them as a reviewer. If you would like to remove a person you can click their name in the right column.</p>
      <ReviewerList
       setReviewers={setReviewers}
       employees={employees}
       reviewers={reviewers}
       />
      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><b>SETUP CRITERIA</b></Accordion.Header>
        <Accordion.Body>
        <QuestionSetup 
        QuestionSelectOnChange={(e)=>{
           let val  = JSON.parse(e.target.value);

           console.log(val)
           
           setCurrentQuestion(val)
        }}
        QuestionData={questionData[0].questions.map(i=>{
       
                let val = {
                  catIndex:i.index,
                  
                  question:i.question
                }
              
                return(<option value={JSON.stringify(val)}>{i.question}</option>)
              })}
            
     
        CategoryData={customCategories.map(i=>{
 
          let val = {
            catIndex:i.catIndex,
            cat:i.cat
          }
          console.log(val)
          return(<option value={JSON.stringify(val)}>{i.cat}</option>)
        })}
        WorkSheetData={renderWorkSheetData.map(i=>{
          let cat = i.cat
          return(<div>
            {i.questions.map(a=>{
              return (<p>{a.question}<a style={{cursor:'pointer'}} onClick={()=>{removeQuestion(i.catIndex, a.id)}}> <img src={deleteBtn} width="10px" height="10px"/></a></p>)
            })}
          </div>)
        })}
        AddQuestion={()=>{addQuestion(currentQuestion);console.log(currentQuestion)}}
        ClearButton={clear}
        SuggestedButton={useSuggested}
        CustomDisplay={custom}
        CustomCatValue={customCatQuery}
        DisplayCustomInput='none'
        CustomCriteriaValue={customCriteriaQuery}
        // SetCustomCategory={(e)=>{
        //   console.log(e.target.value)
        //   setCustomSelectVal(JSON.parse(e.target.value))
        // }}
          // CustomCat={(e)=>{
          //   handleSetCustomQuery(e.target.value)
           
          // }}
          OnCustomCriteriaChange={(e)=>{
            handleTextArea(e.target.value)
            
            
          }}
          OnAddCustomQuestion={(e)=>{
            
            let obj = {
              catIndex:'questions',
              cat:'',
              question:customCriteriaQuery
            }

          addCustomQuestion(obj)
            setUpdate(!update)
          }}
          AddCustomCategory={()=>{
            console.log(customCatQuery)
            let val = {
              catIndex:uuid(),
              cat:customCatQuery.toUpperCase()
            }
            initData.push(val)
       
            setCustomCategories(initData)
            setCustomCatQuery('')
            console.log(customCategories)
          }}
          SaveWorksheet={async()=>{
            
           let value = window.prompt('Please enter a name for the worksheet.')
           if(value){
           console.log(value)
           let val = {
            id:uuid(),
            title:value,
            type:'UE',
           data: worksheetDataArray,
           }
           let resp = await axios.post(API+'add-worksheet',{id:data.id, obj: val},(err,data)=>{
                   return data
           })
           console.log(resp.data)
           if(resp.data === 'worksheet added'){
            window.alert('Worksheet saved. You will see the saved worksheet the review you create.')
            savedWorksheets.push(val)
          } else{
            window.alert('Something went wrong. Worksheet was not saved. Please try again.')

          }
           setUpdate(!update)
        }
          }}
          WorksheetTableItems={
            savedWorksheets.map(i=>{
           
            
                return (
              
                  <tr>
    
                  <td key={i.id}>{i.title}</td>
                  <td style={{textAlign:'center'}}>
                  <Button variant="outlined" style={{ color:'#199A71', fontWeight:'bold', marginRight:'10px'}} onClick={()=>{
                 setWorksheetDataArray(i.data)
                 setUpdate(!update)
              }}>Load</Button>
              <Button variant="outlined" style={{borderColor:'tomato',fontWeight:'bold', color:'tomato'}} onClick={()=>{
                  deleteWorksheet(i.id, i.type)
                 setUpdate(!update)
              }}>Delete</Button>
              </td>
                </tr>
                )
              })
            

          }
      
          
         
        />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><b>FINALIZE</b></Accordion.Header>
        <Accordion.Body>
      <Form.Group>
        <Form.Label><b>Enter the review period:</b></Form.Label>
     <Form.Control onChange={(e)=>handleReviewPeriod(e.target.value)}  type="text" placeholder="Review Period"/>
      </Form.Group>
       
    {/* <Typography style={{marginTop:'10px'}}><b>GOAL SECTION</b></Typography>
      <Form.Group>
      <Form.Check 
      onChange={()=>{setGoalPlanningSection(!goalPlanningSection); console.log(goalPlanningSection)}}
           label="Add goal planning section."
          />
      </Form.Group> */}

      <Typography style={{marginTop:'10px', marginBottom:'10px'}}><b>Extra Reviewee Information(optional)</b></Typography>
      {/* <Form.Group> */}
      {/* <InputGroup className="mb-3"> */}
      {/* <InputGroup.Checkbox onChange={()=>{setJtShow(!jTshow); console.log(!jTshow);
        if(jTshow === false){
           setJobTitle('')
           console.log(jobTitle)
        }
        }} aria-label="Checkbox for following text input" />
      <Form.Control value={jobTitle} onChange={(e)=>handleJobTitle(e.target.value)}  disabled={jTshow} placeholder="Add Job Title" aria-label="Text input with checkbox" />
    </InputGroup>
          
 <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setEiDShow(!eIDshow); console.log(!eIDshow);
        if(eIDshow === false){
           setEmployeeId('')
           console.log(eIDshow)
        }}}  aria-label="Checkbox for following text input" />
        <Form.Control value={employeeId} onChange={(e)=>handleEmployeeId(e.target.value)} disabled={eIDshow} placeholder="Add Employee ID" aria-label="Text input with checkbox" />
      </InputGroup> */}
      <InputGroup className="mb-3">
        <InputGroup.Checkbox onChange={()=>{setDepartmentShow(!departmentShow); console.log(!departmentShow);
        if(departmentShow === false){
           setDepartment('')
           console.log(departmentShow)}}} aria-label="Checkbox for following text input" />
        <Form.Control value={department} onChange={(e)=>handleDepartment(e.target.value)} disabled={departmentShow} placeholder="Add Department" aria-label="Text input with checkbox" />
     </InputGroup>

    {/* <InputGroup className="mb-3">
      <InputGroup.Checkbox onChange={()=>{setTipShow(!tipShow); console.log(!tipShow);
        if(tipShow === false){
           setTimeInPosition('')
           console.log(tipShow)}}} aria-label="Checkbox for following text input" />
      <Form.Control value={timeInPosition} onChange={(e)=>handleTimeInPosition(e.target.value)} disabled={tipShow} placeholder="Add Time in position" aria-label="Text input with checkbox" />
    </InputGroup> */}
      {/* </Form.Group> */}
      <hr></hr>
      <Typography variant="button"><b>Date of next evaluation</b></Typography>
      <Typography variant="subtitle2">The date will be added to the Evaluation Calendar and will also appear on the Dashboard as the date approaches.</Typography>
      <InputGroup className="mb-3">
      <InputGroup.Checkbox onChange={()=>{setNextEvaluationDateShow(!nextEvaluationDateShow); console.log(!nextEvaluationDateShow);
        if(nextEvaluationDateShow === false){
           setNextEvaluationDate('')
           console.log(nextEvaluationDate)
        }
        }} aria-label="Checkbox for following text input" />
      <Form.Control type="date" value={nextEvaluationDate} onChange={(e)=>handleNextEvaluationDate(e.target.value)}  disabled={nextEvaluationDateShow}  aria-label="Text input with checkbox" />
    </InputGroup>
      <hr></hr>
         <Typography variant="buttom"><b>ADD CCs</b></Typography>
         <Form.Group>
          <Form.Label>Add any one you would like to copy on the completed evaluation email.</Form.Label>
         <InputGroup className="mb-3">
        <Form.Control
          placeholder="Recipient's Name"
          value={ccName}
          onChange={(e)=>{
            handleCcName(e.target.value)
          }}
          aria-describedby="basic-addon2"
        />
         <Form.Control
          placeholder="Recipient's Email"
          onChange={(e)=>{
            handleCcEmail(e.target.value)
          }}
          value={ccEmail}
          aria-describedby="basic-addon2"
        />
        <Button onClick={()=>{
            let val = {
              id:uuid(),
              name: ccName,
              email: ccEmail
            }
            setCcEmail('')
            setCcName('')
            carbonCopyList.push(val)
            setUpdate(!update)
        }} style={{color:'white', backgroundColor:'#1979E6'}} id="button-addon2">
          Add
        </Button>
      </InputGroup>
      </Form.Group>
      <Form.Group>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
       {carbonCopyList.map(i=>{
        return(
          <tr>
       
          <td>{i.name}</td>
          <td>{i.email}</td>
          <td style={{textAlign:'center'}}><Button
          onClick={()=>{
            let filter = carbonCopyList.filter(a=>{
              return (i.id !== a.id)
            })
            setCarbonCopyList(filter)
            setUpdate(!update)
             console.log(i.id)
          }}
          style={{padding:'2px', color:'red'}}>Delete</Button></td>
        </tr>
        )
       })}
      </tbody>

    
    </Table>
    <hr></hr>
    <Box sx={{marginBottom:2}}>
    <Form.Check
                        checked={sendImmediately}
                        onChange={(e) => setSendImmediately(e.target.checked)}
                        type="switch"
                        id="custom-switch"
                        label={<Typography variant="subtitle2"><b>Send Emails Immediately</b></Typography>}
                      />
                      <Typography sx={{ fontSize: 14,}} variant="caption">If you leave this switch off you can manually send the reference emails to each person in the details section evaluation manager.</Typography>
                   
                      </Box>
    <Typography><b>LIST OF REVIEWERS</b></Typography>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
       {reviewers.map(i=>{
        return(
          <tr>
      
          <td>{i.name}</td>
          <td>{i.email}</td>
        </tr>
        )
       })}
      </tbody>

    
    </Table>
    
      <hr></hr>
      <div>
       <Link to="demo" onClick={()=> localStorage.setItem('refer', JSON.stringify(
        {reviewee:reviewee, 
        worksheetData:worksheetDataArray,
        department:department,
        reviewPeriod:reviewPeriod,
        ccGroup:carbonCopyList,
        type: 'Upward Evaluation'
        }))} target="_blank"> <Button style={{backgroundColor:'#1A7AE6',  color:"white"}} onClick={ShowDemo}>Demo the Evaluation</Button></Link>

        <Button onClick={()=>{SetupReview();
       // setLoading(true)
       }} style={{float:'right',backgroundColor:'#1A7AE6', color:"white"}}>{sendImmediately? 'Create and Send':'Create'} Evaluations <span><Spinner style={{display:switchOne ? '':'none'}}  size="sm"  animation="border" variant="light"  /></span></Button>
 
      <BackBtn ref={itemRef} OnClickThis={props.OnClickSend}/>
      </div>

      </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
{/* <div style={{zIndex:10000000}}>
    <Modal style={{zIndex:10000000}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      </div> */}
      <Loader open={working} title={loadingMessage}/>
    </div>
    )
}

export default UE;