import React, {useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box, Button, Typography } from "@mui/material";
import { Calendar } from "react-calendar";
import { useEffect } from "react";
import '../assets/css/calendar.css'
import axios from "axios";
import { API } from "../Controllers/API";
import { formatDate } from "../classes/global-functions";
import { AddToCalendar } from "./AddToCalendarBtn";
export const UpcomingEvaluations = (props)=>{
    const data = JSON.parse(sessionStorage.getItem('profile-data'))
    const [value, onChange] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(value.getMonth());
    const [update, setUpdate] = useState(true)
    const [mainData, setMainData] = useState([])
    const DeleteCalendarItem = (a)=>{
      
      axios.post(API+'delete-cal-item', {id:data.id, calendarId:a}, (err,response)=>{
        return response
      }).then(()=>{
        setUpdate(!update)
      })
    }

    useEffect(()=>{
      axios.post(API,{email:data.email}, (err,response)=>{
        return response
      }).then((response)=>{
         setMainData(response.data[0].scheduledEvaluations)
         console.log(mainData)
      })

      
    },[])


    useEffect(()=>{
      axios.post(API,{email:data.email}, (err,response)=>{
        return response
      }).then((response)=>{
         setMainData(response.data[0].scheduledEvaluations)
         console.log(mainData)
      })

      
    },[update])
    
   
    return(
    <Box>
  
  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell align="center">Employee Name</TableCell>
            <TableCell align="center">Evaluation Scheduled Date</TableCell>
            <TableCell align="center">Notes</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
            mainData.filter((i)=>{
                let dateOfNextRev = new Date(i.dateOfNextReview)

                return ( dateOfNextRev.getMonth() === value.getMonth())
            }).filter((i)=>{
              let dateOfNextRev = new Date(i.dateOfNextReview)

              return ( dateOfNextRev.getFullYear() === value.getFullYear())
          }).map((i, index)=>{
              let date = new Date(i.dateOfNextReview)
              let todaysDate = new Date()
                return(
                    <TableRow>
                      <TableCell align="center">{i.name.name}</TableCell>
                    <TableCell align="center">{formatDate(i.dateOfNextReview)}</TableCell>
                    <TableCell align="left">{i.notes}</TableCell>
                    <TableCell align="center"><Button style={{display: (date.getMonth() === todaysDate.getMonth())?'inline':'none'}} onClick={props.SetupReview}>Setup Review</Button><Button onClick={()=>DeleteCalendarItem(i.id)} sx={{color:'red'}}>Delete</Button><AddToCalendar Name={i.name.name} Date={i.dateOfNextReview}/></TableCell>
                 </TableRow>
                )
     
            })
        }
        </TableBody>
      </Table>
    </TableContainer>
        
    
    </Box>)
}