import { Box, Typography } from '@mui/material';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { formatDate } from '../classes/global-functions';

function createCategoryTables(criteria) {
  const categoryTables = {};
  criteria.forEach(item => {
    if (!categoryTables[item.category]) {
      categoryTables[item.category] = [];
    }
    categoryTables[item.category].push(item);
  });

  const categoryHTML = [];
  for (const category in categoryTables) {
    const categoryCriteria = categoryTables[category];
    const categoryRows = categoryCriteria.map(item => {
      const questions = item.questions.map(q => q.question).join('<br>');
      const responses = item.questions.map(q => q.averageResponse).join('<br>').toUpperCase();
      return (
        <Row key={item.catIndex} style={{ marginBottom: '10px' }}>
          <Col md={9} style={{ paddingBottom: '10px' }}>{questions}</Col>
          <Col md={3} style={{ paddingBottom: '10px' }}>
            <b>{responses}</b>
          </Col>
        </Row>
      );
    });

    categoryHTML.push(
      <div key={category}>
        <h4>{category}</h4>
        {categoryRows}
      </div>
    );
  }

  return categoryHTML;
}

const Rows = (props)=>{
    return (
        <Row>
        <Col md={8}>
        <Typography >{props.criteria}</Typography><br></br>

      
        </Col>
        
        <Col md={4}>
        <Typography variant="button"><b>{props.response}</b></Typography><br></br>
 
        </Col>
      </Row>
    )
}

function createPartTwoList(partTwo) {
  const listItems = partTwo.map((item, index) => (
    <li key={index}>{item.comment}</li>
  ));
  return <ul>{listItems}</ul>;
}

function EvaluationReport({ resultObject }) {
  const date = new Date()
  return (
    <Container>
        <Box sx={{textAlign:'center', marginBottom:2}}>
      <Typography variant='button' sx={{fontSize:18, textAlign:'center'}}><b>{resultObject.evaluationType} Report</b></Typography>
      </Box>
      <p><strong>Employee:</strong> {resultObject.employeeName} &nbsp;&nbsp;&nbsp; <strong>Today's Date:</strong>{formatDate(date)}</p>
      <p><strong>Review Period:</strong> {resultObject.reviewPeriod}</p>
      <p><strong>Number of Reviews Averaged:</strong> {resultObject.reviewsAveraged}</p>
      
      <Typography variant='button' sx={{fontSize:16}}><b>Part One: Average Responses</b></Typography>
      <Row>
        <Col md={8}>
        <Typography variant='button'><b>Criteria</b></Typography><br></br>

      
        </Col>
        
        <Col md={4}>
        <Typography variant="button"><b>Average Response</b></Typography><br></br>
 
        </Col>
      </Row>
      <hr />
      {resultObject.criteria.map(i=>{
        return(
        <Box>
        <Typography className='mb-1'><b>{i.category}</b></Typography>
        {i.questions.map(i=>{
            return <Rows criteria={i.question} response={i.averageResponse}/>
        })}
        </Box>
        )
      })}
      <Typography variant='button' sx={{fontSize:16}}><b>Part Two: Comments</b></Typography>
      {createPartTwoList(resultObject.partTwo)}
    </Container>
  );
}

export default EvaluationReport;
