// LoaderModal.js
import React from 'react';
import { Modal, CircularProgress, Box } from '@mui/material';
import logo from '../../assets/css/PECLogo.png';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    zIndex: 2500, // Ensure this value is higher than other modals
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    background: 'linear-gradient(#1A9A71, #1A9A71)',
    padding: 0,
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '50%',
    maxHeight: 85, // Adjust the maximum height as needed
    marginBottom: 5,
  }
};

const Loader = ({ open, title }) => {
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2500, // Ensure this value is higher than other modals
      }}
      open={open}
      closeAfterTransition
      BackdropProps={{
        style: { zIndex: 2400 } // Optional: control backdrop zIndex if needed
      }}
    >
      <Box sx={styles.container}>
        <img style={styles.logo} src={logo} alt="logo" />
        {title}
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    </Modal>
  );
};

export default Loader;
