import React from "react";
import { Container, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';




export const SearchBar = (props)=>{
    return (
        <Container>
        <TextField
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          sx={{ width: '100%', background:'white' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="left">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
          </Container>
    )
}