import * as React from 'react';
import {useState} from 'react'
import { InputGroup, Modal, OverlayTrigger, Spinner } from 'react-bootstrap';
import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Tooltip from 'react-bootstrap/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import Form from 'react-bootstrap/Form';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsIcon from '@mui/icons-material/Sports';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PasswordIcon from '@mui/icons-material/Password';
import SummarizeIcon from '@mui/icons-material/Summarize';
import HandymanIcon from '@mui/icons-material/Handyman';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from '../components/listitems';
import Home from './Home'
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../assets/css/images/PECLogo.png'
import profile_icon from '../assets/css/profile_icon.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Employees from './Employees';
import PeformanceImprovementPlan from './PerformanceImprovementPlan';
import RecentReviews from './RecentReviews';
import axios from 'axios';
import {API} from '../Controllers/API'
import hdLogo from '../assets/css/pec-badge.png'
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { ButtonGroup } from '@mui/material';
import { GettingStarted } from '../components/GettingStarted';
import { EvaluationCalendar } from './EvaluationCalendar';
import Cookies from 'js-cookie';
import Reports from './Reports';
import EvaluationBuilder from './EvaluationBuilder';
import ToolTipButton from '../components/TooltipButton';
import uuid from 'react-uuid';
import {useLocation} from 'react-router-dom';
import { CoachingPlan } from './CoachingPlan/CoachingPlan';


function Copyright(props) {
  return (
    <div style={{textAlign:'center'}}>
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://helpdeskforhr.com">
        Andere HR Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      
    </Typography>
    <img src={hdLogo} height='auto' width='65px'/>
    </div>
  );
}

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundImage:'linear-gradient(#1A9A71, #28B271)',
  
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);



const mdTheme = createTheme({
  palette: {
      primary: {
        main: '#199A71',
      
      },
      
    }, 
  }
);

function DashboardContent() {
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const tokenData = JSON.parse(sessionStorage.getItem('token'));

  const [editEmail, setEditEmail] = useState('');
  const [replyToPlaceholder, setReplyToPlaceholder] = useState(data.replyToEmail);
  const [enableEmailInput, setEnableEmailInput] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = React.useState(true);
  const [subOpen, setSubOpen] = React.useState(false);
  const [showGS, setShowGS] = useState(false);

  const handleCloseGS = () => setShowGS(false);
  const handleShowGS = () => setShowGS(true);
  
  const [mainData, setMainData] = React.useState()
  const [loading, setLoading] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open);
  };


  //Subadmin Variables
  const [subFirstName, setSubFirstName] = useState('')
  const [subLastName, setSubLastName] = useState('')
  const [subEmail, setSubEmail] = useState('')
  const [adminSpinner, setAdminSpinner] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)

  const [update, setUpdate] = useState(false)
useEffect(()=>{
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  axios.post(API,{email:data.email}).then((response)=>{
      console.log(response.data)
      setMainData(response.data)
      if(location.state === 'CP'){
        setMainView(<CoachingPlan/>)
        
        window.history.replaceState(null, '')
      }
      setLoading(false)
    })
  

},[update])

const copyLink = async (a,b)=>{

  let link = `http://app.performanceevaluationcenter.com/#/reset-password?a=${a}&b=${b}`
  await navigator.clipboard.writeText(link)
  window.alert('Copied Link to clipboard. Now you can use this link to personally send the link to the secondary admin to reset their password.')
  }

const AddAdmin = async (object, sendEmail, linkObject)=>{

  setAdminSpinner(true)
let resp = await axios.post(API+'add-secondary-admin',{object:object, sendEmail:sendEmail, linkObject: linkObject});
console.log(resp)
if(resp.data === 'error'){
  window.alert('Something went wrong. Please try again.')
  setAdminSpinner(false)
}
if (resp.data === 'Email Exists') {
  window.alert('This email address is already connected to an account in the system please use another email address. ')
  setAdminSpinner(false)
}
else {
  console.log(resp.data)
  window.alert('Secondary Admin has been added.')
  setSubOpen(false);
  setAdminSpinner(false)
  setUpdate(!update)
  setSubFirstName('');
  setSubLastName('');
  setSubEmail('');
  setSendEmail(false)

}

}
const location = useLocation()
const [mainView, setMainView] = React.useState(<RecentReviews SetupReview={()=>{setMainView(<Home/>)}} Data={mainData}/>);

  const LoadData = ()=>{
    let userCreds = JSON.parse(window.usercreds);
  console.log( userCreds)
  axios.post(API,{email:userCreds.email}).then((response)=>{
      return response.data
    })
  }
if(loading){
  return(<Box sx={{textAlign:'center', marginTop:20}}>
  <CircularProgress/>
  <Typography><b>Loading</b></Typography>
</Box>)
} else {
  
  return (
    <ThemeProvider theme={mdTheme}>
      
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              backgroundColor:'linear-gradient(#1A9A71, #1A9A71)',
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img className='logo-img' height='auto' width={250} src={logo}/>
            <a href='https://performanceevaluationcenter.com/demos/' style={{textDecoration:'none'}} target='_blank'><Button  variant='button' sx={{position:'absolute', top:'15px', right:'15px', color:'white'}}>Get Started</Button></a>
            <IconButton color="inherit">
             
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
        PaperProps={{
          sx:{
            backgroundColor:'linear-gradient(#1A9A71, #1A9A71) !important',
          }
        }}
        className='drawer' variant="permanent" open={open}>
          
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              
              px: [1],
            }}
          >

            <IconButton sx={{color:'white'}} onClick={toggleDrawer}>
              <ChevronLeftIcon htmlColor='white' />
           
            </IconButton>

          </Toolbar>
          <IconButton onClick={()=>handleShow()}  sx={{position:'absolute', top:10 ,left:10, cursor:'pointer', height:50, width:50}} ><AccountCircleIcon sx={{height:40, width:40}} htmlColor='white'/></IconButton>
          <Divider />
          <List component="nav">
          <img height="auto" width="100%" source="https://helpdesksuites.com/wp-content/themes/manual-child/EmployeeHandbookCreator/assets/css/img/HDLogo.png"/>

          <React.Fragment>

    <ListItemButton onClick={()=> setMainView(<RecentReviews SetupReview={()=>{setMainView(<Home/>)}}/>)} sx={{fontSize:'16px'}}  divider>
      <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <DashboardIcon sx={{color:'white'}} />
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white'}}><b>Dashboard</b></span>} />
    </ListItemButton>
    <ListItemButton onClick={()=> setMainView(<Employees/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <GroupIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white'}}><b>Employees</b></span>}  />
    </ListItemButton>
    <ListItemButton onClick={()=> setMainView(<Home SetupReview={()=>{setMainView(<Home/>)}}/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <ArticleIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white'}}><b>Evaluation Manager</b></span>} />
    </ListItemButton>
    <ListItemButton onClick={()=> setMainView(<CoachingPlan/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <SportsIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white', fontFamily:'Roboto","Helvetica","Arial",sans-serif'}}><b>Coaching Plan</b></span>} />
    </ListItemButton>

    <ListItemButton onClick={()=> setMainView(<PeformanceImprovementPlan/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <ArticleIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white', fontFamily:'Roboto","Helvetica","Arial",sans-serif'}}><b>Performance Improvement Plan</b></span>} />
    </ListItemButton>

 
    <ListItemButton onClick={()=> setMainView(<EvaluationCalendar SetupReview={()=>{setMainView(<Home/>)}}/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <CalendarMonthIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText  primary={<span style={{fontSize:'14px', color:'white', fontFamily:'Roboto","Helvetica","Arial",sans-serif'}}><b>Evaluation Calendar</b></span>} />
    </ListItemButton>
    <ListItemButton onClick={()=> setMainView(<Reports/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <SummarizeIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white', fontFamily:'Roboto","Helvetica","Arial",sans-serif'}}><b>Reports</b></span>} />
    </ListItemButton>

    {/* <ListItemButton disabled onClick={()=> setMainView(<EvaluationBuilder/>)} divider>
    <ListItemIcon sx={{minWidth: open? '40px': '60px'}}>
        <HandymanIcon sx={{color:'white'}}/>
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px', color:'white', fontFamily:'Roboto","Helvetica","Arial",sans-serif'}}><b>Evaluation Builder</b><br></br><Typography variant='caption'><b>Coming soon</b></Typography></span>} />
    </ListItemButton> */}
    
  </React.Fragment>
           
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                {/* <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                > */}


                  {mainView}

                    {/* <Home/> */}

                    
                 
                {/* </Paper> */}
            
              {/* Recent Deposits */}
             
              {/* Recent Orders */}
           
          
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Modal size='lg'  style={{zIndex:1500}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Profile Information</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
      <Box sx={{display:!tokenData.subaccount ? 'none': 'block', textAlign:'end'}}><Typography variant='button'><b>Secondary Admin Account</b></Typography></Box>
        <Form.Group className="mb-3">
        <Form.Label><b>Account Holder Name</b></Form.Label>
        <Form.Control placeholder={data.name} disabled />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label><b>Account Holder Email</b></Form.Label>
        <Form.Control placeholder={data.email} disabled />

      </Form.Group>

      <div style={{display:tokenData.subaccount ? 'block': 'none' }}>
<hr></hr>
      <Form.Group  className="mb-3">
        <Form.Label><b>Secondary Admin Name</b></Form.Label>
        <Form.Control placeholder={tokenData.subaccount ? `${tokenData.subdata.firstName} ${tokenData.subdata.lastName}` : ''} disabled />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label><b>Secondary Admin Email</b></Form.Label>
        <Form.Control placeholder={tokenData.subaccount ? tokenData.subdata.email: ''} disabled />

      </Form.Group>
      </div>
      <div style={{display:!tokenData.subaccount ? 'block': 'none'}}>
      <hr></hr>
   
      <Form.Group  className="mb-3">
        <Form.Label><b>Reply-To Email</b></Form.Label>
        
        <InputGroup>
        <Form.Control
          placeholder={replyToPlaceholder}
          disabled={enableEmailInput}
          onChange={(e)=>setEditEmail(e.target.value)}
          aria-label="Recipient's username with two button addons"
        />

      
        <Button sx={{backgroundColor:'#52C47B', color:'white', fontWeight:'bold'}} onClick={()=>setEnableEmailInput(false)} variant="contained">Edit</Button>
        <Button sx={{backgroundColor:'#0285CD', color:'white', fontWeight:'bold'}} variant="contained" onClick={()=>{
       
          console.log(editEmail)
          axios.post(API+'edit-reply-to-email',{id:data.id, email:editEmail}).then((response)=>{
            console.log(response.data)
          return response.data
          }).then((response)=>{
            if(response === 'email updated'){
              setEnableEmailInput(true)
              setReplyToPlaceholder(editEmail)
              window.alert('New Reply-To email has been set.')
            }
          })
          }}>Save</Button>
      </InputGroup>

        <Form.Text className="text-muted">
          This is the Reply-to email that will show when the review emails are sent.
        </Form.Text>
      </Form.Group>
      </div>
      <Form.Group style={{display:!tokenData.subaccount ? 'block': 'none'}}>
        <hr></hr>
        
        <Form.Label><b>Admin Access</b></Form.Label>
        <br></br>
        <Typography variant='subtitle2'>You can up to {mainData[0].subaccounts ? 4 - mainData[0].subaccounts?.length : 0} more subadmin accounts to this one.</Typography>
        <Table component={Paper}>
        <TableHead>
          <TableRow>

            <TableCell align="left"><b>Name</b></TableCell>
            <TableCell align="left"><b>Email</b></TableCell>
            <TableCell align="left"><b>Actions</b></TableCell>
            
          </TableRow>
        </TableHead>

        <TableBody>
          {mainData[0].subaccounts?.map((i)=>{
            return(
              <TableRow>
              <TableCell align="left"><b>{i.firstName} {i.lastName}</b></TableCell>
               <TableCell align="left"><b>{i.email}</b></TableCell>
               <TableCell align="left">
                <ToolTipButton
                onClick={async ()=>{
                  let object = {
                    userId: data.id,
                    subId: i.id,
                    senderEmail: data.email,
                    senderName: data.name,
                    email:i.email,
                    firstName:i.firstName
                  }
                  console.log(object)
                let resp = await axios.post(API+'resend-password-email',{object})

                if(resp === 'error'){
                  window.alert('Something went wrong. Please try again.')
                } else {
                  window.alert('Email Sent.')
                  setUpdate(!update)

                }
                }}
                Title="Send Reset Password Email"
                Icon={<PasswordIcon htmlColor='#52C47B'/>}
                />
                <ToolTipButton
                onClick={()=> copyLink(data.id, i.id)}
                Title="Copy Reset Password Link"
                Icon={<ContentCopyIcon htmlColor='dodgerBlue'/>}
                />

                <ToolTipButton
                onClick={async ()=>{
                  let resp = await axios.post(API+'delete-sub-account',{userId:data.id, subId: i.id})

                  if(resp === 'error'){
                    window.alert('Something went wrong. Please try again.')
                  } else {
                    window.alert('Secondary Admin Removed!')
                    setUpdate(!update)
                  }
                }}
                Title="Delete Admin"
                Icon={<DeleteIcon htmlColor='tomato'/>}
                />

         
               </TableCell>
               </TableRow>
            )
          })}
         
        </TableBody>
        </Table>
<Box className='mt-2' sx={{textAlign:'end'}}>
  <IconButton onClick={()=>{
    console.log(mainData[0])
      if(mainData[0].subaccounts.length > 3){
        window.alert('You have reached your limit of secondary admins.')
      } else {
    setSubOpen(true)
   } }} sx={{backgroundColor:'#52C47B'}}><AddIcon htmlColor='white' /></IconButton>

</Box>
        </Form.Group>
        <hr></hr>
      <Box><Button style={{backgroundColor:'#52C47B', color:'white'}} onClick={()=>{
        
        Cookies.remove('user', { path: '/', domain: '.helpdeskforhr.com' })
        Cookies.remove('user')
         setTimeout(()=>{
          sessionStorage.clear();
          window.location.reload()
        },500)
      }}  variant='success'><b>Logout</b></Button><Button onClick={async ()=>{
        const conf = window.confirm('This will delete all of your data in this application. There will be no way to restore the data. Are you sure you would like to do this?')
      if(conf){
        axios.post(API+'delete-user',{id:data.id}).then((response)=>{
          console.log(response.data)
        return response.data
        }).then((response)=>{
          if(response === 'deleted'){
            Cookies.remove('user')
            Cookies.remove('user', { path: '/', domain: '.helpdeskforhr.com' })
            sessionStorage.clear();
       
            window.location.reload()

          }
        })
       
    }
      }} style={{float:'right',backgroundColor:'tomato', color:"white", display:!tokenData.subaccount ? 'block': 'none'}} ><b>Delete All Data</b></Button></Box>
    </Modal.Body>
      
      </Modal>
      <Modal style={{zIndex:'100001', top:'20%'}} show={subOpen} onHide={()=>setSubOpen(false)}>
        <Modal.Header closeButton>
        <Modal.Title>Add Secondary Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Form.Group>
          <Form.Label><b>First Name</b></Form.Label>
         <Form.Control value={subFirstName} onChange={(e)=>setSubFirstName(e.target.value)}/>
         </Form.Group>

         <Form.Group className='mt-2'>
          <Form.Label><b>Last Name</b></Form.Label>
          <Form.Control value={subLastName} onChange={(e)=>setSubLastName(e.target.value)}/>
         </Form.Group>


         <Form.Group>
          <Form.Label className='mt-2'><b>Email</b></Form.Label>
          <Form.Control value={subEmail} onChange={(e)=>setSubEmail(e.target.value)}/>
         </Form.Group>
         <Form.Group className='mt-5' style={{display:'flex', flexDirection:'row-reverse'}}>
         <Form.Label >Send Reset Password Email</Form.Label> 
         <Form.Check checked={sendEmail} onChange={()=>setSendEmail(!sendEmail)} style={{marginRight:5}}/>
         </Form.Group>
         <Typography variant='caption'><b>As an alternative, you can copy the reset password link in the secondary admin list and sent it to the person.</b></Typography>

         <Box sx={{textAlign:'end'}}>
          <Button
          onClick={()=>{
            let object = 
            {
              id:uuid(),
              adminEmail: data.email,
              email:subEmail.toLowerCase(),
              password: undefined,
              firstName:subFirstName,
              lastName:subLastName
            }
           let linkObject = {
            senderName:data.name,
            senderId: data.id,
           }
            console.log(sendEmail)
            console.log(object)

            AddAdmin(object,sendEmail,linkObject)
          }} variant='contained'>Add Secondary Admin <Spinner style={{marginLeft:10, display:adminSpinner? 'block':'none'}} size='sm'/></Button>
         </Box>
        </Modal.Body>

      </Modal>

      <Offcanvas  style={{zIndex:1000000}} placement={'end'} show={showGS} onHide={handleCloseGS}>
        <Offcanvas.Header >
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <GettingStarted CloseBtn={handleCloseGS}/>
        </Offcanvas.Body>
      </Offcanvas>
    </ThemeProvider>
  );
}

}

export default function Dashboard() {
  return <DashboardContent />;
}