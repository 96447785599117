import { Button, Grid, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Form, Modal, OverlayTrigger } from "react-bootstrap";
import CP from "../../components/CP";
import axios from "axios";
import { API } from "../../Controllers/API";
import { formatDate } from "../../classes/global-functions";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CancelIcon from '@mui/icons-material/Cancel';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ToolTipButton from "../../components/TooltipButton";
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';

export const CoachingPlan = ()=>{
  const token = JSON.parse(sessionStorage.getItem('token'))
  const data = JSON.parse(sessionStorage.getItem('profile-data'))
  const navigate = useNavigate();
const [showDrafts, setShowDrafts] = useState(false);
const [filter,setFilter] = useState('');
const [renderData, setRenderData] = useState([])
const [update, setUpdate] = useState(false);
const [loading,setLoading] = useState(true)
const [show, setShow] = useState(false)
    const handleShow = ()=>{
        setShow(true)
    }

    const handleClose = ()=>{
        setShow(false)
    }

    const copyLink = async (a)=>{
  
      let localLink =`http://localhost:3000/#/pip-sign?a=${data.id}&b=${a}`
      let link = `http://app.performanceevaluationcenter.com/#/cp-sign?a=${data.id}&b=${a}`
      await navigator.clipboard.writeText(link)
      window.alert('Copied Link to clipboard. Now you can use this link to personally email the reviewer their link to complete the review.')
      }
    

      const DeleteBtn = (a,b)=>{
        console.log(a)
        console.log(b)
        let message = window.confirm("This will delete this review and any progress. Are you sure you would like to do this?")
      
      
        if(message){
    
          axios.post(API+'delete-cp', {id:a, reviewId:b}).then((response)=>{
      return response
        }).then((response)=>{
         console.log(response)
         setUpdate(!update)
        })
      }
    }

    useEffect(()=>{
      axios.post(API,{email:data.email}).then((response)=>{
        return response
        
     
           }).then((response)=>{
            console.log(response.data[0])
             setRenderData(response.data[0])
             setLoading(false)
           })
    },[update])
if(loading){
 return <p>loading...</p>
} else {
  
    return (
        <Box>
            <Grid sx={{marginTop:1, marginBottom:2}} container spacing={2} justifyContent="flex-end" alignItems="center">
     
      <Grid sx={{textAlign:'end'}} item xs={6}>
        <Button
          style={{ backgroundColor: '#52C47B', fontWeight: 'bold' }}
          onClick={() => {
            handleShow();
          }}
          variant="contained"
        >
          Start Coaching Plan
        </Button>
      </Grid>
    </Grid>

    <Box style={{textAlign:'start', paddingBottom:'20px'}}>

<Box sx={{marginBottom:10, display:'inline'}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="Search by First or Last Name..." style={{width:'33%', display:'inline'}}/>

</Box>
</Box>

<TableContainer component={Paper}>
  <Table>
<TableHead  sx={{width:'100%'}}>
          <TableRow>
         
            <TableCell><b>Employee Name</b></TableCell>
            <TableCell><b>Plan Created On</b></TableCell>
            <TableCell align='left'><b>Signature Status</b></TableCell>
            <TableCell align='center'><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {renderData?.cps.filter(o=> o.name.name.toLowerCase().includes(filter.toLowerCase())).map(i=>{
          let link = `http://app.performanceevaluationcenter.com/#/cp-view?a=${data.id}&b=${i.reviewId}`
          let status = ''
          let statusColor = ''
          if(i.sendToManager === false  && i.employeeSignature.signature !== '') {
            status = 'Signed'
            statusColor = 'green'
          } 

          if(i.sendToManager === false  && i.employeeSignature.signature === '') {
            status = 'Pending Signature..'
            statusColor = 'orange'
          }

          if(i.sendToManager === true  && i.employeeSignature.signature !== '' && i.managerSignature.signature !== '') {
            status = 'Signed'
            statusColor = 'green'
          }

          if(i.sendToManager === true  && i.employeeSignature.signature === '' && i.managerSignature.signature !== '') {
            status = 'Employee Has Not Signed'
            statusColor = 'orange'
          }

          if(i.sendToManager === true  && i.employeeSignature.signature !== '' && i.managerSignature.signature === '') {
            status = 'Manager Has Not Signed'
            statusColor = 'orange'
          }

          if(i.sendToManager === true  && i.employeeSignature.signature === '' && i.managerSignature.signature === '') {
            status = 'Pending Signatures..'
            statusColor = 'orange'
          }

          


          return(<TableRow>
            <TableCell>{i.name.name}</TableCell>
            <TableCell>{formatDate(i.date)}</TableCell>
            <TableCell  align='left' style={{color:(i.status === 'Signed') ? '#199A71':'orange', cursor:(i.status === 'Not Yet Sent To Be Signed') ? 'cursor':''}}>
                 <Typography sx={{color:statusColor}}><b>{status}</b></Typography>
           </TableCell>
            <TableCell align='center'>
            <ToolTipButton
            Title={`Plan Details`}
            Icon={<InfoIcon htmlColor='#199A71'/>}
            onClick={()=>{ navigate('/cp-details', { replace: true, state:{i} })}}
            />
          
         
        
            <a target="_blank" href={link}>
            <ToolTipButton
            Title={`View Document`}
            Icon={<PlagiarismIcon htmlColor='#199A71'/>}
            />
            </a>
            <ToolTipButton
            Title={`Delete`}
            Icon={<CancelIcon htmlColor='tomato'/>}
            onClick={()=>DeleteBtn(data.id,i.reviewId)}
            />
            
             
              </TableCell>
          </TableRow>)
         })}
        </TableBody>
        </Table>
    </TableContainer>


    <Modal style={{zIndex:'10000'}} size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>COACHING PLAN SETUP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <CP
        Submit={()=>{
          handleClose();
          setUpdate(!update)
        }}
        />
        </Modal.Body>
       
      </Modal>
        </Box>
    )
}

}