import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton sx={{fontSize:'16px'}}  divider>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px'}}>Home</span>} />
    </ListItemButton>
    <ListItemButton divider>
      <ListItemIcon >
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px'}}>Forms and Documents</span>}  />
    </ListItemButton>
    <ListItemButton divider>
    <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary={<span style={{fontSize:'14px'}}>Policies and Procedures</span>} />
    </ListItemButton>
    
  </React.Fragment>
);
