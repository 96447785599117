import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, CircularProgress, Toolbar, Grid} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/pec-badge.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../classes/global-functions";
import pipInstructions from '../../json/pip-instructions.json'
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Controllers/API";
import { useState } from "react";
function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const CpSign = (props)=>{
const data = JSON.parse(sessionStorage.getItem('profile-data'))
const [spinner,setSpinner] = useState(false)
const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
const [loading,setLoading] = useState(true)
const [completed, setCompleted] = useState(false)
const handleSignature = (e)=>{reviewObj.signature = e}
const [managerEmployee, setManagerEmployee] = useState(null)
const handleSignatureDate = (e)=>{reviewObj.signatureDate = e}
  const[reviewObj, setReviewObj] = useState(
    {
        reviewId:'',
        name: '',
        position: '',
        date: '',
        shortTermGoals: [],
        longTermGoals: [],
        skillsTraining: '',
        additionalBudget: '',
        peerSupport: '',
        other: '',
        specificActionSteps: [],
        initialCheckDate: '',
        midwayCheckDate: '',
        finalCheckDate: '',
        employeeSignature: '',
        managerSignature: '',
        ccs:[],
        status:'',
        sendToManager: false,
        manager:{name:'', email:''},
        reviewFrom:{id:'', email:''},

    }
)
  const location = useLocation();
  function getQueryVariable(variable) {
      var query = location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
  }
 
  const a  = getQueryVariable('a')
  const b  = getQueryVariable('b')


  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUrl = window.location.href;
        const response = await axios.post(API + 'get-cp', { id: a, cpId: b });
        console.log('Full Response:', response);
        console.log('Data from Response:', response.data[0]);
        setReviewObj(response.data[0]);
      if(currentUrl.toLowerCase().includes('manager')){
        setManagerEmployee('manager')
      } else {
        setManagerEmployee('employee')
      }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [a, b]); // Trigger the effect when 'a' or 'b' changes

  useEffect(() => {
    console.log('Updated reviewObj:', reviewObj);
  }, [reviewObj]);

  const updateManagerSignature = async ( managerSignature) => {
    try {
      const response = await axios.post(API+ 'update-manager-signature', {
        userId: a,
       coachingPlanId: b,
        managerSignature: managerSignature,
       coachingPlan:reviewObj
      });
  
      // Handle the response as needed
      console.log('Manager signature updated:', response.data);
      window.location.reload()

    } catch (error) {
      console.error('Error updating manager signature:', error);
      
      window.alert('Something went wrong please try again.')

    }
  };

  const updateEmployeeSignature = async () => {
    try {
      const response = await axios.post(API+ 'update-employee-signature', {
       userId: a,
       coachingPlanId: b,
       employeeSignature: signature,
       coachingPlan:reviewObj
      });
      window.location.reload()
      // Handle the response as needed
      console.log('Employee signature updated:', response.data);
    } catch (error) {
      console.error('Error updating employee signature:', error);
      window.alert('Something went wrong please try again.')
      // Handle the error
    }
  };

     if(loading){
      return(
      <Box sx={{textAlign:'center', marginTop:20}}>
      <CircularProgress/>
      <Typography><b>Loading</b></Typography>
    </Box>
      )
     } else {

    
      if((managerEmployee === 'manager' && reviewObj.managerSignature.signature === '') || (managerEmployee === 'employee' && reviewObj.employeeSignature.signature === '')){
        
    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{window.close()}}>Close</Button>
          </Toolbar>



       </AppBar>

       <br></br>
        <Paper className="demo-paper" sx={{ textAlign: 'center', padding: '3%', maxWidth: '95%', marginTop: '70px', justifyContent: 'center'}}>
           <Typography variant="h5" sx={{marginBottom:'25px'}}><b>EMPLOYEE COACHING PLAN</b></Typography>
     
               <hr></hr>
<Box sx={{textAlign:'start'}}>
               </Box>

<Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>EMPLOYEE:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{reviewObj.name.name}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>DATE CREATED:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{reviewObj.date}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>POSITION:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{reviewObj.position}</Form.Label>
        </Form.Group>
      </Grid>
    </Grid>
            <hr></hr>
            <Box style={{ textAlign: 'start' }}>
      <Typography variant="button" sx={{ textAlign: 'right', fontSize: 16 }}>
        <b>GOALS FOR THIS PERIOD:</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid  item xs={12} sm={6} md={6}>
        <hr/>
          <Form.Group>
            <Form.Label>
              <Typography variant="button" sx={{ textAlign: 'right' }}>
                <b>Short-Term Goals:</b>
              </Typography>
            </Form.Label>
          </Form.Group>
          <hr/>
          <Box sx={{ padding:1, maxHeight: 500, overflowY: 'scroll' }}>
            {reviewObj.shortTermGoals.map((i, index) => (
              <Box key={index}>
                <Typography>
                  <b>{`GOAL ${index + 1}:`} {i.title}</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: i.details }} />
                <hr/>
              </Box>
              
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
        <hr/>
          <Form.Group>
            <Form.Label>
              <Typography variant="button" sx={{ textAlign: 'right' }}>
                <b>Long-Term Goals:</b>
              </Typography>
            </Form.Label>
          </Form.Group>
          <hr/>
          <Box sx={{ maxHeight: 500, overflowY: 'scroll' }}>
            {reviewObj.longTermGoals.map((i, index) => (
              <Box key={index}>
                <Typography>
                <b>{`GOAL ${index + 1}:`} {i.title}</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: i.details }} />
                <hr/>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
            <hr></hr>
<Box sx={{textAlign:'start'}}>
<TableContainer>
<Table>
<TableHead  sx={{width:'100%'}}>
               
                  <TableCell sx={{width:125}}><b>What To Do</b></TableCell>
                  <TableCell sx={{width:125}}><b>By When</b></TableCell>
                  <TableCell><b>Resources Needed</b></TableCell>
                  <TableCell><b>Potential Obstacles</b></TableCell>
                 
         
              </TableHead>
              <TableBody>
                {reviewObj.specificActionSteps.map((step, index) => (
                  <TableRow key={index}>
                    <TableCell>{step.whatToDo}</TableCell>
                    <TableCell>{step.byWhen}</TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.resourcesNeeded}}/></TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.potentialObstacles}}/></TableCell>
                  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
</Box>

<Box sx={{textAlign:'start'}}>
   <hr/>
<Typography variant="button" sx={{ fontSize: 16 }}>
        <b>SUPPORT REQUIRED:</b>
      </Typography>   
      <hr/>   

      <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Skills Training:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.skillsTraining}}/></Box>
            <br></br>
            {/* <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Additional Budget:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.additionalBudget}}/></Box>
            <br></br> */}
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Peer Support:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.peerSupport}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Other:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.other}}/></Box>
           
    </Box>  
       
    <Box sx={{textAlign:'start'}}> 
  
    <hr/>    
           <Typography variant="button"><b>Checkpoint Schedule</b></Typography>
           <hr/>
           <TableContainer>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
        
          <TableCell><b>Checkpoint Date</b></TableCell>
          <TableCell><b>Follow-up Type</b></TableCell>
          <TableCell><b>Session Details</b></TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
        {
          reviewObj.checkpoints.map(i=>{
          let date = new Date(i.dateOfNextReview)
          let link = `https://app.performanceevaluationcenter.com/#/cp-session?a=${a}&b=${b}&c=${i.id}`

            return(
              <TableRow>
              <TableCell>{formatDate(date)}</TableCell>
              <TableCell>{i.reviewType}</TableCell>
              <TableCell>{i.status ? <div dangerouslySetInnerHTML={{__html: `<a href='${link}' target='_blank'>View Document</a>`}}/> : 'Not Available' }</TableCell>
            </TableRow>
            )
          })
        }
            </TableBody>
      </Table>
    </TableContainer> 
    
<hr/>
    <Box>
              
    <Typography variant="button"><b>Signatures</b></Typography>
    <hr></hr>  
            <Typography variant="subtitle2">I have read, understand, and agree to abide by the plan set forth above.</Typography>
           
            <Row className="mb-3">
           <div style={{display: managerEmployee === 'employee'? 'flex':'none'}}>
            
        <Form.Group  as={Col} >
        <Typography variant="button"><b>Employee Signature:</b></Typography>
          <br></br>
          <Form.Control 
          value={signature}
          onChange={(e)=>{setSignature(e.target.value); console.log(e.target.value)}} 
          style={{fontFamily:'Signature', fontSize:'16px', height:38, paddingLeft:15}} type="text" />
          <Typography variant="button"><b>{reviewObj.name.name}</b></Typography>
        </Form.Group>

        <Form.Group style={{marginLeft:5}} as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Control 
          value={reviewObj.employeeSignature.date === '' ? formatDate(new Date) : reviewObj.employeeSignature.date}
          disabled
        //   onChange={(e)=>{reviewObj.signatureDate =e.target.value; console.log(e.target.value)}} 
         />
        </Form.Group>
        </div>
      <div style={{display: managerEmployee === 'manager'? 'flex':'none'}}>
        <hr/>
        <Form.Group as={Col} >
          <Typography variant="button"><b>Manager Signature:</b></Typography>
          <br></br>
          <Form.Control 
          onChange={(e)=>{setSignature(e.target.value); console.log(e.target.value)}} 
        
          style={{fontFamily:'Signature', fontSize:'16px', height:38}} type="text" />
          <Typography variant="button"><b>{reviewObj.manager.name}</b></Typography>
        </Form.Group>
       
        <Form.Group style={{marginLeft:5}} as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Control 
          value={reviewObj.managerSignature.date === '' ? formatDate(new Date) : reviewObj.managerSignature.date}
            onChange={(e)=>{reviewObj.signatureDate =e.target.value; console.log(e.target.value)}} 
          />
        </Form.Group>

        </div>
      </Row>
      <hr/>
      <Box sx={{textAlign:'end', display: managerEmployee === 'employee'? 'block':'none'}}>
        <Button variant="contained" 
        onClick={updateEmployeeSignature}
        ><b>Send Back</b>{' '}<Spinner style={{display: spinner ? '':'none'}} variant="light"/></Button>
      </Box>

      <Box sx={{textAlign:'end', display: managerEmployee === 'manager'? 'block':'none'}}>
        <Button variant="contained" 
        onClick={()=>updateManagerSignature(signature)}
        >Update and Send Back{' '}<Spinner style={{display: spinner ? '':'none'}} variant="light"/></Button>
      </Box>
    </Box>
      </Box>  
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
  }  else{
    return(
        <div style={{backgroundColor:'#D5EDE3', height:'100vh'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center'}}>
       <AppBar sx={{marginBottom:'50px'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
          </Toolbar>
       </AppBar>
       <div className="above-btn">
       
    <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px', justifyContent:'center'}}>
        <Typography className="complete-heading" sx={{fontSize:'2vw'}} variant="button">Thank you for completing the evalution! </Typography><br></br><p className="complete-p"  style={{fontSize:'20px',paddingRight:'40px',paddingLeft:'40px'}}>This has been sent back to the person that initiated this process. You will be emailed the completed plan. If you have any questions you can reach out to them for assistance.</p><p>You may now close this window.</p>
        </Paper>
        </div>
        </Box>
        <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
        </div>)
}
     }
  
}