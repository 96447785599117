import { AppBar, CircularProgress, Divider, Paper, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useEffect} from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {Button} from "@mui/material";
import { EPEReview } from "../components/review-screens/epe";
import { ThreeSixtyDemo } from "../components/demo-screens/threesixty-demo";
import { UEDemo } from "../components/demo-screens/ue-demo";
import { EPEDemo } from "../components/demo-screens/EPEDemo";
import { API } from "../Controllers/API";
import axios from "axios";
import { UpdateInput } from "../classes/review-functions";
import { useLocation } from "react-router-dom";
import '../assets/css/review-screen.css'
import { LockOpen } from "@mui/icons-material";
import { ThreeSixtyReview } from "../components/review-screens/360";
import { UEReview } from "../components/review-screens/ue";
import logo from '../assets/css/images/PECLogo.png'
import hdLogo from '../assets/css/pec-badge.png'
import { Link } from "react-router-dom";
import { PIPReview } from "../components/review-screens/pip";

function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const PIPSign = ()=>{
    const location = useLocation();
    function getQueryVariable(variable) {
        var query = location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }
   
    const a  = getQueryVariable('a')
    const b  = getQueryVariable('b')
   
    const queryParameters = new URLSearchParams(window.location.search)
    let dataObject = {reviewData:{
        goalDisplay:false
    }}
    const[reviewObj, setReviewObj] = useState({name:''})
const[showEmployeeId, setEmployeeId] = useState('block')
const[goalDisplay, setGoalDisplay] = useState(false)
const [loader,setLoader] = useState(false)
const [loading,setLoading] = useState(false)
const [completed, setCompleted] = useState(false)
const [ questions, setQuestions] = useState([])
const [partTwo, setPartTwo] = useState('')
const [partFourA, setPartFourA] = useState('')
const [partFourB, setPartFourB] = useState('')
const [partThree, setPartThree] = useState('')
const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')


const EditQuestionArray = (event,a,b)=>{
    let val = event.target.value;

    console.log(val)
console.log(a)
console.log(b)
console.log(questions)
const index = questions.findIndex(object => {
    return object.catIndex === b;
  }); 

  console.log(index)

  const questionIndex = questions[index].questions.findIndex(object => {
    return object.id === a;
  }); 

  questions[index].questions[questionIndex].answer = val

  console.log(questions)
}
const RetrieveData =  ()=>{
   axios.post(API+'get-pip', {id:a, pipId:b}, (err, response)=>{
    
    return response }).then((resp)=>{
      setReviewObj(resp.data[0])
    }).then(()=>{
      setLoading(false)
      console.log(reviewObj)
    })
    
   
   
   
   

}



const[topSection, setTopSection] = useState('')
    useEffect(()=>{
   
      setLoading(true)


RetrieveData()

setReviewObj(RetrieveData())


    }, [])
   

    async function downloadDocx(params) {
        // const converted = htmlDocx.asBlob(htmlString);
        // saveAs(converted, 'test.docx');
      }
      if(!loading){
      if(!completed){
        
          


        
                <PIPReview
                RevieweeName={reviewObj.reviewee.name}
                Department={reviewObj.department}
                Supervisor={reviewObj.supervisor}
                Date={reviewObj.dateCreated}
                AreasOfConcern={reviewObj.areasOfConcern}
                Observations={reviewObj.observations}
                Resources={reviewObj.resources}
                Expectations={reviewObj.expectations}
                GoalList={reviewObj.goalsArray}
                
                />
            

      }  else{
        return(
            <div style={{backgroundColor:'#D5EDE3', height:'100vh'}}>
            <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center'}}>
           <AppBar sx={{marginBottom:'50px'}}>
           <Toolbar
                sx={{
                  backgroundColor:'#1A9A71',
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
    
    <img height='auto' width={250} src={logo}/>
              </Toolbar>
           </AppBar>
           <div className="above-btn">
           
        <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px', justifyContent:'center'}}>
            <Typography className="complete-heading" sx={{fontSize:'2vw'}} variant="button">Thank you for completing the evalution! </Typography><br></br><p className="complete-p"  style={{fontSize:'20px',paddingRight:'40px',paddingLeft:'40px'}}>The completed evaluation has been sent back to the person that initiated this process. If you have any questions or would like a copy of the completed review you can reach out to them for assistance.</p><p>You may now close this window.</p>
            </Paper>
            </div>
            </Box>
            <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
            </div>)
    }
      } else{
        <Box sx={{textAlign:'center', marginTop:20}}>
      <CircularProgress/>
      <Typography><b>Loading</b></Typography>
    </Box>
      }
   
}