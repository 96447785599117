import { Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import EmployeeTransferList from "../subcomponents/EmployeeTransferList";
import Accordion from 'react-bootstrap/Accordion';
import EPE from "../subcomponents/review-screens/epe";
import SELF from "../subcomponents/review-screens/self";
import ThreeSixty from "../subcomponents/review-screens/360";
import UE from "../subcomponents/review-screens/ue";
import PIP from "../subcomponents/review-screens/pip";
import data from '../json/employees.json'
import Loader from "./Loader";
const ReviewSetup = (props)=>{
const [reviewType, setReviewType] = useState(null)
    const handleReviewSelect = (e)=>{

      let val = e.target.value;
      if(val === 'epe'){
        setReviewType(<EPE OnClickSend={props.OnClickSend}/>)
      }

      if(val === '360'){
        setReviewType(<ThreeSixty OnClickSend={props.OnClickSend}/>)
      }

      if(val === 'ue'){
        setReviewType(<UE OnClickSend={props.OnClickSend}/>)
      }

     

      if(val === 'self'){
        setReviewType(<SELF OnClickSend={props.OnClickSend}/>)
      }

      if(val === 'null'){
        setReviewType(null)
      }
    }
    return(<div style={{overflow:'scroll'}}>
        <Form.Group className="mb-3">
        <Form.Label>Please Select the type of evaluation you would like to conduct:</Form.Label>
        <Form.Select onChange={handleReviewSelect} >
          <option value='null'>Select and Evaluation Type</option>
          <option value='epe'>Employee Performance Evaluation</option>
          <option value='self'>Employee Self Evaluation</option>
          <option value='360'>360 Evaluation</option>
          <option value='ue'>Upward Evaluation</option>
      
        </Form.Select>
      </Form.Group>
      
    

      <Form.Group className="mb-3">
      {reviewType}
      
      </Form.Group>
    </div>)
}

export default ReviewSetup;