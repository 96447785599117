import React from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, CircularProgress, Toolbar} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/pec-badge.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../classes/global-functions";
import pipInstructions from '../../json/pip-instructions.json'
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Controllers/API";
import { useState } from "react";
function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const PIPReview = (props)=>{
  const [spinner,setSpinner] = useState(false)
  const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
const [loading,setLoading] = useState(false)
const [completed, setCompleted] = useState(false)
const handleSignature = (e)=>{reviewObj.signature = e}
const handleSignatureDate = (e)=>{reviewObj.signatureDate = e}
  const[reviewObj, setReviewObj] = useState(
    {
  reviewee:  {name:''},
  reviewType: 'Performance Improvement Plan',
  goalsArray: [],
  dateCreated: '',
  department:'',
  supervisor:'',
  reviewPeriod:'',
  areasOfConcern:'',
  observations:'',
  resources:'',
  expectations:'',
  ccGroup:[],
  checkpoints:[],
  status:'Pending Signature..',
  signature:signature,
  signatureDate:signatureDate

    }
)
  const location = useLocation();
  function getQueryVariable(variable) {
      var query = location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
  }
 
  const a  = getQueryVariable('a')
  const b  = getQueryVariable('b')

  const Data = async()=>{
    let response = await axios.post(API+'get-pip', {id:a, pipId:b}, (err, response)=>{
       return response.data[0]
    })
    console.log(response.data[0])
   setReviewObj(response.data[0]);
   if(reviewObj.status === "Completed"){
    setCompleted(true)
   }
   console.log(reviewObj)
  }


     useEffect(()=>{
      Data()

     },[])


     const UpdatePip = async ()=>{
      setSpinner(true)
      reviewObj.status = "Signed"
        console.log(reviewObj)
        let response = await axios.post(API+'update-pip', {id:a, pipId:b, pipObj:reviewObj}, (err, response)=>{
          return response
       })
       console.log(response.data)
           if(response.data == 'Complete'){
            console.log('Complete')
            setCompleted(true)
            setSpinner(false)
           }


     }

     if(!loading){
      if(!completed){
    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{window.close()}}>Close</Button>
          </Toolbar>



       </AppBar>

       <br></br>
        <Paper className="demo-paper" sx={{textAlign:'center', padding:'50px', maxWidth:'950px',marginTop:'70px',justifyContent:'center'}}>
           <Typography variant="h5" sx={{marginBottom:'25px'}}><b>PERFORMANCE IMPROVEMENT PLAN</b></Typography>
           <Box sx={{textAlign:'start'}}>
           <Typography variant="button" sx={{marginBottom:'25px'}}><b>How to Conduct a Performance Improvement Plan (PIP)</b></Typography>
           <Typography variant="p"><b>Instructions:</b> The Performance Improvement Plan (PIP) should be used when an employee receives a low to unsatisfactory rating on their annual performance evaluation. It may also be used any time an employee’s performance or conduct fails to meet the supervisor’s expectations.</Typography>
            <br></br>
            <br></br>
          {pipInstructions.map((i,index)=>{
           
            return (
              <div>
            <Typography variant="button"><b>{index + 1}.	{i.title}</b></Typography>
            <ul>
              {i.details.map(o=>{
              return(
                <li>{o}</li>
              )})}
              </ul>
            </div>
            )
          })}
            
               </Box>
               <br></br>
               <hr></hr>
<Box sx={{textAlign:'start'}}>
               <Typography variant="button" sx={{marginBottom:'35px'}}><b>EMPLOYEE INFORMATION</b></Typography>
               </Box>
<br></br>
        <Box sx={{textAlign:'start',display:'flex', flexDirection:'row', flexWrap: 'wrap'}}>

            <Form.Group style={{flex:'50%'}}>
                <Form.Label><Typography variant="button" ><b>EMPLOYEE:</b></Typography></Form.Label>
                <br></br>
                <Form.Label>{reviewObj.reviewee.name}</Form.Label>
            </Form.Group>


            <Form.Group style={{flex:'50%',  display: (reviewObj.reviewPeriod === '') ? 'none': 'block'}}>
                <Form.Label><Typography variant="button" ><b>DATE CREATED:</b></Typography></Form.Label>
                <br></br>
                <Form.Label>{reviewObj.reviewPeriod}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', display: (reviewObj.department === '') ? 'none': 'block'}}>
                <Form.Label><Typography variant="button" ><b>DEPARTMENT:</b></Typography></Form.Label>
                <br></br>
                <Form.Label>{reviewObj.department}</Form.Label>
            </Form.Group>

            <Form.Group style={{flex:'50%', display: (reviewObj.supervisor === '') ? 'none': 'block'}}>
                <Form.Label><Typography variant="button" ><b>SUPERVISOR:</b></Typography></Form.Label>
                <br></br>
                <Form.Label>{reviewObj.supervisor}</Form.Label>
            </Form.Group>


            {/* <Form.Group style={{flex:'50%', marginTop:'10px', display: (props.TimeInPosition === '') ? 'none': 'block'}}>
                <Form.Label><b>Phase:</b></Form.Label>
                <br></br>
                <Form.Label>Initial Meeting</Form.Label>
            </Form.Group> */}
            
            </Box>
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>PURPOSE:</b></Typography>
            <Typography variant="subtitle2" style={{fontSize:14, textAlign:'start'}}>The purpose of this Performance Improvement Plan (PIP) is to define serious areas of concern, gaps in your work performance, reiterate (Company’s) expectations, and allow you the opportunity to demonstrate improvement and commitment.</Typography>
            
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Areas of Concern:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{reviewObj.areasOfConcern}</Typography></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Observations, Previous Discussions, or Additional Training Provided:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{reviewObj.observations}</Typography></Box>
            </Box>
            <hr></hr>

            <Box style={{textAlign:'start'}}>

            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>STEP ONE: IMPROVEMENT GOALS</b></Typography>
            </Box>
       

            <Box>
            <Typography variant="subtitle2" style={{fontSize:14, textAlign:'start'}}>These are the goals related to areas of concern to be improved and addressed.</Typography>
              <ul>
              {reviewObj.goalsArray.map(i=>{
                return(<li>{i.goalTitle}</li>)
              })}
              </ul>
            </Box>


            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>STEP TWO: ACTIVITY GOALS</b></Typography>
            <Typography variant="subtitle2" style={{fontSize:14, textAlign:'start'}}>Listed below are activities that will help you reach each goal.</Typography>
            <hr></hr>
            {reviewObj.goalsArray.map((i,index)=>{
                return(
                    <Box>
                        <Typography sx={{fontSize:'16px'}} variant="button"><b>Goal {index + 1} Title: {i.goalTitle}</b></Typography>
                         <Row className="mb-3">
        <Form.Group as={Col} >
          <Typography variant="button">Start Date:</Typography>
          <br></br>
          <Form.Label >{i.startDate}</Form.Label>
        </Form.Group>

        <Form.Group as={Col} >
        <Typography variant="button">Projected Completion Date:</Typography>
          <br></br>
          <Form.Label >{i.compDate}</Form.Label>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group>
        <Typography variant="button">Activity:</Typography>
        <Box sx={{border:'1px solid black', minHeight:'175px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{i.activity}</Typography></Box>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group>
        <Typography variant="button">How to accomplish:</Typography>
        <Box sx={{border:'1px solid black', minHeight:'175px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{i.howTo}</Typography></Box>
        </Form.Group>
      </Row>


                        <hr></hr>


                    </Box>
                )
            })}
            
            </Box>
         
         
          
            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>STEP THREE: RESOURCES</b></Typography>
            
            </Box>
          
            <Box>
                <Typography variant="subtitle2" style={{fontSize:14, textAlign:'start'}}>Listed below are resources available to you to complete your Improvement activities (may include other people’s time or expertise, management support, training materials and activities, or time away from usual responsibilities.)</Typography>
                <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{reviewObj.resources}</Typography></Box>


            <hr></hr>
            <Box style={{textAlign:'start'}}>
            <Typography variant="button" style={{fontSize:16, textAlign:'start'}}><b>STEP FOUR: EXPECTATIONS</b></Typography>            </Box>
        

            <Typography variant="subtitle2" style={{fontSize:14, textAlign:'start'}}>The following performance standards must be accomplished to demonstrate progress towards achievement of each improvement goal.</Typography>
                <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><Typography sx={{whiteSpace:'pre-line'}} variant="p">{reviewObj.expectations}</Typography></Box>

            </Box>

            <Box sx={{marginTop:'20px'}}>
            
    </Box>
    
            </Box>
            <Box style={{textAlign:'start'}} >
            <hr></hr>
           
           <Typography variant="button"><b>Checkpoint Schedule</b></Typography>
           <hr></hr>
           <TableContainer>
      <Table sx={{ minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Activity</TableCell>
          <TableCell>Checkpoint Date</TableCell>
          <TableCell>Follow-up Type</TableCell>
          <TableCell>Progress Expected</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
        {
          reviewObj.checkpoints.map(i=>{
          let date = new Date(i.dateOfCheck)
            return(
              <TableRow>
              <TableCell>{i.checkpointGoal}</TableCell>
              <TableCell>{formatDate(date)}</TableCell>
              <TableCell>{i.checkpointFollowUpType}</TableCell>
              <TableCell>{i.checkpointProgressExpected}</TableCell>

            </TableRow>
            )
          })
        }
            </TableBody>
      </Table>
    </TableContainer>
            <hr></hr>
           
            <Typography variant="button"><b>Signature of Employee</b></Typography>
            <hr></hr>
            <Typography variant="subtitle2">I have read, understand, and agree to abide by the plan set forth above.</Typography>
            <Row className="mb-3">
        <Form.Group as={Col} >
          <Typography variant="button">Signature:</Typography>
          <br></br>
          <Form.Control onChange={(e)=>{handleSignature(e.target.value); console.log(e.target.value)}} style={{fontFamily:'Signature', fontSize:'18px'}} type="text" />
          <Typography variant="button"><b>{reviewObj.reviewee.name}</b></Typography>
        </Form.Group>

        <Form.Group as={Col} >
        <Typography variant="button">Date:</Typography>
          <br></br>
          <Form.Control onChange={(e)=>{reviewObj.signatureDate =e.target.value; console.log(e.target.value)}} type="date" />
        </Form.Group>
      </Row>
      <Box sx={{textAlign:'end'}}>
        <Button variant="contained" onClick={UpdatePip}>Send Back{' '}<Spinner style={{display: spinner ? '':'none'}} variant="light"/></Button>
      </Box>
      </Box>
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
  }  else{
    return(
        <div style={{backgroundColor:'#D5EDE3', height:'100vh'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center'}}>
       <AppBar sx={{marginBottom:'50px'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
          </Toolbar>
       </AppBar>
       <div className="above-btn">
       
    <Paper sx={{textAlign:'center', padding:'50px', maxWidth:'950px', justifyContent:'center'}}>
        <Typography className="complete-heading" sx={{fontSize:'2vw'}} variant="button">Thank you for completing the evalution! </Typography><br></br><p className="complete-p"  style={{fontSize:'20px',paddingRight:'40px',paddingLeft:'40px'}}>This has been sent back to the person that initiated this process. You will be emailed the completed plan. If you have any questions you can reach out to them for assistance.</p><p>You may now close this window.</p>
        </Paper>
        </div>
        </Box>
        <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
        </div>)
}
  } else{
    <Box sx={{textAlign:'center', marginTop:20}}>
      <CircularProgress/>
      <Typography><b>Loading</b></Typography>
    </Box>
  }
}