import React, { useState } from "react";
import { Autocomplete, TextField, Typography, Box, Paper } from "@mui/material";

const RevieweeSelect = ({ employees, handleRevieweeSelect }) => {
  // State to hold the selected reviewee
  const [selectedReviewee, setSelectedReviewee] = useState(null);

  // Sort the employees by role first, then by name
  const sortedEmployees = employees.sort((a, b) => {
    if (a.role < b.role) return -1;
    if (a.role > b.role) return 1;
    return a.name.localeCompare(b.name); // Sort alphabetically within each role
  });

  // Function to handle the selection change
  const onSelectReviewee = (event, newValue) => {
    setSelectedReviewee(newValue);
    handleRevieweeSelect(newValue);
  };

  return (
    <div className="mb-3">
      <Typography variant="body1" gutterBottom>
        Select a Person to be reviewed:
      </Typography>
      <Autocomplete
      disableClearable
        disablePortal
        options={sortedEmployees}
        groupBy={(option) => option.role} // Group by role (Manager or Employee)
        getOptionLabel={(option) => option.name || ""} // Display the name of the person
        onChange={onSelectReviewee} // Handle selection change
        renderInput={(params) => (
          <TextField {...params} label="Select Reviewee" variant="outlined" />
        )}
      />
      
      {/* Display selected reviewee details if available */}
      {selectedReviewee && (
        <Box sx={{ marginTop: 2 }}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Selected Reviewee:</Typography>
            <Typography variant="body1">
              <strong>Name:</strong> {selectedReviewee.name}
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> {selectedReviewee.email}
            </Typography>
            <Typography variant="body1">
              <strong>Role:</strong> {selectedReviewee.role}
            </Typography>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default RevieweeSelect;
