import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ToolTipButton from "./TooltipButton";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { Form } from "react-bootstrap";


export const PipDrafts = (props) =>{
    const [draftData, setDraftData] = useState(props.DraftData);
    const [filter, setFilter] = useState('')

    return (
    <Box>
    <Typography><b>PERFORMANCE IMPROVEMENT PLAN DRAFTS</b></Typography>
    <Box sx={{marginTop:2, marginBottom:2}}>
        <Button style={{backgroundColor:'#52C47B', fontWeight:'bold'}} onClick={()=>{props.ShowDrafts()}} variant="contained">Show PIP Dashboard</Button>
        </Box>

<Box sx={{marginBottom:2}}>
  <Form.Control onChange={(e)=>{setFilter(e.target.value)}} placeholder="Search by First or Last Name..." style={{width:'33%', display:'inline'}}/>

</Box>

        <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
         
            <TableCell><b>Employee Name</b></TableCell>
            <TableCell><b>Plan Started On</b></TableCell>
            
            <TableCell align='center'><b>Actions</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {draftData.filter(o=> o.reviewee.name.toLowerCase().includes(filter.toLowerCase())).map((i,index)=>{
            return (<TableRow key={index}>
                <TableCell>{i.reviewee.name}</TableCell>
                <TableCell>{i.dateCreated}</TableCell>
                <TableCell align="center">
                <ToolTipButton 
                Title={`Edit PIP`}
                Icon={<EditIcon/>}
                onClick={() => props.ClickEditPIP(i)}
            
                />
                <ToolTipButton 
                Title={`Delete PIP Draft`}
                Icon={<CancelIcon htmlColor="tomato"/>}
                onClick={()=>props.ClickDeletePip(i.reviewId)}
            
                />
                </TableCell>
            </TableRow>)
           })}
       </TableBody>
       </Table>
       </TableContainer>
    </Box>)
}