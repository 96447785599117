export const coachingPlan = (name, position, date,longTermGoals, shortTermGoals, specificActionSteps,skillsTraining, additionalBudget, peerSupport, other, checkpoints,employeeSignatureName, employeeSignatureDate, managerSignatureName, managerSignatureDate)=>{
    return (`
    <div style="font-family:Calibri,sans-serif">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:24pt">EMPLOYEE COACHING PLAN</span></strong></span></span></span></p>
   
<div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-family:Calibri,sans-serif;color:#000000;font-size:14pt;"><strong>BASIC INFORMATION:</strong></span></p>
</div>
   
    <table style="font-family:Calibri,sans-serif" cellpadding="1" cellspacing="1" style="width:100%">
        <tbody>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Name</strong>: ${name}</h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Position:&nbsp;</strong>${position}</h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Date Created:</strong> ${date}</h3>
                </td>
            </tr>
        </tbody>
    </table>

    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">GOALS FOR THIS PERIOD:</span></strong></span></span></span></p>
    </div>

    <h3><strong>Short-Term Goals:</strong></h3>
    
    ${longTermGoals}
    
    <h3><strong>Long-Term Goals:</strong></h3>
    
    ${shortTermGoals}
    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">SPECIFIC ACTION STEPS:</span></strong></span></span></span></p>
    </div>

    <table  style="width:100%">
        <tbody>
            <tr>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>&nbsp;What To Do</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>&nbsp;By When</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>&nbsp;Resources Needed</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>&nbsp;Potential Obstacles</strong></h3>
                </td>
            </tr>
            ${specificActionSteps.map(i=>{
                return(` <tr>
                <td style="font-family:Calibri,sans-serif">
                <p>${i.whatToDo}</p>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <p>${i.byWhen}</p>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <p>${i.resourcesNeeded}</p>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <p>${i.potentialObstacles}</p>
                </td>
            </tr>`)
            })}
        </tbody>
    </table>
    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">SUPPORT REQUIRED:</span></strong></span></span></span></p>
    </div>

    <h3 style="text-align:start"><strong>Skills Training</strong></h3>
    
    <div style="border:1px solid black; padding:5px">
    ${skillsTraining}
    </div>
    
    
    <h3 style="text-align:start"><strong>Additional Budget</strong></h3>
    
    <div style="border:1px solid black; padding:5px">
    ${additionalBudget}
    </div>
    
    <h3 style="text-align:start"><strong>Peer Support</strong></h3>
    
    <div style="border:1px solid black; padding:5px">
    ${peerSupport}
    </div>
    
    <h3 style="text-align:start"><strong>Other</strong></h3>
    
    <div style="border:1px solid black; padding:5px">
    ${other}
    </div>
    
    <p>&nbsp;</p>
    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">PROGRESS CHECKPOINTS:</span></strong></span></span></span></p>
    </div>

    <table style="width:100%">
        <thead>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Checkpoint Date</strong></h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Follow-up Type</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>Session Details</strong></h3>
                </td>
            
            </tr>
            
        </thead>
        <tbody>
        ${checkpoints}
          
            
        </tbody>
    </table>


    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">SIGNATURES:</span></strong></span></span></span></p>
    </div>
    
    <table style="width:100%">
        <tbody>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Employee Signature:&nbsp;</strong><span style="font-family:'Brush Script MT',cursive; font-size:18pt;">${employeeSignatureName}</span></h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Date:&nbsp;</strong>${employeeSignatureDate}</h3>
                </td>
            </tr>
        </tbody>
    </table>
    <p></p>
    <table style="width:100%">
        <tbody>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Manager Signature:&nbsp;</strong><span style="font-family:'Brush Script MT',cursive; font-size:18pt;">${managerSignatureName}</span></h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Date:&nbsp;</strong>${managerSignatureDate}</h3>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    `)
}

export const coachingSession = (name, position, date, concernsAndGoals, strengths, areasImprovement, keyObjectives, actionItems,managerSign, managerSignatureName ,managerSignatureDate, followUpDate)=>{
    return (`
    <div style="font-family:Calibri,sans-serif">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:24pt">COACHING SESSION</span></strong></span></span></span></p>

    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-family:Calibri,sans-serif;color:#000000;font-size:14pt;"><strong>BASIC INFORMATION:</strong></span></p>
    </div>
   
    <table style="font-family:Calibri,sans-serif" cellpadding="1" cellspacing="1" style="width:100%">
        <tbody>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Name</strong>: ${name}</h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Position:&nbsp;</strong>${position}</h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>&nbsp;Date Created:</strong> ${date}</h3>
                </td>
            </tr>
        </tbody>
    </table>
    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">Concerns and Goals Addressed:</span></strong></span></span></span></p>
    </div>
    
    <div style="border:1px solid black; padding:5px">
    ${concernsAndGoals}
    </div>


    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">Strengths to Leverage:</span></strong></span></span></span></p>
    </div>
    
    <div style="border:1px solid black; padding:5px">
    ${strengths}
    </div>


    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">Areas for Improvement:</span></strong></span></span></span></p>
    </div>
    
    <div style="border:1px solid black; padding:5px">
    ${areasImprovement}
    </div>

    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">Key Objectives for Today’s Session:</span></strong></span></span></span></p>
    </div>
    
    <div style="border:1px solid black; padding:5px">
    ${keyObjectives}
    </div>
    
    
    <p>&nbsp;</p>
    
    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">Action Items and Follow-Up:</span></strong></span></span></span></p>
    </div>

    <table style="width:100%">
        <thead>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif">
                <strong>Action Item</strong></h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif">
                <strong>Who is Responsible</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif">
                <h3><strong>Due Date</strong></h3>
                </td>
                <td style="font-family:Calibri,sans-serif; text-align:center;">
                <h3><strong>Notes</strong></h3>
                </td>            
            </tr>
            ${actionItems}
        </thead>
        
    </table>


    <div style="width:100%: display:${managerSign};">

    <div style="border-top: solid black 1px; border-bottom: solid black 1px">
    <p style="text-align:start"><span style="font-size:medium"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><span style="font-size:14pt">SIGNATURES:</span></strong></span></span></span></p>
    </div>

    <table style="width:100%; display:${managerSign};">
        <tbody>
            <tr>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Manager Signature:&nbsp;</strong><span style="font-family:'Brush Script MT',cursive; font-size:18pt;">${managerSignatureName}</span></h3>
                </td>
                <td>
                <h3 style="font-family:Calibri,sans-serif"><strong>Date:&nbsp;</strong>${managerSignatureDate}</h3>
                </td>
            </tr>
        </tbody>
    </table>

    <p></p>
   
</div>
<div style="border-top: solid black 1px; border-bottom: solid black 1px">
<h3 style="font-family:Calibri,sans-serif"><strong>Follow-Up Date:&nbsp;</strong>${followUpDate}</h3></div>

    </div>
    `)
}