import { Typography } from "@mui/material";
import React, { useState } from "react";

import { Button, Form, InputGroup } from "react-bootstrap";

export const CustomCriteriaForm = (props)=>{
const [custom, setCustom] = useState('none')
    return ( 
    <Form.Group  className="mb-3">
          <Form.Label style={{display: props.DisplayCustomInput}}><b>Custom Category Title:</b></Form.Label> 

      <InputGroup style={{display: props.DisplayCustomInput}}>
      
      <Form.Control onChange={props.CustomCat} value={props.CustomCatValue} placeholder="Add Custom Category to Dropdown" type="text"/>
      <Button
      onClick={props.AddCustomCategory}
      variant="outline-secondary" >
        Add Category
        
      </Button>
    </InputGroup>
    <Typography style={{display: props.DisplayCustomInput}} variant="caption"><b>Category will be added to dropdown for this session only.</b></Typography>
    <Form.Group style={{marginTop:'10px', display: props.DisplayCustomInput}} >
    <Form.Label><b>Select Category:</b></Form.Label> 
    <Form.Select  onChange={props.SetCustomCategory}>
    <option value="">Select Category</option>    
  
    {props.CategoryData}

    </Form.Select>
    </Form.Group>
   <Form.Label>{props.TestLabel}</Form.Label>
    <Form.Label><b>Enter your custom criterion</b></Form.Label>
    <Form.Control
          onChange={props.OnCustomCriteriaChange}
          as="textarea"
          value={props.CustomCriteriaValue}
          placeholder="Enter your criterion."
          style={{ height: '100px' }}
        />  

       <a onClick={props.CloseForm}><Button onClick={props.OnAddCustomQuestion} style={{marginTop:'10px',backgroundColor: '#48B56F',color:'white' }}><b>Add Criteria</b></Button></a>
  </Form.Group>
  )

}