import ForwardToInbox from "@mui/icons-material/ForwardToInbox";
import { IconButton } from "@mui/material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const BsToolTip = (props) => {

  
    const tooltip = (
      <Tooltip style={{ zIndex: 100001 }}>
        {props.Title}
      </Tooltip>
    );
  
    return (
      <OverlayTrigger overlay={tooltip} placement="top">
        <div>
          <IconButton color="primary" onClick={props.onClick}>
         {props.Icon}
          </IconButton>
        </div>
      </OverlayTrigger>
    );
  };
