import React, {useRef} from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import htmlDocx from 'html-docx-js/dist/html-docx';
import {saveAs} from "file-saver";
import {AppBar, Button, CircularProgress, Toolbar, Grid} from "@mui/material";
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import '../../assets/css/review-screen.css'
import logo from '../../assets/css/images/PECLogo.png'
import hdLogo from '../../assets/css/pec-badge.png'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../classes/global-functions";
import pipInstructions from '../../json/pip-instructions.json'
import { useEffect } from "react";
import axios from "axios";
import { API } from "../../Controllers/API";
import { useState } from "react";
import ToolTipButton from "../../components/TooltipButton";
import DownloadIcon from '@mui/icons-material/Download';
import { coachingSession } from "../../documents/cp-document";

function Copyright(props) {
    return (
      <div style={{textAlign:'center'}}>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://helpdeskforhr.com">
          Andere HR Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
        
      </Typography>
      <Typography sx={{fontWeight:'bold'}} variant="caption">POWERED BY</Typography>
      <br></br>
      <img src={hdLogo} height='auto' width='100px'/>
      </div>
    );
  }
export const SessionView = (props)=>{
  const paperRef = useRef(null);
  const [spinner,setSpinner] = useState(false)
  const [signature, setSignature] = useState('')
const [signatureDate, setSignatureDate] = useState('')
const [loading,setLoading] = useState(true)
const [completed, setCompleted] = useState(false)
const [coachingPlan, setCoachingPlan] = useState();
const handleSignature = (e)=>{reviewObj.signature = e}
const handleSignatureDate = (e)=>{reviewObj.signatureDate = e}
  const[reviewObj, setReviewObj] = useState(
   
)

const downloadDocx = () => {
  const htmlContent = paperRef.current.innerHTML;

  // Convert HTML to DOCX
  const docx = htmlDocx.asBlob(
    coachingSession(
      coachingPlan.name.name,
      coachingPlan.position,
      formatDate(coachingPlan.date),
      reviewObj.sessionData.concernsAndGoals,
      reviewObj.sessionData.strengths,
      reviewObj.sessionData.areasImprovement,
      reviewObj.sessionData.keyObjectives,
      reviewObj.sessionData.actionItems.map(i=>{
        return(` <tr>
        <td>
        <h3 style="font-family:Calibri,sans-serif">${i.actionItem}</h3>
        </td>
        <td>
        <h3 style="font-family:Calibri,sans-serif">${i.dueDate}</h3>
        </td>
        <td style="font-family:Calibri,sans-serif">
        <h3 style="font-family:Calibri,sans-serif">${i.responsible}</h3>
       </td>

       <td style="font-family:Calibri,sans-serif">
        <h3 style="font-family:Calibri,sans-serif">${i.notes}</h3>
       </td>
    
    
    </tr>`)
      }),
      reviewObj.sessionData.managerSign ? 'block': 'none',
      reviewObj.sessionData.managerSignature,
      formatDate(reviewObj.sessionData.signatureDate),
     formatDate(reviewObj.sessionData.followUpDate)
    ), { margins: {top: 720, left:720, right:720, bottom:720}});

  // Save the DOCX file
  saveAs(docx, `${coachingPlan.name.name} Coaching Session.docx`);
};


  const location = useLocation();
  function getQueryVariable(variable) {
      var query = location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          if (decodeURIComponent(pair[0]) == variable) {
              return decodeURIComponent(pair[1]);
          }
      }
  }

 
  const a  = getQueryVariable('a')
  const b  = getQueryVariable('b')
  const c  = getQueryVariable('c')

  const Data = async()=>{
    console.log(a);
    console.log(b);
    console.log(c);
    let coachingPlanResp = await axios.post(API+'get-cp', {id:a, cpId:b}, (err, response)=>{
        return response.data[0]
     });

    let response = await axios.post(API+'get-cp-session', {id:a, cpId:b, checkpointId: c}, (err, response)=>{
       return response.data[0]
    });
    console.log(response.data)
    console.log(coachingPlanResp.data)
    setCoachingPlan(coachingPlanResp.data[0])
   setReviewObj(response.data);

setLoading(false)
   
  }


     useEffect(()=>{
      Data()

     },[])


     const UpdatePip = async ()=>{
    //   setSpinner(true)
    //   reviewObj.status = "Signed"
    //     console.log(reviewObj)
    //     let response = await axios.post(API+'update-pip', {id:a, pipId:b, pipObj:reviewObj}, (err, response)=>{
    //       return response
    //    })
    //    console.log(response.data)
    //        if(response.data == 'Complete'){
    //         console.log('Complete')
    //         setCompleted(true)
    //         setSpinner(false)
    //        }


     }

     if(loading){ 
        return ( <Box sx={{textAlign:'center', marginTop:20}}>
        <CircularProgress/>
        <Typography><b>Loading</b></Typography>
      </Box>)
     } else {
        

    return (
        <div style={{backgroundColor:'#D5EDE3'}}>
        <Box className='review-paper-container' sx={{backgroundColor:'#D5EDE3', paddingTop:10,paddingLeft:10,paddingRight:10,display:'flex', justifyContent:'center', minHeight:'100vh'}}>
       <AppBar style={{marginBottom:'70px !important'}}>
       <Toolbar
            sx={{
              backgroundColor:'#1A9A71',
              pr: '24px', // keep right padding when drawer closed
            }}
          >

<img height='auto' width={250} src={logo}/>
<Button sx={{color:'white', position:'absolute', top:15, right:15}} onClick={()=>{window.close()}}>Close</Button>
<ToolTipButton
        onClick={downloadDocx}
        Title="DOWNLOAD"
        iconBtnSx={{color:'white',position:'absolute', top:15, right:80}}
        Icon={<DownloadIcon/>}
        />
        
          </Toolbar>



       </AppBar>

       <br></br>
        <Paper ref={paperRef}  className="demo-paper" sx={{ textAlign: 'center', padding: '3%', maxWidth: '95%', marginTop: '70px', justifyContent: 'center'}}>
           <Typography variant="h5" sx={{marginBottom:'25px'}}><b>COACHING SESSION</b></Typography>
     
               <hr></hr>
<Box sx={{textAlign:'start'}}>
               </Box>

<Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>EMPLOYEE:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{coachingPlan.name.name}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>POSITION:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{coachingPlan.position}</Form.Label>
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
            <Typography variant="button">
              <b>DATE CREATED:</b>
            </Typography>
          </Form.Label>
          <br />
          <Form.Label>{formatDate(reviewObj.dateOfNextReview)}</Form.Label>
        </Form.Group>
      </Grid>
    </Grid>
            <hr></hr>
            <Box style={{ textAlign: 'start' }}>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Concerns or goals to be addressed:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.concernsAndGoals}}/></Box>
            <hr></hr>
          
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Strengths to Leverage:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.strengths}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Areas for Improvement:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.areasImprovement}}/></Box>
            <br></br>
            <Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Key Objectives for Today’s Session:</b></Typography>
            
            <Box sx={{border:'1px solid black', minHeight:'200px', marginTop:'10px', padding:'10px'}}><div dangerouslySetInnerHTML={{__html:reviewObj.sessionData.keyObjectives}}/></Box>
           
    </Box>
            <hr></hr>
<Box sx={{textAlign:'start'}}>
<Typography variant="button" style={{fontSize:14, textAlign:'start'}}><b>Action Items and Follow-Up:</b></Typography>

<TableContainer>
<Table>
<TableHead  sx={{width:'100%'}}>
               
                  <TableCell sx={{width:125}}><b>Action Item</b></TableCell>
                  <TableCell sx={{width:125}}><b>Who is Responsible</b></TableCell>
                  <TableCell><b>Due Date</b></TableCell>
                  <TableCell><b>Notes</b></TableCell>
                 
         
              </TableHead>
              <TableBody>
                {/* {reviewObj.specificActionSteps.map((step, index) => (
                  <TableRow key={index}>
                    <TableCell>{step.whatToDo}</TableCell>
                    <TableCell>{step.byWhen}</TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.resourcesNeeded}}/></TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{__html:step.potentialObstacles}}/></TableCell>
                  
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
            </TableContainer>
</Box>

<Box sx={{textAlign:'start'}}>
   <hr/>
   <Grid sx={{textAlign:'start'}} container spacing={2}>
      <Grid  item xs={12} sm={6} md={4}>
        <Form.Group>
          <Form.Label>
          <Typography variant="button" sx={{ fontSize: 16 }}>
        <b>Next Session Date and Time:</b>
      </Typography>   
          </Form.Label>
          <br />
        </Form.Group>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Form.Group>
        <Form.Label>
          <Typography variant="button" sx={{ fontSize: 16 }}>
        <b>{formatDate(reviewObj.sessionData.followUpDate)}</b>
      </Typography>   
          </Form.Label>
        </Form.Group>
      </Grid>

    </Grid>
  
      <hr/>   
      <Row style={{display: reviewObj.sessionData.managerSign ? 'flex': 'none'}} className="mb-3">
       
        <Form.Group  as={Col} >
          <Typography variant="button"><b>Manager Signature:</b></Typography>
          <br></br>
          <Form.Label 
          
        //   onChange={(e)=>{handleSignature(e.target.value); console.log(e.target.value)}} 
          style={{fontFamily:'Signature', fontSize:'18px'}} type="text">{reviewObj.sessionData.managerSignature}</Form.Label>
          <br/>
          <Typography variant="button"><b>{coachingPlan.manager.name}</b></Typography>
        </Form.Group>
       
        <Form.Group as={Col} >
        <Typography variant="button"><b>Date:</b></Typography>
          <br></br>
          <Form.Label 
          type="text">{formatDate(reviewObj.sessionData.signatureDate)}</Form.Label>
        </Form.Group>
      </Row>
     
    </Box>  
       
    
        </Paper>
    </Box>
    <Box sx={{paddingBottom:'20px'}}><Copyright sx={{ pt: 4 }} /></Box>
       </div>
    )
  } 
}