import { Button, IconButton, Typography, Paper, TableContainer, TableCell, TableRow, TableHead, TableBody } from '@mui/material';
import { Accordion, InputGroup } from 'react-bootstrap';
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Container, Row, Col, Form, Table, Modal, Spinner  } from 'react-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import uuid from "react-uuid";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { API } from "../../Controllers/API";
import axios from "axios";


import '../../assets/css/ckEditor.css';
import { Box } from '@mui/system';
import { formatDate } from '../../classes/global-functions';
const customConfiguration = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        'undo',
        'redo',
      ],
    },
  };

  const BackBtn = forwardRef((props, ref) => {
    return <a {...props}
    onClick={props.OnClickThis}
    ref={ref} ></a>
  })
  


const CPEdit = (props) => {
  const token = JSON.parse(sessionStorage.getItem('token'))
  const data = JSON.parse(sessionStorage.getItem('profile-data'))

  const itemRef = useRef(null);
  const [sendEmailCheck,setSendEmailCheck] = useState(true)
  const [managerCheck,setManagerCheck] = useState(false)
  const [status, setStatus] = useState('Not Yet Sent To Be Signed')
  // State to manage form data
  const [reviewee, setReviewee] = useState('')
  const [formData, setFormData] = useState(props.Data);

    //Checkpoint State
  const [checkpoints, setCheckpoints] = useState([])
  const [checkpointGoal, setCheckpointGoal] = useState('')
  const [checkpointDate, setCheckpointDate] = useState('')
  const [checkpointFollowUpType, setCheckpointFollowUpType] = useState('')
  const [checkpointProgressExpected, setCheckpointProgressExpected] = useState('')



  const [update,setUpdate] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [currentGoalField, setCurrentGoalField] = useState('');
  const [modalGoalTitle, setModalGoalTitle] = useState('');
  const [modalGoalDetails, setModalGoalDetails] = useState('');
  const [spinner,setSpinner] = useState(false)
  const [loading, setLoading] = useState(true);
  const [showActionModal, setShowActionModal] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [modalWhatToDo, setModalWhatToDo] = useState('');
  const [modalByWhen, setModalByWhen] = useState('');
  const [modalResourcesNeeded, setModalResourcesNeeded] = useState('');
  const [modalPotentialObstacles, setModalPotentialObstacles] = useState('');

  const [managers, setManagers] = useState([{
    dateOfLastReview: "NA",
  email: "null",
  id: "null",
  name: "null",
  reviews: [''],
  role: "Manager"
  }])
  const [employees, setEmployees] = useState([{
    dateOfLastReview: "NA",
  email: "null",
  id: "null",
  name: "null",
  reviews: [''],
  role: "Employee"
  }])

  const AddCheckpoint = ()=>{
    let checkpoint = {
      id:uuid(),
      name:{name: `${formData.name.name} Coaching Session`},
      dateOfNextReview: checkpointDate,
      reviewType: `Coaching Session - ${checkpointFollowUpType}`,
      notes:`SessionType: ${checkpointFollowUpType} ${checkpointProgressExpected}`,
      status: false,
      sessionData:{
        concernsAndGoals:'',
        strengths:'',
        areasImprovement:'',
        keyObjectives:[],
        actionItems:[],
        followUpDate:'',
        managerSign:false,
        managerSignature:'',
        signatureDate:''
        
      },
    
  }
 
    
  setFormData((prevFormData) => ({
    ...prevFormData,
    checkpoints: [...prevFormData.checkpoints, checkpoint],
  }));

  setCheckpointDate('')
  setCheckpointFollowUpType('')
  setCheckpointProgressExpected('')


setUpdate(!update)
  console.log(checkpoint)
  }

  const DeleteCheckPoint = (a)=>{
    let filter = checkpoints.filter(i=>{
      return  (a !== i.id)
    })
    console.log(filter)

    setCheckpoints(filter)
  }


  const [carbonCopyList, setCarbonCopyList] = useState([])
  const [ccName, setCcName] = useState('')
  const [ccEmail, setCcEmail] = useState('')

  const handleCcName = (e) =>{
    setCcName(e)
    console.log(ccName)
  }
  
  
  const handleCcEmail = (e) =>{
    setCcEmail(e)
    console.log(ccEmail)
  }




  useEffect(()=>{
console.log(formData)
  },[formData])

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'managerName') {
      // Update the 'manager.name' property
      setFormData((prevData) => ({
        ...prevData,
        manager: {
          ...prevData.manager,
          name: value,
        },
      }));
    } else {
      // Update other fields as usual
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === 'managerEmail') {
      // Update the 'manager.name' property
      setFormData((prevData) => ({
        ...prevData,
        manager: {
          ...prevData.manager,
          email: value,
        },
      }));
    } else {
      // Update other fields as usual
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    let obj = JSON.parse(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: obj,
    }));
  };

  const handleArrayChange = (index, field, e) => {
    const newArray = [...formData[field]];
    newArray[index] = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: newArray,
    }));
  };

  const addNewGoal = (field) => {
    setShowModal(true);
    setCurrentGoalField(field);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalGoalTitle('');
    setModalGoalDetails('');
  };

  const handleAddGoal = () => {
    setFormData((prevData) => ({
      ...prevData,
      [currentGoalField]: [
        ...prevData[currentGoalField],
        {
          title: modalGoalTitle,
          details: modalGoalDetails,
        },
      ],
    }));
    setShowModal(false);
    setModalGoalTitle('');
    setModalGoalDetails('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.name === '' || formData.date === '' ){
   window.alert('Please make sure you have filled out the plan before sending it.')
    } else {
      
if(formData.sendToManager && formData.manager.name === '' && formData.manager.email === ''){
  window.alert('You have not added a manager name and email.')

} else {
 
   let resp = await axios.post(API+'update-coaching-plan',{userId: data.id, coachingPlan: formData})

   console.log(resp);
   if(resp.data === 'error'){
    window.alert('Something went wrong. Please try again.')
   } else {
    window.alert('Plan has been updated!')
    props.Submit()
   }
   


  }

}
  };

  const handleDeleteGoal = (field, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field].filter((_, i) => i !== index),
    }));
  };


  const handleCloseActionModal = () => {
    setShowActionModal(false);
    setCurrentStepIndex(null);
    setModalWhatToDo('');
    setModalByWhen('');
    setModalResourcesNeeded('');
    setModalPotentialObstacles('');
  };

  const handleEditSpecificActionStep = (index) => {
    const step = formData.specificActionSteps[index];
    setCurrentStepIndex(index);
    setModalWhatToDo(step.whatToDo);
    setModalByWhen(step.byWhen);
    setModalResourcesNeeded(step.resourcesNeeded);
    setModalPotentialObstacles(step.potentialObstacles);
    setShowActionModal(true);
  };

  const handleAddNewSpecificActionStep = () => {
    setCurrentStepIndex(null);
    setModalWhatToDo('');
    setModalByWhen('');
    setModalResourcesNeeded('');
    setModalPotentialObstacles('');
    setShowActionModal(true);
  };

  const handleAddSpecificActionStep = () => {
    if (currentStepIndex !== null) {
      // Editing existing step
      setFormData((prevData) => ({
        ...prevData,
        specificActionSteps: prevData.specificActionSteps.map((step, i) =>
          i === currentStepIndex
            ? {
                whatToDo: modalWhatToDo,
                byWhen: modalByWhen,
                resourcesNeeded: modalResourcesNeeded,
                potentialObstacles: modalPotentialObstacles,
              }
            : step
        ),
      }));
    } else {
      // Adding new step
      setFormData((prevData) => ({
        ...prevData,
        specificActionSteps: [
          ...prevData.specificActionSteps,
          {
            whatToDo: modalWhatToDo,
            byWhen: modalByWhen,
            resourcesNeeded: modalResourcesNeeded,
            potentialObstacles: modalPotentialObstacles,
          },
        ],
      }));
    }

    handleCloseActionModal();
  };


  const handleDeleteSpecificActionStep = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      specificActionSteps: prevData.specificActionSteps.filter((_, i) => i !== index),
    }));
  };

  const handleRevieweeSelect = (e) =>{
    setReviewee(e)
  
  
    console.log(reviewee)
    
    
  }


  return (
    <Container>
            <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
       <Box sx={{textAlign:'end'}}>
     
       </Box>
        <Form.Label><Typography sx={{fontSize:'16px'}} variant="subtitle2">Select the person that this plan was created for:</Typography></Form.Label>
       
        <Form.Select 
         name="name"
         disabled
        value={JSON.stringify(formData.name)}
        onChange={handleSelectChange}>
          <option value="null">{formData.name.name}</option>
          <optgroup label="Managers">
         {managers.map(i=>{
            let val = {
              id:i.id,
              firstName:i.firstName,
              lastName:i.lastName,
              name:i.name, 
              email:i.email,
              status:'pending'
            }
          return <option  value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>


         <optgroup label="Employees">
         {employees.map(i=>{
          let val = {
            id:i.id,
            firstName:i.firstName,
            lastName:i.lastName,
            name:i.name, 
            email:i.email,
            status:'pending'
          }
          return <option value={JSON.stringify(val)}>{i.name}</option>
         })}
         </optgroup>
        </Form.Select>
      </Form.Group>

        {/* Employee Information Section */}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Typography variant="button" sx={{ fontSize: 16 }}><b>BASIC INFORMATION</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Row className='mb-4'>
         
          <Col>
            <Form.Group controlId="position">
              <Form.Label><Typography><b>POSITION:</b></Typography></Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formData.position}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="date">
              <Form.Label><Typography><b>DATE OF PLAN SETUP:</b></Typography></Form.Label>
              <Form.Control
                name="date"
                type='date'
                disabled
                value={formData.date}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
    
  

       {/* Goals Section */}
    
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Typography variant='button' sx={{ fontSize: 16 }}><b>GOAL SECTION</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Label>{'Use this section to create the long and short term goals. Click the "+" to add goals for each section.'}</Form.Label>
              <Row>
              <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Short-Term Goals   
                    <IconButton sx={{
                        float:'inline-end',
                        height:25,
                        width:25, 
                        color:'white',
                        backgroundColor:'#25AC71'
                        }}  
                        variant="success" 
                        size="sm" 
                        onClick={() => addNewGoal('shortTermGoals')}
                        >
                      +
                    </IconButton></th>
                 
                </tr>
              </thead>
              <tbody>
                {formData.shortTermGoals.map((goal, index) => (
                 <tr key={index}>
                  <td>
                <Typography variant='button'><b>Goal {index + 1}: {goal.title}</b>  
                <IconButton color="error"
                onClick={() => handleDeleteGoal('shortTermGoals', index)}
              >
                <DeleteIcon />
              </IconButton>
              </Typography>
                <br/>
             
                 <div dangerouslySetInnerHTML={{__html:goal.details}}/>
                 </td>
               </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Long-Term Goals  
                    <IconButton 
                    sx={{
                        float:'inline-end',
                        height:25,
                        width:25, 
                        color:'white',
                        backgroundColor:'#25AC71',
                        paddingLeft:1
                        }} 
                    variant="success" 
                    size="sm" 
                    onClick={() => addNewGoal('longTermGoals')}>+</IconButton></th>
                  
                </tr>
              </thead>
              <tbody>
                {formData.longTermGoals.map((goal, index) => (
                 <tr key={index}>
                 <td>
                 <Typography variant='button'><b>Goal {index + 1}: {goal.title}</b>
                <IconButton color="error"
                onClick={() => handleDeleteGoal('longTermGoals', index)}
              >
                <DeleteIcon />
              </IconButton>
                </Typography>
                <br/>
                 <div dangerouslySetInnerHTML={{__html:goal.details}}/>
                 </td>
                
               </tr>
                ))}
              </tbody>
            </Table>
          
          </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

    {/* Specific Action Steps Section */}
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <Typography variant='button' sx={{ fontSize: 16 }}><b>SPECIFIC ACTION STEPS</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
           

              <Row>
              <Form.Label>{'The employee should understand the steps they are expected to follow, the resources/assistance the employer will provide to assist them in successfully meeting the goals by the expected accomplishment date, and any potential obstacles they may encounter along the way.'}</Form.Label>
              <Typography className='mb-2' variant='button' sx={{fontSize:18}}><IconButton 
                    sx={{
                        float:'inline-end',
                        height:25,
                        width:25, 
                        color:'white',
                        backgroundColor:'#25AC71',
                        paddingLeft:1
                        }} 
                    variant="success" 
                    size="sm" 
                    onClick={handleAddNewSpecificActionStep }>+</IconButton></Typography>
              <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>What To Do</th>
                  <th>By When</th>
                  <th>Resources Needed</th>
                  <th>Potential Obstacles</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.specificActionSteps.map((step, index) => (
                  <tr key={index}>
                    <td>{step.whatToDo}</td>
                    <td>{step.byWhen}</td>
                    <td><div dangerouslySetInnerHTML={{__html:step.resourcesNeeded}}/></td>
                    <td><div dangerouslySetInnerHTML={{__html:step.potentialObstacles}}/></td>
                    <td>
                      <IconButton color="primary" onClick={() => handleEditSpecificActionStep(index)}>
                       <EditIcon/>
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteSpecificActionStep(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
         
          </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>


           {/* Support Section */}
     
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <Typography variant='button' sx={{ fontSize: 16 }}><b>SUPPORT SECTION</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Row>
        
        <Typography variant='button' sx={{fontSize:14}}><b>Skills Training</b></Typography> 
        <Form.Label>{'Describe the skills training support that will be provided to the employee.'}</Form.Label>

         <div style={{padding:10}}>
         <CKEditor
  config={{
        height: 300,
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'link',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      }}
  data={formData.skillsTraining}
  onChange={(event, editor) => {
    const updatedSkillsTraining = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      skillsTraining: updatedSkillsTraining,
    }));
  }}
  editor={ClassicEditor}
/>

        </div>

        <Typography  variant='button' sx={{fontSize:14}}><b>Additional Budget</b></Typography> 
        <Form.Label>{'Description Section Description Section Description Section Description Section Description Section Description Section Description Section Description Section'}</Form.Label>

         <div style={{padding:10}}>
         <CKEditor
  config={{
        height: 300,
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'link',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      }}
  data={formData.additionalBudget}
  onChange={(event, editor) => {
    const updatedAdditionalBudget = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      additionalBudget: updatedAdditionalBudget,
    }));
  }}
  editor={ClassicEditor}
/>

        </div>


        <Typography variant='button' sx={{fontSize:14}}><b>Peer Support</b></Typography> 
        <Form.Label>{'Describe the support the employee can expect to receive from peers, mentors, or other local sources.'}</Form.Label>

         <div style={{padding:10}}>
         <CKEditor
  config={{
        height: 300,
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'link',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      }}
  data={formData.peerSupport}
  onChange={(event, editor) => {
    const updatedPeerSupport = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      peerSupport: updatedPeerSupport,
    }));
  }}
  editor={ClassicEditor}
/>

        </div>


        <Typography variant='button' sx={{fontSize:14}}><b>Other</b></Typography> 
        <Form.Label>{'Describe any other means or sources of support the employee can expect to receive during the coaching and goal achieving process.'}</Form.Label>

         <div style={{padding:10}}>
         <CKEditor
            config={{
                height: 300, // Set the desired height in pixels
            }}
            data={formData.other}
            onChange={(event, editor) => {
                const updatedOther = editor.getData();
                setFormData((prevData) => ({
                ...prevData,
                other: updatedOther,
                }));
            }}
            editor={ClassicEditor}
            />

        </div>

        </Row>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

           {/* Progress Checkpoints Section */}
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <Typography variant='button' sx={{ fontSize: 16 }}><b>COACHING SESSIONS</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
            <Typography variant="button"><b>Add Checkpoints:</b></Typography>
        <Typography variant="subtitle2">In this section you can add checkpoints for future coaching sessions. You will also be reminded of these checkpoints in the Evaluation Calendar and on the Dashboard under upcoming evaluations.</Typography>
        <hr></hr>

      <Row className="mb-3">
            <Form.Group  as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Checkpoint Date:</b></Typography></Form.Label>
        <Form.Control value={checkpointDate} 
        onChange={(e)=>setCheckpointDate(e.target.value)} type="date"  />
      </Form.Group>
      <Form.Group as={Col} >
        <Form.Label><Typography variant="subtitle2"><b>Session Type:</b></Typography></Form.Label>
        <Form.Control value={checkpointFollowUpType} 
        onChange={(e)=>setCheckpointFollowUpType(e.target.value)} type="text" placeholder="exp: Call, Meeting..." />
      </Form.Group>
      
      </Row>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label><Typography variant="subtitle2"><b>Additional Notes:</b></Typography></Form.Label>
        <Form.Control value={checkpointProgressExpected} onChange={(e)=>setCheckpointProgressExpected(e.target.value)} placeholder="Detail the progress that is expected with this goal..." as="textarea" rows={3} />
      </Form.Group>
      <div style={{textAlign:'end'}}>
      <Button 
      onClick={AddCheckpoint} 
      variant="contained">Add Checkpoint</Button>  
      </div>
       
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650,display: (checkpoints.length > 0) ?'table':'none' }} aria-label="simple table">
        <TableHead>
          <TableRow>

          <TableCell>Checkpoint Date</TableCell>
          <TableCell>Session Type</TableCell>
          <TableCell>Notes</TableCell>
          <TableCell>Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
        {
          formData.checkpoints.map(i=>{
          let date = new Date(i.dateOfNextReview)
            return(
              <TableRow>
              <TableCell>{formatDate(date)}</TableCell>
              <TableCell>{i.reviewType}</TableCell>
              <TableCell><div dangerouslySetInnerHTML={{__html: i.notes}}/></TableCell>
              <TableCell><Button 
              onClick={()=>DeleteCheckPoint(i.id)}
               sx={{color:'red'}}>Delete</Button></TableCell>

            </TableRow>
            )
          })
        }
            </TableBody>
      </Table>
    </TableContainer>
            </Accordion.Body>
          </Accordion.Item>
     

      {/* Progress Checkpoints Section */}
      
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <Typography variant='button' sx={{ fontSize: 16 }}><b>FINALIZE</b></Typography>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
           

      <Form.Group >
        <Form.Label><Typography><b>WOULD YOU LIKE TO INCLUDE THE MANAGERS SIGNATURE? </b></Typography></Form.Label> 
          <Form.Check 
            type="checkbox"
            label={`Check this if you would like to send the plan to the person's manager to be signed by them?`}
            checked={formData.sendToManager}
            onChange={()=>{
              
                setFormData((prevData) => ({
                  ...prevData,
                  sendToManager: !formData.sendToManager,
                }));

             
                
            }}
          />
          <hr/>
      </Form.Group>
      <Form.Group style={{display: formData.sendToManager === true ? 'block':'none'}}>
      <Typography ><b>MANAGER INFORMATION</b></Typography>
       
          <Form.Label>Input the managers name and email below.</Form.Label>
         <InputGroup className="mb-3">
        <Form.Control
          placeholder="Manager's Name"
          name="managerName"
          value={formData.manager.name}
          onChange={(e)=>{
            let value = e.target.value
            setFormData((prevData) => ({
              ...prevData,
              manager: {
                ...prevData.manager,
                name: value,
              },
            }));
          }}
          aria-describedby="basic-addon2"
        />
         <Form.Control
          placeholder="Manager's Email"
          name="managerEmail"
          value={formData.manager.email}
          onChange={(e)=>{
            let value = e.target.value
            setFormData((prevData) => ({
              ...prevData,
              manager: {
                ...prevData.manager,
                email: value,
              },
            }));
          }}
          aria-describedby="basic-addon2"
        />
        
      
      </InputGroup>
   
      </Form.Group>
      
   
      
   

       
  

              </Row>
            </Accordion.Body>
          </Accordion.Item>
     
          </Accordion>
          <Box sx={{marginTop:2, float:'right'}}>
          <Button variant='contained' type="submit">
          {'Update'}
          <Spinner style={{display: spinner ? '':'none'}}  size="sm"  animation="border" variant="light"  />
        </Button>
        </Box>
       
        
         
  
 

       
          {/* Modal for Adding Goals */}
          <Modal size='lg' style={{ zIndex: 10000 }} show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Goal</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight:500}}>
            <Form.Group controlId="modalGoalTitle">
              <Form.Label>Goal Title:</Form.Label>
              <Form.Control
                type="text"
                value={modalGoalTitle}
                onChange={(e) => setModalGoalTitle(e.target.value)}
                placeholder="Enter goal title"
              />
            </Form.Group>

            <Form.Group controlId="modalGoalDetails">
              <Form.Label>Goal Details:</Form.Label>
              <div style={{ height: '300px' }}>
              <CKEditor
              config={{
                minHeight: '300px', // Sets the enter mode to create a new paragraph (line break) on Enter
              }}
                editor={ClassicEditor}
                data={modalGoalDetails}
                onChange={(event, editor) => setModalGoalDetails(editor.getData())}
         
                />
                </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" sx={{backgroundColor:'gray', marginRight:2}} onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="contained" onClick={handleAddGoal}>
              Add Goal
            </Button>
          </Modal.Footer>
        </Modal>



        {/* Action Modal */}
        {/* Modal for Adding/Editing Specific Action Step */}
      <Modal size='lg' style={{ zIndex: 10000 }} show={showActionModal} onHide={handleCloseActionModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentStepIndex !== null ? 'Edit' : 'Add'} Action Step</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="modalWhatToDo">
            <Form.Label>What To Do:</Form.Label>
            <Form.Control
              type="text"
              value={modalWhatToDo}
              onChange={(e) => setModalWhatToDo(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="modalByWhen">
            <Form.Label>By When:</Form.Label>
            <Form.Control
              type="text"
              value={modalByWhen}
              onChange={(e) => setModalByWhen(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="modalResourcesNeeded">
            <Form.Label>Resources Needed:</Form.Label>
            <CKEditor
             config={{
                height: 300, // Set the desired height in pixels
              }}
              editor={ClassicEditor}
                data={modalResourcesNeeded}
                onChange={(event, editor) => setModalResourcesNeeded(editor.getData())} />
          </Form.Group>

          <Form.Group controlId="modalPotentialObstacles">
            <Form.Label>Potential Obstacles:</Form.Label>
            <CKEditor
             config={{
                height: 300, // Set the desired height in pixels
              }}
              editor={ClassicEditor}
              data={modalPotentialObstacles}
              onChange={(event, editor) => setModalPotentialObstacles(editor.getData())}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" sx={{backgroundColor:'gray', marginRight:2}} onClick={handleCloseActionModal}>
            Close
          </Button>
          <Button variant="contained" onClick={handleAddSpecificActionStep}>
            {currentStepIndex !== null ? 'Save Changes' : 'Add Action Step'}
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Submit Button */}
        
      </Form>
    </Container>
  );
};

export default CPEdit;
