import React from "react";
import SelectionCard from "./SelectionCard";
import singleUserImg from '../assets/css/images/single_user.png'
import multiUserImg from '../assets/css/images/multi_user.png'
import { Box } from "@mui/material";
import '../assets/css/selectionCard.css'


const SelectUploadButtonGroup = (props)=>{
    return(
         <Box className="selection-group" sx={{textAlign:'center' }}>
   
         <SelectionCard OnClick={props.SingleOnClick} Image={singleUserImg} HeaderText="Add a Single Employee" DetailText="Here you can add a single employee by entering their information."/>
          <SelectionCard OnClick={props.MultiOnClick}  Image={multiUserImg} HeaderText="Add Multiple Employees" DetailText="You can upload multiple employees by uploading a CSV file."/>
         
    </Box>)
}
export default SelectUploadButtonGroup;