import './App.css';
import Dashboard from './screens/Dashboard';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
 
} from 'react-router-dom';
import { useState } from 'react';
import { Demo } from './screens/Demo';
import { MainNav } from './nav/MainNav';


import useToken from './components/hooks/useToken';
import Login from './screens/Login';
import { Review } from './screens/Review';
import { DocWebView } from './screens/DocViewWeb';
import { PIPSign } from './screens/PIPSign';
import { PIPReview } from './components/review-screens/pip';
import { PIPWeb } from './components/web-document-screens/pip-web';
import { ResetPassword } from './screens/ResetPassword';
import { PasswordReset } from './screens/PasswordReset';
import {CpSign} from './components/review-screens/cp-sign';
import { CoaochingPlanDetails } from './screens/CoachingPlan/CoachingPlanDetails';
import { SessionSign } from './screens/CoachingPlan/session-sign';
import { CpView } from './screens/CoachingPlan/cp-view';
import { SessionView } from './screens/CoachingPlan/session-view';




function App() {

  // const token = getToken();
  // const [token, setToken] = useState();
  const { token, setToken } = useToken();
  if(!token) {
    return(
      <Routes> 
        <Route path="/" element={ <Login setToken={setToken} />} />
        <Route path="/demo" element={<Demo/>} />
        <Route path="/review" element={<Review/>} />
        <Route path="/pip-sign" element={<PIPReview/>} />
        <Route path="/cp-manager-sign" element={<CpSign/>} />
        <Route path="/cp-employee-sign" element={<CpSign/>} />
        <Route path="/cp-view" element={<CpView/>} />
        <Route path="/cp-session" element={<SessionSign/>} />
        <Route path="/cp-session-view" element={<SessionView/>} />
        <Route path="/cp-details" element={<CoaochingPlanDetails/>} />
        <Route path="/pip-view" element={<PIPWeb/>} />
        <Route path="/view" element={<DocWebView/>} />
        <Route path="/reset-password" element={<ResetPassword/>} />
        <Route path="/password-reset" element={<PasswordReset/>} />
      </Routes>
    )
  } else{
  return (
    <Routes>
    <Route path="/" element={<Dashboard/>} />
    
   
    <Route render={() => <h1>Page no found</h1>} />

    <Route path="/demo" element={<Demo/>} />
    <Route path="/review" element={<Review/>} />
    <Route path="/pip-sign" element={<PIPSign/>} />
    <Route path="/cp-session" element={<SessionSign/>} />
    <Route path="/cp-session-view" element={<SessionView/>} />
    <Route path="/cp-manager-sign" element={<CpSign/>} />
    <Route path="/cp-employee-sign" element={<CpSign/>} />
    <Route path="/cp-view" element={<CpView/>} />
    <Route path="/cp-details" element={<CoaochingPlanDetails/>} />
    <Route path="/pip-view" element={<PIPWeb/>} />
    <Route path="/view" element={<DocWebView/>} />
    <Route path="/reset-password" element={<ResetPassword/>} />
    <Route path="/password-reset" element={<PasswordReset/>} />
    </Routes>
  );
}

}

export default App;




